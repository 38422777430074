import React from 'react';
import { cx } from 'emotion';

export interface FieldSetProps {
    className?: string;
}

export const FieldSet: React.FC<FieldSetProps> = ({ children, className }) => {
    return <div className={cx('space-y-4', className)}>{children}</div>;
};
