import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { api } from '../app/utils';
import {
    CreateUserForm,
    ApplicationUser,
    Local,
    UpdateUserForm,
    PasswordResetForm,
    LocalTemplateType,
    UpdateLocalTemplatesRequest,
    UpdateLocalSettingsRequest,
    Member,
    SendEmailTemplatePreviewRequest,
    Employer,
    JoinLink,
    MemberTag,
    AdditionalField,
    GridPreset,
    SignatureConfig,
    SignatureType,
    DeleteMembersRequest,
    LocalTag,
    CreateEmployerForm,
    DeleteLocalTagResponse,
    TagCounts,
    TagSummary,
    UpdateLocalTagsRequest,
    DeleteTagRequest,
    DeleteTagResponse,
    RenameTagRequest,
    RenameTagResponse,
    DeleteAllTagsDTO,
    ResetJoinStatusRequest,
    UpdateLocalPaymentSettingsRequest,
} from '../types';
import { AdminState } from '../reducers/adminReducer';
import message from '../utils/message';
import { DialogActions, Button } from '@material-ui/core';
import React from 'react';

export const getLocals = createAsyncThunk(
    'admin/getLocals',
    async (_: void, thunkAPI) => {
        const response: Local[] = await api.get('auth/get-user-locals').json();

        return response;
    }
);

export const sendJoinForm = createAsyncThunk(
    'admin/sendJoinForm',
    async (req: { memberIds: string[]; forceSend?: boolean }) => {
        // return;
        const response = await api
            .post('join/send-join-form', {
                json: req,
            })
            .json();

        return response;
    }
);

export const getAllMemberIds = createAsyncThunk(
    'admin/getAllMembers',
    async (filters: { filterModel: any; quickSearch?: string }) => {
        const response: string[] = await api
            .post('join/get-all-member-ids', {
                json: {
                    quickFilter: filters.quickSearch,
                    filterModel: filters.filterModel,
                    startRow: 0,
                    endRow: -1,
                    rowGroupCols: [],
                    valueCols: [],
                    pivotCols: [],
                    groupCols: [],
                    sortModel: [],
                },
            })
            .json();

        return response;
    }
);

export const createUser = createAsyncThunk(
    'admin/createUser',
    async (req: { form: CreateUserForm }) => {
        const response: ApplicationUser = await api
            .post('auth/create-user', {
                json: req.form,
            })
            .json();
        return response;
    }
);

export const updateUser = createAsyncThunk(
    'admin/updateUser',
    async (req: { form: UpdateUserForm }) => {
        const response: ApplicationUser = await api
            .post('auth/update-user', {
                json: req.form,
            })
            .json();
        return response;
    }
);

export const createEmployers = createAsyncThunk(
    'admin/createEmployers',
    async (req: { forms: CreateEmployerForm[] }) => {
        const response: Employer[] = await api
            .post('employer/create', {
                json: req.forms,
            })
            .json();
        return response;
    }
);

export const deleteEmployer = createAsyncThunk(
    'admin/deleteEmployer',
    async (req: { employerId: string }) => {
        const response: string = await api
            .delete(`employer/${req.employerId}`)
            .json();

        return response;
    }
);

export const fetchUsersByLocal = createAsyncThunk(
    'admin/fetchUsersByLocal',
    async (req: { localId: string }) => {
        const response: ApplicationUser[] = await api
            .get(`auth/get-users-by-local/${req.localId}`)
            .json();

        return response;
    }
);

export const fetchUsers = createAsyncThunk('admin/fetchUsers', async () => {
    const response: ApplicationUser[] = await api.get(`auth/get-users`).json();

    return response;
});

export const revokeToken = createAsyncThunk(
    'admin/revokeToken',
    async (req: { userId: string }) => {
        return await api.post(`admin/revoke-token/${req.userId}`).text();
    }
);

export const deleteUser = createAsyncThunk(
    'admin/deleteUser',
    async (req: { id: string; local: string }) => {
        const response: string = await api
            .delete(`auth/delete-user/${req.id}`, {
                json: req.local,
            })
            .json();
        return response;
    }
);

export const requestPasswordReset = createAsyncThunk(
    'admin/requestPasswordReset',
    async (req: { email?: string }) => {
        return await api
            .post(`auth/request-password-reset`, { json: req })
            .text();
    }
);

export const resetPassword = createAsyncThunk(
    'admin/resetPassword',
    async (req: PasswordResetForm) => {
        return await api.post(`auth/reset-password`, { json: req }).json();
    }
);

export const setActiveSignupsFilters = createAction<
    AdminState['activeSignupsFilters']
>('admin/setActiveSignupsFilters');

export const fetchUnsentCounts = createAsyncThunk(
    'admin/fetchUnsentCounts',
    async (req: { memberIds: string[] }) => {
        const response: {
            unsent: number;
        } = await api
            .post(`join/count-unsent-join-form`, { json: req.memberIds })
            .json();
        return response;
    }
);

export const updateTemplate = createAsyncThunk(
    'admin/updateTemplate',
    async (
        payload: {
            template: LocalTemplateType;
            value: string | null;
        },
        thunkAPI
    ) => {
        const adminState: AdminState = (thunkAPI.getState() as any)
            .adminReducer;

        const local = adminState.locals[adminState.selectedLocal];

        if (!local) {
            throw new Error('No local selected');
        }

        const req: UpdateLocalTemplatesRequest = {
            localId: local.displayID,
            localTemplateType: payload.template,
            value: payload.value,
        };

        const res: Local = await api
            .post('local/update-template', {
                json: req,
            })
            .json();

        return res;
    }
);

export const updateLocalSettings = createAsyncThunk(
    'admin/updateLocalSettings',
    async (payload: Omit<UpdateLocalSettingsRequest, 'localId'>, thunkAPI) => {
        const adminState: AdminState = (thunkAPI.getState() as any)
            .adminReducer;

        const local = adminState.locals[adminState.selectedLocal];

        if (!local) {
            throw new Error('No local selected');
        }

        const req = {
            ...payload,
            localId: local.displayID,
        };

        const res: Local = await api
            .post('local/update-settings', {
                json: req,
            })
            .json();

        return res;
    }
);

export const updateLocalPaymentSettings = createAsyncThunk(
    'admin/updateLocalPaymentSettings',
    async (payload: UpdateLocalPaymentSettingsRequest, thunkAPI) => {
        // const adminState: AdminState = (thunkAPI.getState() as any)
        //     .adminReducer;

        // const local = adminState.locals[adminState.selectedLocal];

        // if (!local) {
        //     throw new Error('No local selected');
        // }

        // const req = {
        //     ...payload,
        //     localId: local.displayID,
        // };

        const res: Local = await api
            .post('local/update-payment-settings', {
                json: payload,
            })
            .json();

        return res;
    }
);

export const callMember = createAsyncThunk(
    'admin/callMember',
    async (member: Member, ThunkApi) => {
        const shouldCall = await new Promise((resolve, reject) => {
            message.show(
                `Call ${member.firstName} ${member.lastName}`,
                `Are you sure you would like to call ${member.firstName} ${member.lastName}?`,
                ({ onClose }) => (
                    <DialogActions>
                        <Button
                            onClick={() => {
                                resolve(false);
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                resolve(true);
                                onClose();
                            }}
                        >
                            Call
                        </Button>
                    </DialogActions>
                )
            );
        });

        if (shouldCall) {
            await api.post(`membercall/start`, {
                json: { memberId: `${member.displayID}` },
            });
        }
    }
);

export const sendEmailTemplatePreview = createAsyncThunk(
    'admin/sendEmailTemplatePreview',
    async (payload: { localTemplateType: LocalTemplateType }, thunkAPI) => {
        const adminState: AdminState = (thunkAPI.getState() as any)
            .adminReducer;

        const local = adminState.locals[adminState.selectedLocal];

        if (!local) {
            throw new Error('No local selected');
        }

        const req: SendEmailTemplatePreviewRequest = {
            ...payload,
        };

        await api.post('local/send-email-template-preview', {
            json: req,
        });
    }
);

export const sendSmsTemplatePreview = createAsyncThunk(
    'admin/sendSmsTemplatePreview',
    async (payload: { localTemplateType: LocalTemplateType }) => {
        await api.post('local/send-sms-template-preview', {
            json: payload,
        });
    }
);

export const deleteMembers = createAsyncThunk(
    'admin/deleteMember',
    async (memberIds: string[], ThunkApi) => {
        const request: DeleteMembersRequest = {
            memberIds,
        };
        return api.post(`member/delete`, { json: request }).json();
    }
);

export const resetJoinStatus = createAsyncThunk(
    'admin/resetJoinStatus',
    async (memberIds: string[], ThunkApi) => {
        const request: ResetJoinStatusRequest = {
            memberIds,
        };
        return api.post(`member/reset-joins`, { json: request }).json();
    }
);

export const fetchEmployers = createAsyncThunk(
    'admin/fetchEmployers',
    async (localNumber: string, ThunkApi) => {
        const employers: Employer[] = await api
            .get(`employer/by-local/${localNumber}`)
            .json();
        return { localNumber, employers };
    }
);
export const resendNewUserEmail = createAsyncThunk(
    'admin/resendNewUserEmail',
    async (req: { email?: string }) => {
        return await api.post(`auth/send-new-user-email`, { json: req }).text();
    }
);

export const fetchMemberJoinLinks = createAsyncThunk(
    'admin/fetchMemberJoinLinks',
    async (memberId: string, ThunkApi) => {
        const links: JoinLink[] = await api
            .get(`join/get-links-by-member/${memberId}`)
            .json();
        return { memberId, links };
    }
);

const fetchAllMemberTags = createAsyncThunk(
    'admin/fetchAllMemberTags',
    async () => {
        const res: string[] = await api.get(`member/all-tags`).json();
        return res;
    }
);

const fetchLocalTags = createAsyncThunk('admin/fetchLocalTags', async () => {
    const res: LocalTag[] = await api.get(`local/local-tags`).json();
    return { tags: res };
});

const fetchTagCounts = createAsyncThunk('admin/fetchTagCounts', async () => {
    const res = await api.get(`local/tag-counts`).json<TagCounts>();
    return res;
});

const fetchTagSummaries = createAsyncThunk(
    'admin/fetchTagSummaries',
    async () => {
        const res = await api.get('local/tag-summary').json<TagSummary[]>();
        return res;
    }
);

const createLocalTags = createAsyncThunk(
    'admin/createLocalTags',
    async (req: UpdateLocalTagsRequest) => {
        const res = await api
            .post('local/add-tags', { json: req })
            .json<LocalTag[]>();
        return res;
    }
);

const renameTag = createAsyncThunk(
    'admin/renameTag',
    async (req: RenameTagRequest) => {
        const res = await api
            .patch('local/rename-tag', { json: req })
            .json<RenameTagResponse>();

        return res;
    }
);

const deleteTag = createAsyncThunk(
    'admin/deleteTag',
    async (req: DeleteTagRequest) => {
        const res = await api
            .delete('local/tag', { json: req })
            .json<DeleteTagResponse>();
        return res;
    }
);

const deleteAllTags = createAsyncThunk(
    'admin/deleteAll',
    async (req: DeleteAllTagsDTO) => {
        const res: DeleteAllTagsDTO = await api
            .delete('local/all-tags', {
                json: req,
            })
            .json();

        return res;
    }
);

const updateMemberTags = createAsyncThunk(
    'admin/updateMemberTags',
    async (req: { memberId: string; tags: string[] }) => {
        const res: MemberTag[] = await api
            .post(`member/update-tags/${req.memberId}/`, {
                json: req.tags,
            })
            .json();
        return res;
    }
);

const deleteLocalTag = createAsyncThunk(
    'admin/deleteLocalTag',
    async (req: { tagId: string; deleteFromMembers?: boolean }) => {
        const params = new URLSearchParams({
            removeFromMember: (req.deleteFromMembers ?? false).toString(),
        });

        return api
            .delete(`local/local-tags/${req.tagId}?${params}`)
            .json<DeleteLocalTagResponse>();
    }
);

const getMemberCustomFields = createAsyncThunk(
    'admin/memberCustomFields',
    async (req: { localNumber: string }) => {
        const res: AdditionalField[] = await api
            .get(`member/all-custom-fields`)
            .json();
        return { local: req.localNumber, additionalFields: res };
    }
);

export const fetchGridPresets = createAsyncThunk(
    'admin/fetchGridPresets',
    async (req: { localId: string }) => {
        const response: GridPreset[] = await api.get(`gridpreset/me`).json();
        return response;
    }
);

export const upsertGridPreset = createAsyncThunk(
    'admin/upsertGridPreset',
    async (req: { preset: GridPreset; localId: string }) => {
        const response: GridPreset = await api
            .post(`gridpreset/upsert-preset`, {
                json: req.preset,
            })
            .json();
        return response;
    }
);

export const deleteGridPreset = createAsyncThunk(
    'admin/deleteGridPreset',
    async (req: { id: string }) => {
        await api.delete(`gridpreset/${req.id}`);
        return { displayId: req.id };
    }
);

export const selectLocal = createAction<string>('admin/selectLocal');

const updateSignature = createAsyncThunk(
    'admin/updateSignature',
    async (req: { localId: string; signatureConfig: SignatureConfig }) => {
        const res: SignatureConfig = await api
            .post(`local/signature`, {
                json: req.signatureConfig,
            })
            .json();

        return res;
    }
);

const resetSignature = createAsyncThunk(
    'admin/resetSignature',
    async (req: { localId: string; type: SignatureType }) => {
        const res: SignatureConfig = await api
            .delete(`local/signature`, {
                json: req.type,
            })
            .json();

        return res;
    }
);

const deleteAdditionalField = createAsyncThunk(
    'admin/deleteAdditionalField',
    async (payload: { additionalFieldId: string }, thunkAPI) => {
        const adminState: AdminState = (thunkAPI.getState() as any)
            .adminReducer;

        const local = adminState.locals[adminState.selectedLocal];
        if (!local) {
            throw new Error('No local selected');
        }
        const res: string = await api
            .delete(`additionalfield/delete/${payload.additionalFieldId}`)
            .json();

        return { fieldId: res, localNumber: local.localNumber };
    }
);

export const AdminActions = {
    getLocals,

    sendJoinForm,
    createUser,
    updateUser,
    fetchUsers,
    fetchUsersByLocal,
    deleteUser,
    requestPasswordReset,
    resetPassword,
    selectLocal,
    fetchUnsentCounts,
    updateTemplate,
    updateLocalSettings,
    callMember,
    sendEmailTemplatePreview,
    deleteMember: deleteMembers,
    fetchEmployers,
    fetchMemberJoinLinks,
    resendNewUserEmail,
    fetchAllMemberTags,
    fetchLocalTags,
    fetchTagCounts,
    fetchTagSummaries,
    createLocalTags,
    renameTag,
    deleteTag,
    deleteAllTags,
    updateMemberTags,
    deleteLocalTag,
    getMemberCustomFields,
    createEmployers,
    deleteEmployer,
    fetchGridPresets,
    upsertGridPreset,
    deleteGridPreset,
    setActiveSignupsFilters,
    updateSignature,
    resetSignature,
    deleteAdditionalField,
    updateLocalPaymentSettings,
};
