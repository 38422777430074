import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
    Employer,
    Member,
    ImportRequest,
    MemberImportResult,
    ExtraImportColumns,
} from '../types';
import { api } from '../app/utils';
import { pick, values } from 'lodash-es';
import { AppThunk } from '../app/store';
import { LocalStorageKey, ImportColumnMap } from '../app/constants';
import ky, { HTTPError } from 'ky';

export class ImportError extends Error {
    name = 'ImportError';
}

const falsyJoinedValues = ['no', 'n', 'false', 'null', '0'];

const buildImportRequest = (
    state: ImportState,
    local: string
): ImportRequest => {
    const {
        doTerminationCheck,
        importAgentEmployerMapping,
        importEmployers,
        importAdditionalFields,
    } = state.importParams ?? {};
    const headerMapping =
        state.headerHash && state.headerMapping[state.headerHash];

    if (!state.headerHash) {
        throw new ImportError('Unable to identify headers. Try again later');
    }

    if (!headerMapping) {
        throw new ImportError(
            'No columns mapped. Choose how to import the columns, then try again.'
        );
    }

    const headerValues = values(headerMapping);
    const missingMappings = [];
    if (!headerValues.find((val) => val === 'employee.firstName')) {
        missingMappings.push('First Name');
    }

    if (!headerValues.find((val) => val === 'employee.lastName')) {
        missingMappings.push('Last Name');
    }

    if (!headerValues.find((val) => val === 'employee.employerNumber')) {
        missingMappings.push('Employer Code');
    }

    if (!headerValues.find((val) => val === 'employee.locationNumber')) {
        missingMappings.push('Location Code');
    }

    if (
        importEmployers &&
        !headerValues.find((val) => val === 'employer.checkOffName')
    ) {
        missingMappings.push('Employer Name');
    }

    if (
        importEmployers &&
        !headerValues.find((val) => val === 'employer.name')
    ) {
        missingMappings.push('Location Name');
    }

    if (missingMappings.length) {
        throw new ImportError(
            `The following required columns are missing mappings: ${missingMappings.join(
                ', '
            )}`
        );
    }

    if (!state.importData) {
        throw new Error('No import data found');
    }

    const submissionCols = Object.keys(headerMapping);

    const submissionData = state.importData
        ?.map((data) => {
            const mapped: Record<string, any> = {};
            const additionalFields: Record<string, any> = {};

            Object.keys(data).forEach((key) => {
                if (submissionCols.includes(key)) {
                    const mappedField = headerMapping[key] as any;

                    mapped[mappedField] = data[key];
                } else {
                    if (data[key]) {
                        additionalFields[key] = data[key];
                    }
                }
            });

            return [mapped, additionalFields];
        })
        .map(([data, additionalFields]) => {
            const submission = {
                member: {},
                employer: {},
                extraImportColumns: {},
                additionalFields: additionalFields,
            } as {
                member: Partial<Member>;
                employer: Partial<Employer>;
                extraImportColumns: Partial<ExtraImportColumns>;
                additionalFields: Record<string, any>;
            };

            Object.keys(data).forEach((key) => {
                if (key.startsWith('employee.')) {
                    const prop = key.replace('employee.', '');

                    let val = data[key];

                    if (prop === 'hasJoined') {
                        if (!val) {
                            val = '';
                        } else if (
                            falsyJoinedValues.includes(val.toLowerCase())
                        ) {
                            val = 'false';
                        } else {
                            val = true;
                        }
                    }
                    submission.member[prop as keyof Member] = val;
                } else if (key.startsWith('employer.')) {
                    const prop = key.replace('employer.', '');
                    submission.employer[prop as keyof Employer] = data[key];
                } else if (key.startsWith('extraImportColumns.')) {
                    const prop = key.replace(
                        'extraImportColumns.',
                        ''
                    ) as keyof ExtraImportColumns;

                    submission.extraImportColumns[
                        prop as keyof ExtraImportColumns
                    ] = data[key];
                } else {
                    throw new Error('Unknown mapping: ' + key);
                }
            });

            return submission;
        });

    return {
        imports: submissionData,
        importEmployers: importEmployers ?? true,
        importAgentEmployerMapping: importAgentEmployerMapping ?? true,
        doTerminationCheck: doTerminationCheck ?? false,
        importAdditionalFields: importAdditionalFields ?? false,
    };
};

const preflightImport = createAsyncThunk<MemberImportResult, string>(
    'import/dryRunImport',
    async (local: string, thunkAPI) => {
        const state: ImportState = (thunkAPI.getState() as any).importReducer;

        const req = buildImportRequest(state, local);

        try {
            const res: MemberImportResult = await api
                .post('import/import-members-preflight', {
                    json: req,
                })
                .json();

            if (res.errors?.length) {
                throw new ImportError(res.errors[0]);
            }

            return res;
        } catch (e) {
            if (e instanceof HTTPError) {
                const resp = await e.response.json();

                const errors = Object.values(resp.errors)?.map(
                    (err: any) => err?.[0]
                );

                if (errors[0]) {
                    e.message = errors[0];
                }

                throw e;
            } else {
                throw e;
            }
        }
    }
);

export const submitImport = createAsyncThunk<MemberImportResult, string>(
    'import/submitImport',
    async (local: string, thunkAPI) => {
        const state: ImportState = (thunkAPI.getState() as any).importReducer;

        const req = buildImportRequest(state, local);

        try {
            const res = await api
                .post('import/import-members', {
                    json: req,
                })
                .json();

            return res as MemberImportResult;
        } catch (e) {
            if (e instanceof HTTPError) {
                const resp = await e.response.json();

                const errors = Object.values(resp.errors)?.map(
                    (err: any) => err?.[0]
                );

                if (errors[0]) {
                    e.message = errors[0];
                }

                throw e;
            } else {
                throw e;
            }
        }
    }
);

export const importEmployee = createAsyncThunk(
    'import/importEmployee',
    async ({
        employee,
        employer,
    }: {
        employee: Partial<Member>;
        employer: Partial<Employer>;
    }) => {
        const submission = {
            member: employee,
            employer: employer,
            additionalFields: {},
        };

        const req = {
            imports: [submission],
            importEmployers: true,
        };

        const res = await api
            .post('import/import-members', {
                json: req,
            })
            .json();

        return res as MemberImportResult;
    }
);

const updateHeaderMapping = (mapping: {
    fieldName: string;
    mappedTo: keyof (Member & Employer) & '';
}): AppThunk => (dispatch, getState) => {
    dispatch(ImportActions.setHeaderMapping(mapping));
    const newMapping = getState().importReducer.headerMapping;

    localStorage.setItem(
        LocalStorageKey.HeaderMapping,
        JSON.stringify(newMapping)
    );
};

export interface ImportState {
    importData?: Record<string, any>[];
    importCols?: string[];
    importParams?: {
        importEmployers: boolean;
        importAgentEmployerMapping: boolean;
        doTerminationCheck: boolean;
        importAdditionalFields: boolean;
    };
    headerHash?: string;
    headerMapping: Record<string, Record<string, string>>;
    importResult?: MemberImportResult;
}

const initialState: ImportState = {
    importData: undefined,
    importCols: undefined,
    headerHash: undefined,
    headerMapping: {},
    importResult: undefined,
};

const importSlice = createSlice({
    name: 'import',
    initialState,
    reducers: {
        importData(
            state,
            action: PayloadAction<{
                data: Record<string, any>[];
                columns: string[];
                importParameters: ImportState['importParams'];
            }>
        ) {
            const { data, columns, importParameters } = action.payload;

            const oldHeaderMappings = JSON.parse(
                localStorage.getItem(LocalStorageKey.HeaderMapping) ?? '{}'
            );

            const headerHash = columns
                .join('')
                .replace(/\s/g, '')
                .toLowerCase();

            let headerMapping = pick(oldHeaderMappings[headerHash], columns);

            // If no preset columns already, try to make a good guess
            if (Object.keys(headerMapping).length === 0) {
                columns.forEach((col) => {
                    const match = ImportColumnMap.find(
                        (map) => map.name.toLowerCase() === col.toLowerCase()
                    );
                    if (match) {
                        headerMapping[col] = match.field;
                    }
                });
            }

            const newHeaderMapping = {
                ...oldHeaderMappings,
                [headerHash]: headerMapping,
            };

            state.headerMapping = newHeaderMapping;
            state.headerHash = headerHash;
            state.importData = data;
            state.importCols = columns;
            state.importParams = importParameters;
        },
        setHeaderMapping(
            state,
            action: PayloadAction<{
                fieldName: string;
                mappedTo: keyof (Member & Employer) & '';
            }>
        ) {
            const { fieldName, mappedTo } = action.payload;
            if (state.headerHash) {
                if (mappedTo) {
                    state.headerMapping[state.headerHash][fieldName] = mappedTo;
                } else {
                    delete state.headerMapping[state.headerHash][fieldName];
                }
            }
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(preflightImport.fulfilled, (state, action) => {
                state.importResult = action.payload;
            })
            .addCase(submitImport.fulfilled, (state, action) => {
                state.importResult = action.payload;
            }),
});

const { actions, reducer } = importSlice;

export const ImportActions = {
    ...actions,
    updateHeaderMapping,
    preflightImport,
    submitImport,
};

export default reducer;
