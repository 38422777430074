import React, { Suspense } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import JoinStack from './join/JoinStack';
import { Learn } from './join/Learn';
import { Login } from './admin/Login';
import { RequestPasswordReset } from './admin/RequestPasswordReset';
import { PasswordReset } from './admin/PasswordReset';
import { FullPageLoader } from './components/FullPageLoader';
import PrintSignUp from './admin/PrintSignUp';
import { Payment } from './Payment/Payment';
const AdminStack = React.lazy(() => import('./admin/AdminStack'));

function App() {
    return (
        <div className="App h-full flex flex-col">
            <Suspense fallback={<FullPageLoader />}>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route path="/payment" component={Payment} />
                    <Route
                        path="/login/requestPasswordReset"
                        component={RequestPasswordReset}
                    />
                    <Route
                        path="/login/resetPassword"
                        component={PasswordReset}
                    />
                    <Route path="/admin" component={AdminStack} />
                    <Route path="/learn" component={Learn} />
                    <Route path={["/:local/:employerNumber/:employerName/print", "/:local/print"]} component={PrintSignUp} />
                    {/* This route has to be at the end */}
                    <Route path="/" component={JoinStack} />
                </Switch>
            </Suspense>
        </div>
    );
}

export default App;
