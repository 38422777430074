import React, { useEffect, useCallback, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import FTextField from '../components/inputs/FTextField';
import { LoginRequest } from '../types';
import { login } from '../actions/appActions';
import { useAppDispatch, useTypedSelector } from '../app/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useLocation, useHistory } from 'react-router-dom';
import { LoaderButton } from '../components/LoaderButton';
import { Button } from '@material-ui/core';

const defaultValues = {
    local: '',
    email: '',
    password: '',
};

export const Login: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation<any>();
    const { enqueueSnackbar } = useSnackbar();
    const token = useTypedSelector((state) => state.appReducer.token);

    const redirrectToAdmin = useCallback(() => {
        setLoading(false);
        const { from } = location.state || {
            from: { pathname: '/admin' },
        };

        history.replace(from);
    }, [location, history]);

    useEffect(() => {
        if (token) {
            redirrectToAdmin();
        }
    }, [token, redirrectToAdmin]);

    const handleSubmit = (
        values: LoginRequest,
        helpers: FormikHelpers<LoginRequest>
    ) => {
        setLoading(true);
        dispatch(login(values))
            .then(unwrapResult)
            .then(redirrectToAdmin)
            .catch((e) => {
                setLoading(false);
                if (e.message === 'Unauthorized') {
                    enqueueSnackbar('Invalid Login', {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar('Failed to login', {
                        variant: 'error',
                    });
                }
            });
    };

    return (
        <div className="flex h-full items-center justify-center">
            <div className="p-8 lg:w-1/2 md:w-2/3 w-full max-w-xl">
                <h3 className="mb-4 text-xl">UFCW Join Admin Login</h3>
                <Formik initialValues={defaultValues} onSubmit={handleSubmit}>
                    {({ handleSubmit }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col space-y-2"
                        >
                            <FTextField
                                label="Email"
                                name="email"
                                type="email"
                            />
                            <FTextField
                                label="Password"
                                name="password"
                                type="password"
                            />

                            <LoaderButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="flex-1"
                                loading={loading}
                                ContainerProps={{
                                    className: 'flex',
                                }}
                            >
                                Login
                            </LoaderButton>
                        </form>
                    )}
                </Formik>
                <div className="text-center">
                    <Button variant="text" href="/login/requestPasswordReset">
                        Forgot Password?
                    </Button>
                </div>
            </div>
        </div>
    );
};
