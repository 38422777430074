import React from 'react';
import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { cx } from 'emotion';
import DynamicLogo from '../components/DynamicLogo';
import { useTypedSelector } from '../app/store';

interface Props {
    transparent?: boolean;
}

export const NavBar: React.FC<Props> = ({ transparent }) => {
    const joinForm = useTypedSelector(
        (state) => state.joinFormReducer.joinForm
    );
    const existingMember = useTypedSelector(
        (state) => state.appReducer.employee
    );

    let localNumber = '';
    if (existingMember?.local.localNumber) {
        localNumber = 'LOCAL ' + existingMember.local.localNumber;
    } else if (joinForm?.formLocal !== '') {
        localNumber = 'LOCAL ' + joinForm.formLocal;
    }

    return (
        <div
            className={cx(
                'z-10 w-full bg-transparent',
                !transparent && 'bg-ufcw-blue'
            )}
        >
            <div
                className={cx(
                    'flex justify-between px-4 md:px-12 z-10 h-12 md:h-16 flex-shrink-0 w-full max-w-screen-xl mx-auto mb-1 md:mb-0',
                    transparent ? 'mt-4 md:mt-8' : 'mt-2 md:mt-2 md:mb-1'
                )}
            >
                <a className="m-0" href="/">
                    <DynamicLogo
                        className="m-auto sm:m-0 h-12 md:h-16"
                        local={localNumber}
                    ></DynamicLogo>
                </a>
                <IconButton className="p-0 text-3xl hidden">
                    <Menu className="text-white" fontSize="inherit" />
                </IconButton>
            </div>
        </div>
    );
};
