import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    CircularProgress,
    IconButton,
    TextField,
    useMediaQuery,
} from '@material-ui/core';
import { SignatureOrder } from '../app/constants';
import { useAppDispatch, useTypedSelector } from '../app/store';
import { css, cx } from 'emotion';
import SignatureCanvas from 'react-signature-canvas';
import {
    submitApplication as submitApplicationAction,
    updateJoinForm,
    updateSignature,
} from '../actions/joinFormActions';
import { AttachMoney, Check, ChevronRight, Replay } from '@material-ui/icons';
import { Signature, SignatureResponseStatus, SignatureType } from '../types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import rays from '../assets/rays.svg';
import sigPlaceholder from '../assets/signature_placeholder.svg';
import { useSnackbar } from 'notistack';
import { maxBy } from 'lodash-es';
import { ConditionalWrapper } from '../components/ConditionalWrapper';
import { PhotoFrame } from '../components/PhotoFrame';
import { mq } from '../app/theme';
import { Footer } from './Footer';
import { GradientButton } from '../components/GradientButton';
import { LoaderButton } from '../components/LoaderButton';
import {
    AppActions,
    fetchLocalEmployerLink,
    selectLocal,
} from '../actions/appActions';

const JPEG_COMPRESSION_RATE = 0.5;

export const Sign: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const canvas = useRef<SignatureCanvas | null>(null);
    const scrollEl = useRef<HTMLDivElement | null>(null);

    const localSigBackdoor = useRouteMatch<{ local: string }>('/:local/sign');
    useEffect(() => {
        if (localSigBackdoor?.params.local) {
            Promise.all([
                dispatch(
                    AppActions.fetchSignatures({
                        localId: localSigBackdoor.params.local,
                    })
                ).then(unwrapResult),
                dispatch(
                    fetchLocalEmployerLink({
                        local: localSigBackdoor?.params.local,
                        employer: '',
                    })
                ).then(unwrapResult),
            ]).then((res) => {
                const [signaturesResponse, localResponse] = res;
                if (
                    signaturesResponse.status ===
                    SignatureResponseStatus.LocalNotFound
                ) {
                    enqueueSnackbar(
                        'Invalid Local Selection: using default signatures',
                        {
                            variant: 'info',
                        }
                    );
                }

                if (localResponse?.local) {
                    dispatch(selectLocal(localResponse.local));
                }
            });
        }
    }, [localSigBackdoor?.params.local]);

    const isMd = useMediaQuery(mq('md', true));

    const local = useTypedSelector((state) => state.appReducer.local);

    const [currentStep, setCurrentStep] = useState(0);

    const unsortedConfigs = useTypedSelector(
        (state) => state.appReducer.signatureConfigs
    );

    const signatureConfigs = [...(unsortedConfigs ?? [])].sort((a, b) => {
        const idxA = SignatureOrder.indexOf(a.type);
        const idxB = SignatureOrder.indexOf(b.type);

        return idxA - idxB;
    });

    useEffect(() => {
        if (!unsortedConfigs && !localSigBackdoor?.params.local) {
            dispatch(
                AppActions.fetchSignatures({
                    localId: local?.localNumber,
                })
            )
                .then(unwrapResult)
                .then((res) => {
                    if (res.status === SignatureResponseStatus.LocalNotFound) {
                        enqueueSnackbar(
                            'Invalid Local: using default signature configuration',
                            {
                                variant: 'info',
                            }
                        );
                    }
                });
        }
    }, [
        unsortedConfigs,
        local,
        enqueueSnackbar,
        localSigBackdoor?.params.local,
    ]);

    const currentSignature = signatureConfigs[currentStep];

    const isLastSignature = currentStep === signatureConfigs.length - 1;

    const joinForm = useTypedSelector(
        (state) => state.joinFormReducer.joinForm
    );

    const [abcAmount, setAbcAmount] = useState<string>(
        joinForm.abcAmount?.toFixed(2).toString() ??
            local?.defaultAbcAmount?.toFixed(2) ??
            local?.minABCAmount?.toFixed(2) ??
            '1.00'
    );
    const [abcError, setAbcError] = useState<string | null>(null);

    const hasCompletedForm = useTypedSelector(
        (state) => state.joinFormReducer.isFormComplete
    );

    const [isDrawn, setDrawn] = useState<boolean>(false);
    const [isSubmitting, setSubmitting] = useState<{
        skip: boolean;
        continue: boolean;
    }>({
        skip: false,
        continue: false,
    });

    useEffect(() => {
        if (
            local &&
            (local.minABCAmount != undefined ||
                local.defaultAbcAmount !== undefined) &&
            (!joinForm.abcAmount || joinForm.abcAmount === 1)
        ) {
            if (local.defaultAbcAmount) {
                setAbcAmount(local.defaultAbcAmount.toFixed(2));
            } else if (local.minABCAmount) {
                setAbcAmount(local?.minABCAmount.toFixed(2));
            }
        }
    }, [local]);

    useEffect(() => {
        if (!hasCompletedForm) {
            enqueueSnackbar('Form not completed. Results may be unexpected.', {
                variant: 'warning',
            });
        }
    }, [hasCompletedForm, enqueueSnackbar]);

    const updateSig = () => {
        const sig = getSig();

        if (sig && currentSignature) {
            dispatch(
                updateSignature({
                    type: currentSignature.type,
                    value: sig,
                })
            );
        }
        canvas.current?.clear();
        setDrawn(false);
    };

    const handleDrawBegin = () => {
        if (!isDrawn) {
            setDrawn(true);
        }
    };

    const resetSig = () => {
        canvas.current?.clear();
        setDrawn(false);
    };

    const handleChanges = (hasSigned: boolean) => {
        if (currentSignature?.type === SignatureType.ActiveBallot) {
            if (hasSigned) {
                dispatch(
                    updateJoinForm({
                        abcAmount: parseFloat(abcAmount),
                    })
                );
            } else {
                dispatch(
                    updateJoinForm({
                        abcAmount: 0,
                    })
                );
            }
        }
    };

    const getSig = (): string => {
        const sigCanvas = canvas.current?.getCanvas();
        let sig =
            sigCanvas?.toDataURL('image/jpeg', JPEG_COMPRESSION_RATE) ?? '';

        // check if valid
        const match = sig.match(/A+/g) || [];

        const consecutiveAsCount = maxBy(match, (arr) => arr.length) || '';

        // check if larger than 40% of dataurl
        const total = sig.length;
        const aTotal = consecutiveAsCount.length;

        if (aTotal > total * 0.4) {
            sig = canvas.current?.getCanvas().toDataURL('image/png') ?? '';
        }

        return sig;
    };

    const incrementStep = (accept: boolean) => {
        updateSig();
        handleChanges(accept);
        setCurrentStep((currentStep) => currentStep + 1);
    };

    useEffect(() => {
        if (scrollEl.current) {
            scrollEl.current.scrollTop = 0;
        }
        if (document.scrollingElement) {
            document.scrollingElement.scrollTop = 0;
        }
    }, [currentStep]);

    const submitApplication = (button: keyof typeof isSubmitting) => {
        if (!hasCompletedForm) {
            if (local?.isUsingPayments && localSigBackdoor) {
                history.push('/dues');
            }
            enqueueSnackbar('Form not completed. Unable to submit', {
                variant: 'warning',
            });
            return;
        }
        updateSig();
        handleChanges(button === 'continue');
        setSubmitting((prev) => ({
            ...prev,
            [button]: true,
        }));
        dispatch(submitApplicationAction())
            .then(unwrapResult)
            .then((res) => {
                if (res.succeeded) {
                    if (local?.isUsingPayments) {
                        history.push('/dues');
                    } else {
                        history.push('/success');
                    }
                } else {
                    enqueueSnackbar('Unable to submit. Try again later', {
                        variant: 'error',
                    });
                }
            })
            .catch((e) => {
                enqueueSnackbar('Unable to submit. Try again later', {
                    variant: 'error',
                });
            })
            .finally(() => {
                setSubmitting((prev) => ({
                    ...prev,
                    [button]: false,
                }));
            });
    };

    if (!currentSignature) {
        return <CircularProgress className="m-auto" />;
    }

    return (
        <div className="flex-1 flex flex-col overflow-hidden md:overflow-auto">
            <SignatureWizard
                currentSignature={currentSignature}
                isFirst={currentStep === 0}
            />
            <ConditionalWrapper
                wrap={isMd}
                wrapper={(children) => (
                    <div className="px-8 mb-16">
                        <PhotoFrame
                            header={
                                currentStep === 0
                                    ? "Just a few signatures, and you'll be all set!"
                                    : currentSignature.title
                            }
                            className="max-w-screen-md m-auto -mt-16"
                            classes={{
                                header: 'text-ufcw-blue-dark text-sm',
                                body: 'w-full',
                                content: 'mt-8 -mb-10',
                            }}
                        >
                            {children}
                        </PhotoFrame>
                    </div>
                )}
            >
                <div
                    className="flex-1 font-serif text-sm leading-relaxed p-4 text-grey-900 whitespace-pre-line md:h-40 relative top-0 overflow-auto"
                    ref={scrollEl}
                >
                    {currentSignature.content}
                </div>
                <div
                    className={cx(
                        'space-y-5 p-4 md:pt-12 border-t shadow-t flex flex-col flex-shrink-0',
                        'md:shadow-none',
                        css({
                            boxShadow:
                                '0 -1px 10px 0 rgba(0, 0, 0, 0.06), 0 -1px 10px 0 rgba(0, 0, 0, 0.06)',
                            [mq('md')]: {
                                boxShadow: 'none',
                            },
                        })
                    )}
                >
                    {currentSignature.type === SignatureType.ActiveBallot && (
                        <TextField
                            label={`Amount Per ${
                                local?.isAssociateLocal ? 'Month' : 'Week'
                            }`}
                            type="number"
                            value={abcAmount}
                            className="md:max-w-xs"
                            error={Boolean(abcError)}
                            helperText={abcError}
                            onChange={(e) => {
                                const numVal = parseFloat(e.target.value);
                                const minContrib = local?.minABCAmount ?? 1;
                                if (numVal >= 0 || e.target.value === '') {
                                    setAbcAmount(e.target.value);
                                }
                                if (
                                    abcError &&
                                    (numVal >= minContrib || numVal === 0)
                                ) {
                                    setAbcError(null);
                                }
                            }}
                            onBlur={() => {
                                const abcNum = parseFloat(abcAmount);
                                if (abcNum > 10) {
                                    setAbcAmount((10).toFixed(2));
                                    enqueueSnackbar('Maximum amount is $10', {
                                        variant: 'info',
                                    });
                                } else {
                                    const minContrib = local?.minABCAmount ?? 1;
                                    if (abcNum < minContrib && abcNum !== 0) {
                                        setAbcError(
                                            `Minimum contribution is $${minContrib.toFixed(
                                                2
                                            )}`
                                        );
                                    } else {
                                        setAbcError(null);
                                    }

                                    const truncatedVal = parseFloat(
                                        abcAmount
                                    ).toFixed(2);

                                    setAbcAmount(truncatedVal);
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <AttachMoney
                                        type="number"
                                        className="text-gray-600 text-xl"
                                    />
                                ),
                            }}
                            inputProps={{
                                step: 1,
                            }}
                        />
                    )}
                    <div className="h-32 w-full relative">
                        <SignatureCanvas
                            ref={canvas}
                            backgroundColor="#fff"
                            onBegin={handleDrawBegin}
                            canvasProps={{
                                className: cx(
                                    'border-2 border-ufcw-orange rounded-lg w-full h-full',
                                    'md:border-gray-300'
                                ),
                            }}
                        />
                        <IconButton
                            className="absolute right-0 bottom-0"
                            onClick={resetSig}
                        >
                            <Replay className="text-gray-600" />
                        </IconButton>
                        {!isDrawn && (
                            <img
                                src={sigPlaceholder}
                                alt="signature placeholder"
                                className={cx(
                                    'absolute pointer-events-none opacity-25',
                                    css({
                                        top: '50%',
                                        height: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    })
                                )}
                            />
                        )}
                    </div>
                    {isLastSignature ? (
                        <div
                            className={cx(
                                'grid grid-cols-2 gap-2 md:w-1/2 md:max-w-sm md:m-auto',
                                css({
                                    [mq('md')]: {
                                        transform: 'translateY(1.25rem)',
                                        backgroundColor: '#fff !important',
                                        outline: '1.5rem solid #fff',
                                        '&:focus': {
                                            outline: '1.5rem solid #fff',
                                        },
                                    },
                                })
                            )}
                        >
                            {!currentSignature.required && (
                                <div className="flex-1 flex">
                                    <LoaderButton
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        ContainerProps={{
                                            className: 'flex flex-1',
                                        }}
                                        onClick={() =>
                                            submitApplication('skip')
                                        }
                                        disabled={Boolean(abcError)}
                                        loading={isSubmitting.skip}
                                    >
                                        Decline
                                    </LoaderButton>
                                </div>
                            )}
                            <GradientButton
                                ContainerProps={{
                                    className: cx(
                                        'flex',
                                        currentSignature.required &&
                                            'col-span-2'
                                    ),
                                }}
                                className={cx(
                                    'flex-1',
                                    (!isDrawn || Boolean(abcError)) &&
                                        css({
                                            '&[disabled]': {
                                                background: 'transparent',
                                                color: 'rgba(0, 0, 0, 0.3)',
                                                borderColor: 'currentColor',
                                                fontWeight: 500,
                                            },
                                        })
                                )}
                                loading={isSubmitting.continue}
                                disabled={!isDrawn || Boolean(abcError)}
                                onClick={() => submitApplication('continue')}
                            >
                                Continue
                            </GradientButton>
                        </div>
                    ) : (
                        <div
                            className={cx(
                                'grid grid-cols-2 gap-2 md:w-1/2 md:max-w-sm md:m-auto',
                                css({
                                    [mq('md')]: {
                                        transform: 'translateY(1.25rem)',
                                        backgroundColor: '#fff !important',
                                        outline: '1.5rem solid #fff',
                                        '&:focus': {
                                            outline: '1.5rem solid #fff',
                                        },
                                    },
                                })
                            )}
                        >
                            {!currentSignature.required && (
                                <Button
                                    size="large"
                                    variant="outlined"
                                    color="primary"
                                    className="flex-1"
                                    onClick={() => incrementStep(false)}
                                >
                                    Decline
                                </Button>
                            )}
                            <GradientButton
                                ContainerProps={{
                                    className: cx(
                                        'flex',
                                        currentSignature.required &&
                                            'col-span-2'
                                    ),
                                }}
                                className={cx(
                                    'flex-1',
                                    !isDrawn &&
                                        css({
                                            '&[disabled]': {
                                                background: 'transparent',
                                                color: 'rgba(0, 0, 0, 0.3)',
                                                borderColor: 'currentColor',
                                                fontWeight: 500,
                                            },
                                        })
                                )}
                                disabled={!isDrawn}
                                onClick={() => incrementStep(true)}
                            >
                                Continue
                            </GradientButton>
                        </div>
                    )}
                </div>
            </ConditionalWrapper>
            <Footer className="mt-auto hidden md:block" />
        </div>
    );
};

interface SignatureWizardProps {
    currentSignature: Signature;
    isFirst?: boolean;
}

const SignatureWizard: React.FC<SignatureWizardProps> = ({
    currentSignature,
    isFirst,
}) => {
    const signatures = useTypedSelector(
        (state) => state.appReducer.signatureConfigs
    );

    return (
        <div
            className={cx(
                'bg-gray-100',
                'md:pb-20 md:pt-5',
                css({
                    backgroundImage: `url(${rays})`,
                    backgroundPosition: 'top right',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '11rem',
                    [mq('md')]: {
                        backgroundSize: 'contain',
                    },
                })
            )}
        >
            <div className="flex p-2 px-4 justify-between m-auto max-w-sm border-b md:border-0">
                {SignatureOrder.map((type) =>
                    signatures?.find((sig) => sig.type === type)
                )
                    .filter(Boolean)
                    .map((sig, i) => {
                        if (!sig) {
                            return null;
                        }

                        return (
                            <React.Fragment key={sig.type}>
                                {i !== 0 && (
                                    <ChevronRight className="text-gray-300 text-4xl" />
                                )}
                                <SignatureBubble
                                    position={i + 1}
                                    signature={sig}
                                    active={sig.type === currentSignature.type}
                                />
                            </React.Fragment>
                        );
                    })}
            </div>
            {isFirst && (
                <div className="px-4 pb-2 pt-1 md:pb-6 pr-8 md:hidden">
                    <h3 className="text-ufcw-blue-dark text-md font-semibold md:text-xl text-left">
                        Just three signatures, and you'll be all set!
                    </h3>
                </div>
            )}
        </div>
    );
};

interface SignatureBubbleProps {
    position: number;
    signature: Signature;
    active?: boolean;
}

const SignatureBubble: React.FC<SignatureBubbleProps> = ({
    position,
    signature,
    active = false,
}) => {
    const isDone = useTypedSelector(
        (state) => !!state.joinFormReducer.signatures[signature.type]
    );

    return (
        <div className="flex items-center">
            <div
                className={cx(
                    'w-6 h-6 rounded-full flex items-center font-semibold justify-center',
                    !active && 'bg-gray-300 text-gray-600',
                    active && 'bg-ufcw-orange text-white'
                )}
            >
                {isDone && !active ? (
                    <Check className="text-gray-700 text-lg" />
                ) : (
                    position
                )}
            </div>
            {active && (
                <span className="ml-2 font-semibold text-sm text-ufcw-blue">
                    {signature.title}
                </span>
            )}
        </div>
    );
};
