import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { css, cx } from 'emotion';
import { CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router';
import { debug } from 'console';
const urlSlug = require('url-slug');

function PrintSignUp() {
    const location = useLocation();
    const [qrSize, setQrSize] = useState<number | null>(null);
    const setQRSize = (ref: HTMLDivElement | null) => {
        if (!ref) {
            return;
        }

        setQrSize(ref.offsetWidth);
    };

    const joinPath = location.pathname.substring(
        0,
        location.pathname.lastIndexOf('/')
    );

    const pathParts = joinPath.split('/');

    const local = pathParts[1];
    const employerNumber = pathParts[2];
    const employerName = pathParts[3];
   

    return (
        <div className="flex flex-col items-center">
            <div
                className={cx(
                    'w-full',
                    css({
                        maxWidth: 360,
                        marginTop: 16,
                    })
                )}
            >
                <img
                    className="block mx-auto"
                    src="/images/for-a-better-life.svg"
                />
                <div
                    className={cx(
                        'border-8 border-solid border-yellow-500 flex items-center justify-center',
                        css({ marginBottom: 20, marginTop: 60 })
                    )}
                >
                    <div id="qr" ref={setQRSize} className={cx('w-full')}>
                        {qrSize && (
                            <QRCode
                                size={qrSize}
                                className="p-5"
                                value={window.location.origin + '/join' + joinPath}
                            />
                        )}
                    </div>
                </div>

                <div
                    className={cx(
                        'flex items-center justify-center',
                        css({ marginBottom: 60 })
                    )}
                >
                    <img
                        src="/images/phone-qr.svg"
                        height="32"
                        width="19"
                        style={{ marginRight: 20 }}
                    />
                    <p
                        className={cx(
                            'uppercase font-bold text-blue-800 text-lg'
                        )}
                    >
                        {employerNumber
                            ? `JOIN.UFCW.ORG/JOIN/${local}/${employerNumber}`
                            : `JOIN.UFCW.ORG/JOIN/${local}`}
                    </p>
                </div>
            </div>

            <img
                className={cx(
                    'block my-0 mx-auto max-w-full',
                    css({
                        maxHeight: 76,
                        maxWidth: 256,
                    })
                )}
                src={'/images/join-ufcw.svg'}
                alt="UFCW"
            />
            {employerName && (
                <div>
                    <p className="flex justify-center text-gray-800 uppercase">
                        {employerNumber}{' '}
                        {urlSlug.revert(employerName)}
                    </p>
                </div>
            )}
        </div>
    );
}

export default PrintSignUp;
