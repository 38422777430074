import React, { FC, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../app/store';
import { ChevronRight, PlayArrow } from '@material-ui/icons';
import { Footer } from './Footer';
import { cx, css } from 'emotion';
import { palette, mq } from '../app/theme';
import { PhotoFrame } from '../components/PhotoFrame';
import heroImages from '../assets/hero-images';
import happyFruitBg from '../assets/ms-happy-fruit.jpg';
import { GradientButton } from '../components/GradientButton';
import { quillStyleReset } from '../app/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog, Modal } from '@material-ui/core';
import JoinVideo from '../components/JoinVideo';

export const Welcome: FC = () => {
    const history = useHistory();
    const handleJoin = useCallback(() => {
        history.push('/join');
    }, [history]);

    const member = useTypedSelector((state) => state.appReducer.employee);
    const hasFormId = useTypedSelector((state) => state.appReducer.formId);
    const local = useTypedSelector((state) => state.appReducer.local);
    const [bgImage, setBgImage] = useState(heroImages[local?.appImage ?? 0]);
    const [showPreviewVideoModal, setShowPreviewVideoModal] = useState(false);

    const desktop = useMediaQuery('(min-width:600px)');
    const mobile = useMediaQuery('(max-width:600px)');

    // const ref = React.createRef();

    useEffect(() => {
        setBgImage(heroImages[local?.appImage ?? 0]);
    }, [local]);

    return (
        <div className="-mt-24">
            <header
                className={cx(
                    'px-8 py-8',
                    (local?.appImage ?? 0) <= 4 ||
                        [12, 19].includes(local?.appImage ?? 0)
                        ? css({
                              backgroundColor: '#0054a6',
                              position: 'relative',
                              backgroundImage: `linear-gradient(to top, ${palette.ufcwBlueDark} 10%, transparent 75%), url(${bgImage})`,
                              backgroundPosition: 'center 50%',
                              backgroundSize: 'cover',

                              [mq('md')]: {
                                  backgroundImage: `linear-gradient(to right, ${palette.ufcwBlueDark} 10%, transparent 55%), url(${bgImage})`,
                                  backgroundPosition: '20% 25%',
                                  backgroundSize: '164% auto',
                              },

                              [mq('xl')]: {
                                  backgroundImage: `linear-gradient(to right, ${palette.ufcwBlueDark} 25%, transparent 55%), url(${bgImage})`,
                                  backgroundPosition: 'right 25%',
                                  backgroundSize: '100% auto',
                              },
                          })
                        : css({
                              backgroundColor: '#0054a6',
                              position: 'relative',
                              backgroundImage: `linear-gradient(to top, ${palette.ufcwBlueDark} 10%, transparent 75%), url(${bgImage})`,
                              backgroundPosition: '70% 0px',
                              backgroundSize: 'cover',

                              [mq('md')]: {
                                  backgroundImage: `linear-gradient(to right, ${palette.ufcwBlueDark} 10%, transparent 55%), url(${bgImage})`,
                                  backgroundPosition: 'right 0',
                                  backgroundSize: 'cover',
                              },

                              [mq('xl')]: {
                                  backgroundImage: `linear-gradient(to right, ${palette.ufcwBlueDark} 25%, transparent 55%), url(${bgImage})`,
                                  backgroundPosition: 'right 0',
                                  backgroundSize: 'cover',
                              },
                          })
                )}
            >
                {mobile && local?.videoUrl && (
                    <div
                        onClick={() => setShowPreviewVideoModal(true)}
                        className={cx(
                            'absolute bg-black opacity-75 flex items-center',
                            css({
                                top: '40%',
                                left: '50%',
                                borderRadius: 40,
                                width: 165,
                                transform: 'translate(-50%)',
                            })
                        )}
                    >
                        <PlayArrow className="text-white text-6xl" />
                        <p className="text-white">Watch Video</p>
                    </div>
                )}
                <div
                    className={cx(
                        'flex flex-col items-center max-w-screen-xl justify-end text-center m-auto',
                        'md:items-start md:justify-center md:text-left md:py-32 px-0 sm:px-4 md:px-4 lg:px-7 xl:px-12 md:pb-40'
                    )}
                >
                    <h1
                        className={cx(
                            'text-lg mt-64 font-bold mb-2 text-ufcw-yellow',
                            'md:mt-24 md:text-2xl md:mb-10'
                        )}
                    >
                        {hasFormId
                            ? `Hi ${member?.firstName} ${member?.lastName}!`
                            : 'Welcome!'}
                    </h1>
                    <h3
                        className={cx(
                            'font-black text-white text-4xl text-shadow',
                            'md:text-6xl md:max-w-32 leading-tight mb-6 md:mb-8'
                        )}
                    >
                        Join UFCW for <br className="hidden sm:block" />a Better
                        Life
                    </h3>
                    <div className={cx('hidden', 'md:block md:mt-4')}>
                        <GradientButton
                            onClick={handleJoin}
                            className="text-white md:w-64"
                            size="large"
                            fullWidth
                            endIcon={
                                <ChevronRight className="text-ufcw-yellow" />
                            }
                        >
                            Get Started
                        </GradientButton>
                    </div>
                    {hasFormId && (
                        <p
                            className={cx(
                                'text-center text-gray-200 text-sm mt-12 hidden',
                                'md:block'
                            )}
                        >
                            Not {member?.firstName} {member?.lastName}?{' '}
                            <a
                                href={`${
                                    local?.contactPageUrl ??
                                    'http://www.ufcw.org/about/connect-with-the-ufcw/'
                                }`}
                                target="_blank"
                                className="underline"
                            >
                                Contact Us
                            </a>
                        </p>
                    )}
                </div>
            </header>
            <div className={cx('mx-6 transform -translate-y-1/2', 'md:hidden')}>
                <GradientButton
                    onClick={handleJoin}
                    className="text-white"
                    size="large"
                    fullWidth
                    endIcon={<ChevronRight className="text-ufcw-yellow" />}
                >
                    Get Started
                </GradientButton>
            </div>

            {hasFormId && (
                <p
                    className={cx(
                        'text-center text-gray-600 text-xs mb-8',
                        'md:hidden'
                    )}
                >
                    Not {member?.firstName} {member?.lastName}?{' '}
                    <a
                        href={`${
                            local?.contactPageUrl ??
                            'http://www.ufcw.org/about/connect-with-the-ufcw/'
                        }`}
                        target="_blank"
                        className="text-ufcw-indigo underline"
                    >
                        Contact Us
                    </a>
                </p>
            )}
            {desktop && (
                <div className="md:flex md:justify-center md:mx-8 pt-2 md:pt-0">
                    <PhotoFrame
                        header="We're Here for You"
                        img={happyFruitBg}
                        video={local?.videoUrl}
                        className={cx(
                            'mb-8 mx-auto',
                            'md:max-w-screen-xl md:-mt-16 md:px-4 md:pb-0'
                        )}
                        classes={{
                            header:
                                'text-ufcw-blue md:text-white md:mb-12 md:text-2xl text-shadow',
                            image: 'pt-0 md:pt-8 pb-0 md:py-8',
                            content: 'md:text-base',
                            body:
                                'flex md:flex-row md:px-8 md:space-x-8 md:items-center md:pb-6',
                        }}
                    >
                        <div className="px-3 pb-4">
                            {local?.welcomePageTemplate ? (
                                <div
                                    className={quillStyleReset}
                                    dangerouslySetInnerHTML={{
                                        __html: local.welcomePageTemplate,
                                    }}
                                ></div>
                            ) : (
                                <>
                                    <h3 className="inline-block margin-auto text-xl font-bold mb-3">
                                        Being a UFCW union member is about
                                        having a strong contract, and knowing
                                        that we’ll always stand together.
                                    </h3>
                                    <p className="font-serif md:mt-2">
                                        We work to build a better life for
                                        hard-working men and women through:
                                    </p>
                                    <ul className="list-disc ml-6 font-serif md:mt-2">
                                        <li className="font-serif">
                                            Free College Degree Programs
                                        </li>
                                        <li className="font-serif">
                                            Language Classes
                                        </li>
                                        <li className="font-serif">
                                            Discounts on your wireless bill,
                                            movie tickets, legal programs and
                                            more...
                                        </li>
                                    </ul>
                                </>
                            )}
                        </div>
                    </PhotoFrame>
                </div>
            )}

            {mobile && (
                <div>
                    {showPreviewVideoModal && local && (
                        <Modal
                            open={true}
                            onClose={() => {
                                setShowPreviewVideoModal(false);
                            }}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            style={{
                                marginTop: 80,
                            }}
                        >
                            <div className="flex">
                                <JoinVideo
                                    video={local?.videoUrl}
                                    mobile={true}
                                />
                            </div>
                        </Modal>
                    )}
                    <PhotoFrame
                        header="We're Here for You"
                        img={happyFruitBg}
                        video=""
                        className={cx(
                            'mb-8 mx-auto',
                            'md:max-w-screen-xl md:-mt-16 md:px-4 md:pb-0'
                        )}
                        classes={{
                            header:
                                'text-ufcw-blue md:text-white md:mb-12 md:text-2xl text-shadow',
                            image: 'pt-0 md:pt-8 pb-0 md:py-8',
                            content: 'md:text-base',
                        }}
                    >
                        <div className="px-3 pb-4">
                            {local?.welcomePageTemplate ? (
                                <div
                                    className={quillStyleReset}
                                    dangerouslySetInnerHTML={{
                                        __html: local.welcomePageTemplate,
                                    }}
                                ></div>
                            ) : (
                                <>
                                    <h3 className="inline-block margin-auto text-xl font-bold mb-3">
                                        Being a UFCW union member is about
                                        having a strong contract, and knowing
                                        that we’ll always stand together.
                                    </h3>
                                    <p className="font-serif md:mt-2">
                                        We work to build a better life for
                                        hard-working men and women through:
                                    </p>
                                    <ul className="list-disc ml-6 font-serif md:mt-2">
                                        <li className="font-serif">
                                            Free College Degree Programs
                                        </li>
                                        <li className="font-serif">
                                            Language Classes
                                        </li>
                                        <li className="font-serif">
                                            Discounts on your wireless bill,
                                            movie tickets, legal programs and
                                            more...
                                        </li>
                                    </ul>
                                </>
                            )}
                        </div>
                    </PhotoFrame>
                </div>
            )}
            <Footer className="md:mt-16" />
        </div>
    );
};
