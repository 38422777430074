import { Field, FieldProps } from 'formik';
import React, { ChangeEvent } from 'react';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class FComboBoxField extends React.Component<
    Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> & {
        name: string;
        label?: string;
        displayField?: string;
        valueField?: string;
        onChange?: (e: any, val: any) => void;
        required?: boolean;
        hideErrorText?: boolean;
    }
> {
    render() {
        const {
            hideErrorText,
            displayField,
            valueField,
            name,
            required,
            value,
            ...props
        } = this.props;
        return (
            <Field name={name}>
                {({ field, form }: FieldProps) => {
                    const { errors, touched } = form;
                    const { name, ...fieldProps } = field;

                    // this is probably very bad. We should write another field that is less sencha-y and moar M-UI to avoid this
                    let val = props.options?.find(
                        (opt) =>
                            opt?.value === field.value || opt === field.value
                    );

                    if (!val && props.freeSolo) {
                        val = {
                            [displayField ?? 'text']: field.value,
                            [valueField ?? 'value']: field.value,
                        };
                    }

                    return (
                        <Autocomplete
                            renderOption={(option, state) =>
                                option[valueField ?? 'text'] ?? ''
                            }
                            getOptionLabel={(option) =>
                                option[displayField ?? 'text'] ?? ''
                            }
                            getOptionSelected={(option, value) => {
                                return option === value;
                            }}
                            value={val ?? {}}
                            renderInput={(params) => (
                                <TextField
                                    error={
                                        !!(
                                            (touched[name] ||
                                                form.submitCount > 0) &&
                                            errors[name]
                                        )
                                    }
                                    helperText={
                                        !hideErrorText &&
                                        (touched[name] ||
                                            form.submitCount > 0) &&
                                        errors[name]
                                    }
                                    {...fieldProps}
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                    onChange={
                                        props.freeSolo
                                            ? (e) => {
                                                  form.setFieldValue(
                                                      name,
                                                      e.target.value
                                                  );
                                              }
                                            : undefined
                                    }
                                    placeholder={props.placeholder}
                                    fullWidth
                                    label={props.label}
                                    value={val ?? (field.value || '')}
                                    required={required}
                                />
                            )}
                            {...props}
                            onChange={(e: ChangeEvent<{}>, value: any) => {
                                const val =
                                    (value && value[valueField ?? 'value']) ??
                                    '';
                                form.setFieldValue(name, val);
                                props.onChange && props.onChange(e, value);
                            }}
                            onBlur={(e) => {
                                form.setFieldTouched(name, true);
                                props.onBlur && props.onBlur(e);
                            }}
                        />
                    );
                }}
            </Field>
        );
    }
}

export default FComboBoxField;
