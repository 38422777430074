import React, { useRef, FC } from 'react';
import { StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';
import { AddressDetails } from '../../types';
import FTextField, { FTextFieldProps } from './FTextField';

const gMapsLibs = ['places'];

function mapPlaceToAddressComponents(
    place: google.maps.places.PlaceResult
): AddressDetails | undefined {
    if (!place) {
        return;
    }

    let street = '',
        route = '',
        city = '',
        state = '',
        zip = '';
    if (place && place.address_components) {
        street =
            place.address_components.find((c) =>
                c.types.includes('street_number')
            )?.long_name ?? '';
        route =
            place.address_components.find((c) => c.types.includes('route'))
                ?.long_name ?? '';
        city =
            (
                place.address_components.find((c) =>
                    c.types.includes('neighborhood')
                ) ||
                place.address_components.find((c) =>
                    c.types.includes('locality')
                ) ||
                place.address_components.find((c) =>
                    c.types.includes('sublocality')
                )
            )?.long_name ?? '';
        state =
            place.address_components.find((c) =>
                c.types.includes('administrative_area_level_1')
            )?.long_name ?? '';
        zip =
            place.address_components.find((c) =>
                c.types.includes('postal_code')
            )?.long_name ?? '';
    }

    const streetAddress = `${street} ${route}`.trim();

    return {
        streetAddress,
        state,
        city,
        zip,
    };

    // formProps.setFieldValue('addressLine1', streetAddress?.trim() || '');
    // formProps.setFieldValue('state', state?.short_name ?? '');
    // formProps.setFieldValue('city', city?.long_name ?? '');
    // formProps.setFieldValue('zipCode', zip?.long_name || '');
    // formProps.setFieldValue('city', city?.long_name || '');
}

export type FAddressAutocompleteProps = FTextFieldProps & {
    onPlaceChange?: (
        place?: AddressDetails,
        placeResult?: google.maps.places.PlaceResult
    ) => void;
};

export const FAddressAutocomplete: FC<FAddressAutocompleteProps> = ({
    onPlaceChange,
    ...props
}) => {
    const searchBox = useRef<any>(null);
    const { isLoaded, loadError } = useLoadScript({
        id: 'google-maps-script-loader',
        googleMapsApiKey: 'AIzaSyDOugrTuEDKl3Is8wh4ct-3XNs3dxNnkEg',
        libraries: gMapsLibs,
    });

    if (isLoaded) {
        return (
            <StandaloneSearchBox
                onLoad={(ref) => (searchBox.current = ref)}
                onPlacesChanged={() => {
                    const place = searchBox.current?.getPlaces()[0];
                    const addressComponents = mapPlaceToAddressComponents(
                        place
                    );
                    onPlaceChange?.(addressComponents, place);
                }}
            >
                <FTextField placeholder="" {...props} ref={props.innerRef} />
            </StandaloneSearchBox>
        );
    } else if (loadError) {
        return <FTextField {...props} ref={props.innerRef} />;
    } else {
        return <div />;
    }
};
