import {
    DatePickerProps,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { Field, FieldProps, FieldAttributes } from 'formik';
import React from 'react';
import { fromUTC } from '../../app/utils';
import { isValid } from 'date-fns';

interface Props extends Partial<DatePickerProps> {
    pickerType?: 'DATE' | 'DATETIME';
    hideErrorText?: boolean;
    validate?: FieldAttributes<any>['validate'];
}

class FDatePickerField extends React.Component<Props> {
    render() {
        const {
            hideErrorText,
            name,
            pickerType,
            validate,
            ...props
        } = this.props;
        return (
            <Field name={name} validate={validate}>
                {({ field, form }: FieldProps) => {
                    const { errors, touched } = form;
                    const { name, ...fieldProps } = field;
                    const val = field.value ? fromUTC(field.value) : null;
                    if (!pickerType || pickerType === 'DATE') {
                        return (
                            <KeyboardDatePicker
                                error={
                                    !!(
                                        (touched[name] ||
                                            form.submitCount > 0) &&
                                        errors[name]
                                    )
                                }
                                helperText={
                                    !hideErrorText &&
                                    (touched[name] || form.submitCount > 0) &&
                                    errors[name]
                                }
                                autoOk
                                variant="inline"
                                {...props}
                                {...fieldProps}
                                PopoverProps={{
                                    disableScrollLock: true,
                                }}
                                value={val}
                                onKeyUpCapture={({ keyCode, target }) => {
                                    let value = (target as HTMLInputElement)
                                        .value;
                                    let groupOne = /^(\d)_/;
                                    let groupTwo = /^(\d{2})\/(\d)_/;
                                    if (
                                        value.match(groupOne) &&
                                        (keyCode === 191 || keyCode === 189)
                                    ) {
                                        let [, month] = value.match(
                                            groupOne
                                        ) as Array<string>;
                                        (target as HTMLInputElement).value =
                                            '0' + month + '/__/____';
                                    }
                                    if (
                                        value.match(groupTwo) &&
                                        (keyCode === 191 || keyCode === 189)
                                    ) {
                                        let [, month, day] = value.match(
                                            groupTwo
                                        ) as Array<string>;
                                        (target as HTMLInputElement).value =
                                            month + '/0' + day + '/____';
                                    }
                                }}
                                onChange={(newVal) => {
                                    form.setFieldValue(
                                        name,
                                        isValid(newVal)
                                            ? newVal?.toISOString()
                                            : ''
                                    );
                                    props.onChange && props.onChange(newVal);
                                }}
                                onBlur={(e) => {
                                    form.setFieldTouched(name, true);
                                    props.onBlur && props.onBlur(e);
                                }}
                                format="MM/dd/yyyy"
                            />
                        );
                    } else if (pickerType === 'DATETIME') {
                        return (
                            <KeyboardDateTimePicker
                                error={
                                    !!(
                                        (touched[name] ||
                                            form.submitCount > 0) &&
                                        errors[name]
                                    )
                                }
                                helperText={
                                    !hideErrorText &&
                                    (touched[name] || form.submitCount > 0) &&
                                    errors[name]
                                }
                                autoOk
                                variant="inline"
                                {...props}
                                {...fieldProps}
                                value={val}
                                PopoverProps={{
                                    disableScrollLock: true,
                                }}
                                onChange={(newVal) => {
                                    form.setFieldValue(
                                        name,
                                        isValid(newVal)
                                            ? newVal?.toISOString()
                                            : ''
                                    );
                                    props.onChange && props.onChange(newVal);
                                }}
                                onBlur={(e) => {
                                    form.setFieldTouched(name, true);
                                    props.onBlur && props.onBlur(e);
                                }}
                                format="MM/dd/yyyy hh:mm a"
                            />
                        );
                    }
                }}
            </Field>
        );
    }
}

export default FDatePickerField;
