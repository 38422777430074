import React from 'react';
import { cx } from 'emotion';

interface FooterProps {
    className?: string;
}

export const Footer: React.FC<FooterProps> = ({ className }) => {
    return (
        <div className={cx('px-16 py-8 md:py-16 bg-gray-200', className)}>
            <a href="http://ufcw.org"><img
                src="/images/UFCW_logo.png"
                alt="Logo"
                className="h-8 m-auto mb-3"
            /></a>
            <div className="text-ufcw-gray-light text-sm text-center">
                The United Food and Commercial Workers International Union
            </div>
        </div>
    );
};
