import React, { useState } from 'react';
import { Footer } from './Footer';
import { Button } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { cx, css } from 'emotion';
import { mq } from '../app/theme';
import DynamicLogo from '../components/DynamicLogo';
import { ChevronRight } from '@material-ui/icons';
import { AnimatedThumb } from '../components/AnimatedThumb';

export const Learn: React.FC = () => {
    const [hash, setHash] = useState<number>(0);
    const isMd = useMediaQuery(mq('md', true));
    return (
        <div className="flex-1 flex flex-col">
            <div className={cx('flex z-10 w-full absolute')}>
                <div
                    className={cx(
                        'flex justify-between items-center text-center px-4 md:px-12 z-10 h-16 flex-shrink-0 w-full max-w-screen-xl mx-auto mt-8'
                    )}
                >
                    <a className="m-auto sm:m-0" href="/">
                        <DynamicLogo className="m-auto sm:m-0 h-12 md:h-16"></DynamicLogo>
                    </a>
                    <p className="hidden sm:block text-white">
                        Call{' '}
                        <em className="not-italic text-ufcw-yellow">
                            1-888-725-1602
                        </em>{' '}
                        or{' '}
                        <a
                            href="http://www.ufcw.org/about/connect-with-the-ufcw/"
                            className="underline"
                        >
                            Contact Us
                        </a>
                    </p>
                </div>
            </div>
            <div
                className={cx(
                    'flex flex-col items-center flex-shrink-0 py-32 bg-ufcw-blue-dark pt-24 sm:pt-32 lg:pt-48',
                    css({
                        paddingBottom: '12rem',
                        [mq('md')]: {
                            paddingBottom: '18rem',
                        },
                        [mq('lg')]: {
                            paddingBottom: '24rem',
                        },
                    })
                )}
            >
                <h1 className="text-white text-center font-extrabold mt-2 text-2xl sm:text-5xl lg:text-6xl mt-8 leading-snug relative z-10">
                    Membership Applications &amp;
                    <br />
                    Management Made Easy
                </h1>
                <AnimatedThumb
                    key={hash}
                    className={cx(
                        'h-16 w-12 cursor-pointer absolute z-0 -mt-12 md:-mt-16 lg:-mt-24',
                        css({
                            filter: 'grayscale(100%) brightness(0%)',
                            opacity: '.15',
                            width: '12rem',
                            [mq('sm')]: {
                                width: '16rem',
                            },
                            [mq('md')]: {
                                width: '20rem',
                            },
                            [mq('lg')]: {
                                width: '24rem',
                            },
                        })
                    )}
                    onClick={() => setHash(Date.now())}
                />
            </div>
            <div>
                <div className="border border-l-0 sm:border-l border-r-0 sm:border-r border-yellow-400 sm:rounded-lg m-auto my-8 p-6 pb-12 md:p-12 w-full sm:w-5/6 lg:w-2/3 max-w-screen-lg -mt-32 md:-mt-48 lg:-mt-64 relative flex items-center justify-center content-center">
                    <div className="bg-ufcw-blue-dark absolute text-white top-0 -mt-4 md:-mt-5 px-4 text-lg md:text-2xl font-bold">
                        How it Works
                    </div>
                    <div
                        className={cx(
                            'relative h-0 w-full shadow-lg relative',
                            css({
                                paddingBottom: '56.25%',
                            })
                        )}
                    >
                        {/* youtube without closing voiceover: nxZVvmUmqOc */}
                        {/* youtube initial/old closing voiceover: 3N9XdYZqLkI */}
                        <iframe
                            src="https://www.youtube.com/embed/csGXA5AavAw?rel=0"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="video"
                            className="absolute w-full h-full top-0 left-0"
                        />
                    </div>
                </div>
            </div>
            <div>
                <div
                    className={cx(
                        'flex flex-col items-center justify-center content-center',
                        css({
                            marginTop: '-3.5rem',
                        })
                    )}
                >
                    <div className="bg-white px-4 relative">
                        <Button
                            color="primary"
                            variant="contained"
                            className={cx(
                                css({
                                    backgroundColor: 'rgb(84,114,210)',
                                    background:
                                        'linear-gradient(180deg, rgba(84,114,210,1) 0%, rgba(73,99,184,1) 100%)',
                                    border: '1px solid #445CAB',
                                    boxShadow:
                                        '0 1px 0 rgba(0,0,0,.16) !important',
                                    letterSpacing: '.075em',
                                    fontWeight: 'bold',
                                    fontSize: '14px !important',
                                    padding: '10px 22px !important',
                                }),
                                'm-auto w-auto text-xl'
                            )}
                            href="http://www.ufcw.org/about/connect-with-the-ufcw/"
                            disableElevation={isMd}
                            fullWidth
                            size="large"
                            type="submit"
                            endIcon={
                                <ChevronRight className="text-ufcw-yellow" />
                            }
                        >
                            Contact Us
                        </Button>
                    </div>
                    <p className="text-gray-600 mt-4">
                        or Call{' '}
                        <em className="font-bold not-italic">1-888-725-1602</em>{' '}
                        to Get Started
                    </p>
                </div>
            </div>
            <div>
                <div className="flex flex-col-reverse md:flex-row w-5/6 lg:w-1/2 max-w-screen-lg m-auto pt-16 md:pt-32 pb-24 md:pb-24">
                    <div className="flex flex-col w-full md:w-2/3">
                        <h2 className="text-ufcw-blue-dark font-bold text-lg md:text-2xl text-center md:text-left mt-10 md:mt-0">
                            "Join UFCW" Shareable PDF Flyer
                        </h2>
                        <p className="font-serif text-sm md:text-lg mt-4 md:mt-10 leading-relaxed text-center md:text-left">
                            Learn more about the features of the Join UFCW app
                            with this one-page printable flyer. Share with
                            others as a printout or a digital file.
                        </p>
                        <Button
                            component="a"
                            variant="outlined"
                            color="primary"
                            size="large"
                            className="m-auto md:m-0 w-48 mt-5 md:mt-10 font-bold pt-2 pb-2"
                            href="/join-ufcw-flyer.pdf"
                            target="_blank"
                        >
                            Download Flyer
                        </Button>
                    </div>
                    <div className="sm:pl-6 flex-1">
                        <img
                            className="shadow-lg m-auto"
                            src="/images/join-flyer.jpg"
                            height="250"
                            width="193"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <Footer className="mt-auto md:mt-16" />
        </div>
    );
};
