import React, { useCallback, SVGProps } from 'react';
import { css, cx } from 'emotion';

const Snap = require(`imports-loader?this=>window,fix=>module.exports=0!snapsvg/dist/snap.svg.js`);

interface AnimatedThumbProps extends SVGProps<SVGSVGElement> {}

const svgClass = css({
    overflow: 'visible',
    display: 'block',
    height: 'auto',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',

    path: {
        stroke: '#FFC315',
        strokeWidth: 10,
        fill: 'transparent',
    },

    '.thumb-line': {
        stroke: 'rgba(255, 255, 255, .5)',
        strokeWidth: 10,
        strokeLinecap: 'round',
        transition: '.3s ease-in-out',
    },
});

export const AnimatedThumb: React.FC<AnimatedThumbProps> = ({
    className,
    ...props
}) => {
    const svgCallback = useCallback((el: SVGSVGElement) => {
        if (el !== null) {
            const thumb1 = Snap.select('#thumb-1');
            const thumb2 = Snap.select('#thumb-2');
            const thumb3 = Snap.select('#thumb-3');

            const line1 = Snap.select('#line1');
            const line2 = Snap.select('#line2');
            const line3 = Snap.select('#line3');

            const thumb2Points = thumb2.node.getAttribute('d');
            const thumb3Points = thumb3.node.getAttribute('d');

            const toThumb = function () {
                thumb1.animate(
                    { d: thumb2Points },
                    250,
                    mina.backout,
                    toThumb4
                );
            };

            const toThumb4 = function () {
                line1.attr({ opacity: 1 });
                line2.attr({ opacity: 1 });
                line3.attr({ opacity: 1 });
                thumb1.animate({ d: thumb3Points }, 150, mina.backout, finish);
            };

            const finish = function () {
                line1.attr({ opacity: 0 });
                line2.attr({ opacity: 0 });
                line3.attr({ opacity: 0 });
            };

            setTimeout(() => {
                toThumb();
            }, 500);
        }
    }, []);

    return (
        <svg
            ref={svgCallback}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="317.6px"
            height="210.8px"
            viewBox="0 0 317.6 210.8"
            className={cx(svgClass, className)}
            xmlSpace="preserve"
            {...props}
        >
            <g id="line-group">
                <line
                    opacity="0"
                    id="line1"
                    className="thumb-line"
                    x1="114.1"
                    y1="7.1"
                    x2="89.6"
                    y2="-17.4"
                />
                <line
                    opacity="0"
                    id="line2"
                    className="thumb-line"
                    x1="229.6"
                    y1="7.1"
                    x2="254.1"
                    y2="-17.4"
                />
                <line
                    opacity="0"
                    id="line3"
                    className="thumb-line"
                    x1="166.8"
                    y1="-40.9"
                    x2="166.8"
                    y2="-75.5"
                />
            </g>
            <path
                id="thumb-1"
                className="st0"
                d="M267.1,124.2L267.1,124.2c12,0,21.7,9.7,21.7,21.7c0,12-9.7,21.7-21.7,21.7h-14.4
            c12,0,21.7,9.7,21.7,21.7c0,12-9.7,21.7-21.7,21.7h-93.8c-57.7,0-43.3-28.9-158.8-28.9V52h43.3c38.2-30.8,61-25,122.7-43.3
            c25.4-10.4,82-1.4,108.6-8.3c17.9-4.6,24.9,33.1,2.9,37.2c-20.6,4-75.9,1.5-97.1,0h115.5c12,0,21.7,9.7,21.7,21.7
            c0,12-9.7,21.7-21.7,21.7h-14.4c12,0,21.7,9.7,21.7,21.7c0,12-9.7,21.7-21.7,21.7H267.1"
            />
            <path
                opacity="0"
                id="thumb-2"
                className="st0"
                d="M276.6,122.8L276.6,122.8c11.5-3.2,23.5,3.5,26.7,15c3.2,11.5-3.5,23.5-15,26.7l-13.9,3.9
            c11.5-3.2,23.5,3.5,26.7,15c3.2,11.5-3.5,23.5-15,26.7l-90.3,25.4c-55.6,15.6-49.5-16.1-160.7,15.2L0,125.6l41.7-11.7
            c28.5-40,52-40.6,106.4-74.9c21.6-16.9,78.5-23.6,102.3-37.4c16-9.3,32.9,25.2,12.9,35c-18.8,9.5-73.5,23.1-93.5,26.3L281,31.6
            c11.5-3.2,23.5,3.5,26.7,15c3.2,11.5-3.5,23.5-15,26.7l-13.9,3.9c11.5-3.2,23.5,3.5,26.7,15c3.2,11.5-3.5,23.5-15,26.7
            L276.6,122.8"
            />
            <path
                opacity="0"
                id="thumb-3"
                className="st0"
                d="M267.1,204L267.1,204c12,0,21.7,9.7,21.7,21.7c0,12-9.7,21.7-21.7,21.7h-14.4
            c12,0,21.7,9.7,21.7,21.7c0,12-9.7,21.7-21.7,21.7h-93.8c-57.7,0-43.3-28.9-158.8-28.9V131.8h43.3
            c61.1-3.5,108.8-54.3,108.3-115.5c0-22.8,43.3-25.7,43.3,17.6c-2.2,28.2-7,56.1-14.4,83.5h115.5c12,0,21.7,9.7,21.7,21.7
            c0,12-9.7,21.7-21.7,21.7h-14.4c12,0,21.7,9.7,21.7,21.7c0,12-9.7,21.7-21.7,21.7H267.1"
            />
        </svg>
    );
};
