import { createReducer, createSelector } from '@reduxjs/toolkit';
import {
    createUser,
    deleteUser,
    fetchUsers,
    fetchUsersByLocal,
    updateUser,
} from '../actions/adminActions';
import { ApplicationUser } from '../types';
import { RootState } from '../app/store';
import _ from 'lodash';

export interface UsersState {
    users: ApplicationUser[];
}

// TODO: key by user ID
const defaultState: UsersState = {
    users: [],
};

export const usersReducer = createReducer(defaultState, (builder) =>
    builder
        .addCase(fetchUsers.fulfilled, (state, action) => {
            state.users = [...action.payload];
        })
        .addCase(fetchUsersByLocal.fulfilled, (state, action) => {
            state.users = [...action.payload];
        })
        .addCase(createUser.fulfilled, (state, action) => {
            state.users = [...state.users, action.payload];
        })
        .addCase(updateUser.fulfilled, (state, action) => {
            state.users = [
                ...state.users.filter((u) => u.id !== action.payload.id),
                action.payload,
            ];
        })
        .addCase(deleteUser.fulfilled, (state, action) => {
            state.users = state.users.filter((u) => u.id !== action.payload);
        })
);

const getAgents = createSelector(
    (state: RootState) => state.usersReducer.users,
    (users): ApplicationUser[] => {
        return users.filter((u) => u.role === 'Agent');
    }
);

const getUsers = createSelector(
    (state: RootState) => state.usersReducer.users,
    (users): ApplicationUser[] => {
        return _([...users])
            .orderBy((a) => `${a.firstName} ${a.lastName}`)
            .value();
    }
);

export const UsersSelectors = {
    getAgents,
    getUsers,
};
