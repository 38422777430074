import { createReducer } from '@reduxjs/toolkit';
import {
    updateJoinForm,
    updateSignature,
    markJoinFormCompleted,
    submitApplication,
    fetchLocals,
    selectEmployer,
    JoinFormActions,
} from '../actions/joinFormActions';
import {
    JoinForm,
    SignatureType,
    Employer,
    PaymentSessionInfo,
} from '../types';
import { selectLocal } from '../actions/appActions';
import _ from 'lodash';

const defaultJoinForm: JoinForm = {
    formLocal: '',
    formEmployer: {
        name: '',
    },

    ssn: '',
    firstName: '',
    initial: '',
    lastName: '',
    sex: '',
    birthDate: '',

    email: '',
    phoneNum: '',
    cellPhone: '',
    textMessage: 'Y',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',

    origHireDate: '',
    jbno: '',
    fp: '',
    department: '',

    abcAmount: 1,
    voter: '',
    language: 'E',
    race: '',
    industry: {
        options: [],
        other: '',
    },
};

export interface JoinState {
    joinForm: JoinForm;
    signatures: Partial<Record<SignatureType, string>>;
    localOptions: string[];
    isFormComplete: boolean;
    isSubmitted: boolean;
    employerOptions?: Employer[];
    paymentSession?: PaymentSessionInfo;
    joinGuid?: string;
}

const defaultState: JoinState = {
    joinForm: defaultJoinForm,
    signatures: {},
    localOptions: [],
    isFormComplete: false,
    isSubmitted: false,
    employerOptions: undefined,
    paymentSession: undefined,
    joinGuid: undefined,
};

export const joinFormReducer = createReducer(defaultState, (builder) =>
    builder
        .addCase(updateJoinForm, (state, action) => {
            state.joinForm = {
                ...state.joinForm,
                ...action.payload,
            };
        })
        .addCase(markJoinFormCompleted, (state, action) => {
            state.isFormComplete = true;
        })
        .addCase(updateSignature, (state, action) => {
            state.signatures[action.payload.type] = action.payload.value;
        })
        .addCase(submitApplication.fulfilled, (state, action) => {
            state.isSubmitted = true;
            state.joinGuid = action.payload.joinGuid;
        })
        .addCase(fetchLocals.fulfilled, (state, action) => {
            state.localOptions = action.payload;
        })
        .addCase(selectLocal, (state, action) => {
            state.joinForm.formLocal = action.payload.localNumber;
        })
        .addCase(selectEmployer, (state, action) => {
            state.joinForm.formEmployer = action.payload;
            if (state.joinForm.formEmployer) {
                state.joinForm.formEmployer.name =
                    state.joinForm.formEmployer.checkOffName;
                state.joinForm.formEmployer.employerLoc = '';
            }
        })
        .addCase(JoinFormActions.fetchEmployers.fulfilled, (state, action) => {
            state.employerOptions = action.payload.employers.sort((a, b) =>
                a.employerName?.localeCompare(b.employerName)
            );
        })
        .addCase(
            JoinFormActions.fetchPaymentSession.fulfilled,
            (state, action) => {
                state.paymentSession = action.payload;
            }
        )
);
