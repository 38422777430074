import {
    configureStore,
    combineReducers,
    Action,
    ThunkAction,
} from '@reduxjs/toolkit';
import {
    appReducer,
    joinFormReducer,
    importReducer,
    usersReducer,
    adminReducer,
    callReducer,
    surveyReducer,
} from '../reducers';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { enableES5 } from 'immer'; // Fixes Typescript bug compilation on production https://github.com/reduxjs/redux-toolkit/issues/476
enableES5();
const rootReducer = combineReducers({
    appReducer,
    joinFormReducer,
    importReducer,
    usersReducer,
    adminReducer,
    callReducer,
    surveyReducer,
});

const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

(window as any)['ufcwStore'] = store;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const dispatch = store.dispatch;
export default store;
