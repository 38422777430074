import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Welcome } from './Welcome';
import { FormStack } from './FormStack';
import {
    fetchFormById,
    fetchLocalEmployerLink,
    selectLocal,
    AppActions,
} from '../actions/appActions';
import { useAppDispatch, useTypedSelector } from '../app/store';
import { NavBar } from './Navbar';
import { Completed } from './Completed';
import {
    fetchLocals,
    selectEmployer,
    JoinFormActions,
} from '../actions/joinFormActions';
import { unwrapResult } from '@reduxjs/toolkit';
import { LocalNotFound } from './LocalNotFound';
import { FullPageLoader } from '../components/FullPageLoader';
import { ScrollToTop } from '../components/ScrollToTop';

const JoinStack: React.FC = () => {
    const dispatch = useAppDispatch();
    const linkMatch = useRouteMatch<{ formId: string }>('/j/:formId?');
    const welcomeMatch = useRouteMatch<{ formId: string }>('/welcome');
    const successMatch = useRouteMatch<{ formId: string }>('/success');
    const formShortId = useTypedSelector((state) => state.appReducer.formId);
    const [loading, setLoading] = useState<boolean>(true);
    const localMatch = useRouteMatch<{
        localNumber: string;
        employerNumber?: string;
    }>('/join/:localNumber/:employerNumber?');
    const selectedLocal = useTypedSelector((state) => state.appReducer.local);

    const hasInvalidLocal = useTypedSelector(
        (state) => state.appReducer.hasInvalidLocal
    );

    const localNumber = localMatch?.params.localNumber;
    const employerNumber = localMatch?.params.employerNumber;

    useEffect(() => {
        if (loading && linkMatch && !formShortId) {
            const { formId } = linkMatch.params;

            dispatch(fetchFormById(formId)).then(() => {
                setLoading(false);
            });
        } else if (loading && !linkMatch) {
            dispatch(
                fetchLocals({
                    includeAssociate: !!localNumber,
                })
            );

            if (localNumber && !employerNumber) {
                dispatch(JoinFormActions.fetchEmployers(localNumber));
            }
            if (localNumber) {
                dispatch(
                    fetchLocalEmployerLink({
                        local: localNumber,
                        employer: employerNumber,
                    })
                )
                    .then(unwrapResult)
                    .then((res) => {
                        dispatch(selectLocal(res.local));
                        if (res.employer) {
                            dispatch(selectEmployer(res.employer));
                        }
                    })
                    .finally(() => setLoading(false));
            } else {
                setLoading(false);
            }
        }
    }, [
        dispatch,
        linkMatch,
        formShortId,
        loading,
        localNumber,
        employerNumber,
    ]);

    useEffect(() => {
        if (selectedLocal?.localNumber) {
            dispatch(
                AppActions.fetchSignatures({
                    localId: selectedLocal?.localNumber,
                })
            );
        }
    }, [selectedLocal, dispatch]);

    if (loading) {
        return <FullPageLoader />;
    }

    if (hasInvalidLocal) {
        return <LocalNotFound />;
    }

    return (
        <>
            <ScrollToTop />
            <NavBar
                transparent={
                    !!linkMatch ||
                    !!welcomeMatch ||
                    !!successMatch ||
                    !!localMatch
                }
            />
            <Switch>
                <Route path="/welcome" exact component={Welcome} />
                <Route path="/join/:localNumber" component={Welcome} />
                <Route
                    path="/nolink"
                    component={() => <div>Link not valid</div>}
                />
                <Route path="/success" component={Completed} />
                <Route path="/join" component={FormStack} />
                <Route path={['/sign', '/:local/sign']} component={FormStack} />
                <Route path="/dues" component={FormStack} />
                <Redirect to="/welcome" />
            </Switch>
        </>
    );
};

export default JoinStack;
