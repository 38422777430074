import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { css, cx } from 'emotion';
import React, { useEffect, useRef, useState } from 'react';
import { mq } from '../app/theme';
import { ConditionalWrapper } from '../components/ConditionalWrapper';
import { PhotoFrame } from '../components/PhotoFrame';
import rays from '../assets/rays.svg';
import { useAppDispatch, useTypedSelector } from '../app/store';
import { formatCurrency } from '../app/utils';
import { Redirect, useHistory } from 'react-router';
import {
    fetchPaymentSession,
    updatePaymentInfo,
} from '../actions/joinFormActions';
import { unwrapResult } from '@reduxjs/toolkit';
import { GradientButton } from '../components/GradientButton';
import { LoaderButton } from '../components/LoaderButton';
import { useSnackbar } from 'notistack';
import { PaymentSubmissionResponse } from '../types';

export const Dues = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const isMd = useMediaQuery(mq('md', true));

    const paymentFrame = useRef<HTMLIFrameElement>(null);

    const local = useTypedSelector((state) => state.appReducer.local);
    const joinGuid = useTypedSelector(
        (state) => state.joinFormReducer.joinGuid
    );
    const abcAmount = useTypedSelector(
        (state) => state.joinFormReducer.joinForm.abcAmount ?? 0
    );

    const paymentSession = useTypedSelector(
        (state) => state.joinFormReducer.paymentSession
    );

    const [frameDimensions, setFrameDimensions] = useState<
        { width: number; height: number } | undefined
    >(undefined);

    const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

    const [paymentResult, setPaymentResult] = useState<
        PaymentSubmissionResponse | undefined
    >(undefined);

    const [hasPaymentErrored, setHasPaymentErrored] = useState(false);

    useEffect(() => {
        if (local) {
            if (!joinGuid) {
                enqueueSnackbar(
                    'Join record not found. Results may be unexpected',
                    {
                        variant: 'warning',
                    }
                );
            }

            dispatch(
                fetchPaymentSession({
                    localNumber: local.localNumber,
                    // hardcoded guid is a fallback for using with testing records
                    joinGuid:
                        joinGuid ?? '1406ae6a-3d9c-44f6-bd9e-aaaaaaaaaaaa',
                })
            )
                .then(unwrapResult)
                .catch((e) => {
                    setHasPaymentErrored(true);
                });
        }
    }, [local, joinGuid, dispatch]);

    useEffect(() => {
        if (!paymentSession?.url) {
            return;
        }

        const paymentsOrigin = new URL(paymentSession.url).origin;

        const fn = (e: MessageEvent) => {
            if (e.origin !== paymentsOrigin) {
                return;
            }

            if (e.data?.type === 'WindowDimensions') {
                setFrameDimensions({
                    width: e.data.value.width,
                    height: e.data.value.height,
                });
            }

            if (e.data?.type === 'Init') {
                setIsSubmittingPayment(false);
            }

            if (e.data?.type === 'PaymentSuccess') {
                setPaymentResult(e.data.value);
            }
        };

        window.addEventListener('message', fn);

        return () => {
            window.removeEventListener('message', fn);
        };
    }, [paymentSession]);

    useEffect(() => {
        if (paymentResult && joinGuid) {
            dispatch(
                updatePaymentInfo({
                    amount: paymentResult.amount,
                    confirmationCode: paymentResult.confirmationCode,
                    joinGuid: joinGuid,
                    type: paymentResult.type,
                })
            );
        }
    }, [paymentResult]);

    const submitPayment = () => {
        if (!paymentFrame.current) {
            return;
        }

        setIsSubmittingPayment(true);

        paymentFrame.current.contentWindow?.postMessage(
            {
                type: 'SubmitPayment',
            },
            '*'
        );
    };

    if (!local) {
        return <Redirect to="/join" />;
    }

    return (
        <div>
            <div
                className={cx(
                    'bg-gray-100 p-4 md:h-40',
                    css({
                        backgroundImage: `url(${rays})`,
                        backgroundPosition: 'top right',
                        backgroundRepeat: 'no-repeat',
                    })
                )}
            >
                <div className="bg-gray-200 text-sm p-3 md:rounded-md md:max-w-screen-lg md:text-center md:m-auto md:p-3">
                    <h5 className="font-semibold mr-2 inline text-gray-900">
                        Amount Due:
                    </h5>
                    <span className="text-gray-800">
                        {formatCurrency(local?.duesRate + abcAmount)}
                    </span>
                </div>
            </div>

            <div className="md:-mt-20 md:mx-8 relative">
                <ConditionalWrapper
                    wrap={isMd}
                    wrapper={(children) => (
                        <PhotoFrame
                            header="Union Dues Payment"
                            className="md:max-w-screen-lg md:m-auto pb-8 md:mb-16 md:px-4"
                            classes={{
                                header: 'text-ufcw-blue',
                                body: 'w-full',
                            }}
                        >
                            {children}
                        </PhotoFrame>
                    )}
                >
                    {paymentSession ? (
                        <iframe
                            ref={paymentFrame}
                            src={paymentSession.url}
                            className={cx(
                                'w-full',
                                css({
                                    height: frameDimensions?.height,
                                })
                            )}
                        />
                    ) : hasPaymentErrored ? (
                        <div className="p-4 text-center">
                            <h3 className="text-blue-800 text-xl">
                                Something went wrong!
                            </h3>
                            <p>
                                No worries, though. A union representative will
                                reach out to help you with dues.
                            </p>
                        </div>
                    ) : (
                        <div className="p-4 flex justify-center items-center">
                            <CircularProgress />
                        </div>
                    )}

                    <div
                        className={cx(
                            'absolute flex flex-col-reverse sm:flex-row bottom-0 bg-white px-8 transform -translate-x-1/2 translate-y-16 sm:translate-y-1/2 sm:space-x-2 max-w-full',
                            css({
                                left: '50%',
                                width: 512,
                            })
                        )}
                    >
                        {paymentResult || hasPaymentErrored ? (
                            <>
                                <GradientButton
                                    fullWidth
                                    ContainerProps={{
                                        className: 'flex flex-1',
                                    }}
                                    onClick={() => {
                                        history.push('/success');
                                    }}
                                >
                                    Continue
                                </GradientButton>
                            </>
                        ) : (
                            <>
                                {!local.requirePayment ? (
                                    <LoaderButton
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        ContainerProps={{
                                            className:
                                                'flex flex-1 mt-2 sm:mt-0',
                                        }}
                                        onClick={() => history.push('/success')}
                                    >
                                        Skip for Now
                                    </LoaderButton>
                                ) : null}
                                <GradientButton
                                    fullWidth
                                    loading={isSubmittingPayment}
                                    disabled={
                                        !paymentSession || hasPaymentErrored
                                    }
                                    ContainerProps={{
                                        className: 'flex flex-1',
                                    }}
                                    onClick={() => {
                                        submitPayment();
                                    }}
                                >
                                    Continue
                                </GradientButton>
                            </>
                        )}
                    </div>
                </ConditionalWrapper>
            </div>
        </div>
    );
};
