import { createReducer } from '@reduxjs/toolkit';
import {
    Member,
    Employer,
    Token,
    JWT,
    RoleKey,
    Local,
    SignatureConfig,
} from '../types';
import {
    fetchFormById,
    login,
    logout as logoutAction,
    selectLocal,
    fetchLocalEmployerLink,
    AppActions,
} from '../actions/appActions';
import { LocalStorageKey } from '../app/constants';
import JwtDecode from 'jwt-decode';
import { AppDispatch } from '../app/store';
import { AdminActions } from '../actions/adminActions';

export interface AppState {
    localId?: number;
    formId?: string;
    employee?: Member;
    employer?: Employer;
    local?: Local;
    token?: Token;
    isSuperAdmin: boolean;
    hasInvalidLocal: boolean;
    signatureConfigs?: SignatureConfig[];
}

const jwt = localStorage.getItem(LocalStorageKey.JWT);
let token: JWT | undefined = undefined;
let isSuperAdmin: boolean = false;

if (jwt) {
    token = JwtDecode<JWT>(jwt);
    isSuperAdmin = token[RoleKey] === 'SuperAdmin';
}

export const appTokenFromJWT = (token: JWT): Token | undefined => {
    return {
        role: token[RoleKey],
        userId: token?.sub,
        username: token?.username,
        canImport: token[RoleKey] !== 'Agent' || token?.canImport === 'true',
        localId: token?.localId,
        localNumber: token?.localNumber,
    };
};

const initialState: AppState = {
    localId: undefined,
    local: undefined,
    token: token ? appTokenFromJWT(token) : undefined,
    isSuperAdmin,
    hasInvalidLocal: false,
};
export const appReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(fetchFormById.fulfilled, (state, action) => {
            const member = action.payload?.member;
            state.localId = action.payload?.localId;

            state.employer = member?.employer;

            delete member?.employer;
            state.employee = member;
            state.local = action.payload?.local;

            state.formId = action.payload?.shortId;
        })
        .addCase(selectLocal, (state, action) => {
            state.local = action.payload;
        })
        .addCase(fetchLocalEmployerLink.rejected, (state, action) => {
            if (action.error.message === 'Not Found') {
                // User has entered an invalid local
                state.hasInvalidLocal = true;
            }
        })
        .addCase(login.fulfilled, (state, action) => {
            state.token = action.payload;
            state.isSuperAdmin =
                (action.payload as any)[RoleKey] === 'SuperAdmin';
        })
        .addCase(logoutAction, (state, action) => {
            delete state.token;
        })
        .addCase(AppActions.fetchSignatures.fulfilled, (state, action) => {
            state.signatureConfigs = action.payload.signatures;
        })
        .addCase(AdminActions.updateSignature.fulfilled, (state, action) => {
            if (state.signatureConfigs) {
                state.signatureConfigs = [
                    ...state.signatureConfigs?.filter((cfg) => {
                        return (
                            (action.payload.displayID
                                ? cfg.displayID !== action.payload.displayID
                                : true) && cfg.type !== action.payload.type
                        );
                    }),
                    action.payload,
                ];
            } else {
                state.signatureConfigs = [action.payload];
            }
        })
        .addCase(AdminActions.resetSignature.fulfilled, (state, action) => {
            if (state.signatureConfigs) {
                state.signatureConfigs = [
                    ...state.signatureConfigs?.filter((cfg) => {
                        return (
                            (action.payload.displayID
                                ? cfg.displayID !== action.payload.displayID
                                : true) && cfg.type !== action.payload.type
                        );
                    }),
                    action.payload,
                ];
            } else {
                state.signatureConfigs = [action.payload];
            }
        })
);

export function logout(dispatch: AppDispatch) {
    localStorage.removeItem(LocalStorageKey.JWT);
    window.location.replace('/login');
}
