import {
    SignatureType,
    Signature,
    LocalTemplateType,
    Local,
    TemplateToken,
} from '../types';
import { css } from 'emotion';

export const GenderSelectOptions = [
    { text: 'Male', value: 'M' },
    { text: 'Female', value: 'F' },
    { text: 'Gender Non-Conforming', value: 'NC' },
    { text: 'Transgender', value: 'T' },
    { text: 'Other', value: 'O' },
];

export enum GenderLookup {
    'M' = 'Male',
    'F' = 'Female',
    'NC' = 'Gender Non-Conforming',
    'T' = 'Transgender',
    'O' = 'Other',
}

export const PreferredLanguage = [
    { text: 'English', value: 'E' },
    { text: 'Spanish', value: 'S' },
    { text: 'Chinese Mandarin', value: 'M' },
    { text: 'Chinese Cantonese', value: 'C' },
    { text: 'Vietnamese', value: 'V' },
    { text: 'Italian', value: 'I' },
    { text: 'French', value: 'F' },
    { text: 'German', value: 'G' },
    { text: 'Persian', value: 'P' },
    { text: 'Armenian', value: 'A' },
    { text: 'Tagalog', value: 'T' },
    { text: 'Korean', value: 'K' },
    { text: 'Russian', value: 'R' },
    { text: 'Other', value: 'O' },
];

export const YesNoOptions = [
    { text: '', value: '' },
    { text: 'Yes', value: 'Y' },
    { text: 'No', value: 'N' },
];

export const VoterOptions = [
    { text: '', value: '' },
    { text: 'Yes', value: 'Y' },
    { text: 'No', value: 'N' },
    { text: 'Democrat', value: 'D' },
    { text: 'Republican', value: 'R' },
    { text: 'Independent', value: 'I' },
    { text: 'Unknown', value: ' ' },
];
export const RaceOptions = [
    {
        text: 'Asian',
        value: 'A',
    },
    {
        text: 'Black or African American',
        value: 'B',
    },
    {
        text: 'Hispanic or Latino/a',
        value: 'L',
    },
    {
        text: 'Native American or Alaskan Native',
        value: 'I',
    },
    {
        text: 'Native Hawaiian or Pacific Islander',
        value: 'H',
    },
    {
        text: 'White or Caucasian',
        value: 'W',
    },
    {
        text: 'Other',
        value: 'O',
    },
];

export enum RaceLookup {
    'A' = 'Asian',
    'B' = 'Black or African American',
    'L' = 'Hispanic or Latino/a',
    'I' = 'Native American or Alaskan Native',
    'H' = 'Native Hawaiian or Pacific Islander',
    'W' = 'White or Caucasian',
    'O' = 'Other',
}

export const FPOptions = [
    { text: 'Full-Time', value: 'F' },
    { text: 'Part-Time', value: 'P' },
];

export const StateSelectOptions = [
    { text: '', value: '' },
    { text: 'Alabama', value: 'AL' },
    { text: 'Alaska', value: 'AK' },
    { text: 'Arizona', value: 'AZ' },
    { text: 'Arkansas', value: 'AR' },
    { text: 'California', value: 'CA' },
    { text: 'Colorado', value: 'CO' },
    { text: 'Connecticut', value: 'CT' },
    { text: 'Delaware', value: 'DE' },
    { text: 'District of Columbia', value: 'DC' },
    { text: 'Florida', value: 'FL' },
    { text: 'Georgia', value: 'GA' },
    { text: 'Hawaii', value: 'HI' },
    { text: 'Idaho', value: 'ID' },
    { text: 'Illinois', value: 'IL' },
    { text: 'Indiana', value: 'IN' },
    { text: 'Iowa', value: 'IA' },
    { text: 'Kansas', value: 'KS' },
    { text: 'Kentucky', value: 'KY' },
    { text: 'Louisiana', value: 'LA' },
    { text: 'Maine', value: 'ME' },
    { text: 'Maryland', value: 'MD' },
    { text: 'Massachusetts', value: 'MA' },
    { text: 'Michigan', value: 'MI' },
    { text: 'Minnesota', value: 'MN' },
    { text: 'Mississippi', value: 'MS' },
    { text: 'Missouri', value: 'MO' },
    { text: 'Montana', value: 'MT' },
    { text: 'Nebraska', value: 'NE' },
    { text: 'Nevada', value: 'NV' },
    { text: 'New Hampshire', value: 'NH' },
    { text: 'New Jersey', value: 'NJ' },
    { text: 'New Mexico', value: 'NM' },
    { text: 'New York', value: 'NY' },
    { text: 'North Carolina', value: 'NC' },
    { text: 'North Dakota', value: 'ND' },
    { text: 'Ohio', value: 'OH' },
    { text: 'Oklahoma', value: 'OK' },
    { text: 'Oregon', value: 'OR' },
    { text: 'Pennsylvania', value: 'PA' },
    { text: 'Rhode Island', value: 'RI' },
    { text: 'South Carolina', value: 'SC' },
    { text: 'South Dakota', value: 'SD' },
    { text: 'Tennessee', value: 'TN' },
    { text: 'Texas', value: 'TX' },
    { text: 'Utah', value: 'UT' },
    { text: 'Vermont', value: 'VT' },
    { text: 'Virginia', value: 'VA' },
    { text: 'Washington', value: 'WA' },
    { text: 'West Virginia', value: 'WV' },
    { text: 'Wisconsin', value: 'WI' },
    { text: 'Wyoming', value: 'WY' },
];

export const phoneMask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export const SignatureOrder = [
    SignatureType.Membership,
    SignatureType.DuesCheckoff,
    SignatureType.ActiveBallot,
];

export const DefaultABCAmount = 1;

export enum LocalStorageKey {
    JWT = 'jwt',
    HeaderMapping = 'header-mapping',
    SignupsGridState = 'signups-grid-state',
}

export const ImportColumnMap: { name: string; field: string }[] = [
    { name: 'ssn', field: 'employee.ssn' },
    { name: 'firstName', field: 'employee.firstName' },
    { name: 'initial', field: 'employee.initial' },
    { name: 'lastName', field: 'employee.lastName' },
    { name: 'suffix', field: 'employee.suffix' },
    { name: 'birthDate', field: 'employee.birthDate' },
    { name: 'email', field: 'employee.email' },
    { name: 'phoneNum', field: 'employee.phoneNum' },
    { name: 'cellPhone', field: 'employee.cellPhone' },
    { name: 'addressLine1', field: 'employee.addressLine1' },
    { name: 'addressLine2', field: 'employee.addressLine2' },
    { name: 'city', field: 'employee.city' },
    { name: 'state', field: 'employee.state' },
    { name: 'zipCode', field: 'employee.zipCode' },
    { name: 'employerName', field: 'employer.name' },
    { name: 'employerNumber', field: 'employee.employerNumber' },
    { name: 'companyId', field: 'employee.companyId' },
    { name: 'storeId', field: 'employee.storeId' },
    { name: 'department', field: 'employee.department' },
    { name: 'hireDate', field: 'employee.origHireDate' },
    { name: 'fullOrPartTime', field: 'employee.fp' },
    { name: 'jobNumber', field: 'employee.jbno' },
];

export const TemplateNameLookup: Record<LocalTemplateType, string> = {
    [LocalTemplateType.JoinFormEmail]: 'Join Form Email',
    [LocalTemplateType.JoinFormSms]: 'Join Form SMS',
    [LocalTemplateType.ClientJoinSuccess]: 'Success Page',
    [LocalTemplateType.JoinSuccessEmail]: 'Join Success Email',
    [LocalTemplateType.WelcomePage]: 'Welcome Page',
    [LocalTemplateType.JoinNotificationEmailSubject]:
        'Join Notification Email Subject',
};

export const TemplateFieldLookup: Record<
    LocalTemplateType,
    keyof Pick<
        Local,
        | 'joinFormEmailTemplate'
        | 'joinFormSmsTemplate'
        | 'clientJoinSuccessTemplate'
        | 'successEmailTemplate'
        | 'welcomePageTemplate'
        | 'joinNotificationEmailTemplate'
    >
> = {
    [LocalTemplateType.JoinFormEmail]: 'joinFormEmailTemplate',
    [LocalTemplateType.JoinFormSms]: 'joinFormSmsTemplate',
    [LocalTemplateType.ClientJoinSuccess]: 'clientJoinSuccessTemplate',
    [LocalTemplateType.JoinSuccessEmail]: 'successEmailTemplate',
    [LocalTemplateType.WelcomePage]: 'welcomePageTemplate',
    [LocalTemplateType.JoinNotificationEmailSubject]:
        'joinNotificationEmailTemplate',
};

export const TemplateTokens: Partial<Record<
    LocalTemplateType,
    TemplateToken[]
>> = {
    [LocalTemplateType.JoinFormEmail]: [
        {
            label: "Employee's first name",
            value: 'MEMBER_FIRSTNAME',
        },
        {
            label: "Employee's last name",
            value: 'MEMBER_LASTNAME',
        },
        {
            label: 'Join URL',
            value: 'LINK',
        },
        {
            label: 'Join button',
            value: 'JOIN_BUTTON',
        },
        {
            label: 'Employer name',
            value: 'EMPLOYER_NAME',
        },
        {
            label: 'Home Page URL',
            value: 'HOMEPAGE_URL',
        },
        {
            label: 'Contact Page URL',
            value: 'CONTACT_URL',
        },
        {
            label: 'Contact Phone',
            value: 'CONTACT_PHONE',
        },
    ],
    [LocalTemplateType.JoinFormSms]: [
        {
            label: "Employee's first name",
            value: 'MEMBER_FIRSTNAME',
        },
        {
            label: "Employee's last name",
            value: 'MEMBER_LASTNAME',
        },
        {
            label: 'Join URL',
            value: 'LINK',
        },
        {
            label: 'Employer name',
            value: 'EMPLOYER_NAME',
        },
        {
            label: 'Home Page URL',
            value: 'HOMEPAGE_URL',
        },
        {
            label: 'Contact Page URL',
            value: 'CONTACT_URL',
        },
        {
            label: 'Contact Phone',
            value: 'CONTACT_PHONE',
        },
    ],
    [LocalTemplateType.JoinSuccessEmail]: [
        {
            label: "Employee's first name",
            value: 'MEMBER_FIRSTNAME',
        },
        {
            label: "Employee's last name",
            value: 'MEMBER_LASTNAME',
        },
        {
            label: 'Employer name',
            value: 'EMPLOYER_NAME',
        },
        {
            label: 'Home Page URL',
            value: 'HOMEPAGE_URL',
        },
        {
            label: 'Contact Page URL',
            value: 'CONTACT_PAGE',
        },
        {
            label: 'Contact Phone',
            value: 'CONTACT_PHONE',
        },
    ],
    [LocalTemplateType.JoinNotificationEmailSubject]: [
        {
            label: "Employee's first name",
            value: 'MEMBER_FIRSTNAME',
        },
        {
            label: "Employee's last name",
            value: 'MEMBER_LASTNAME',
        },
        {
            label: "Employee's SSN 4",
            value: 'MEMBER_SSN_4',
        },
        {
            label: 'Employer name',
            value: 'EMPLOYER_NAME',
        },
        {
            label: 'Employer number',
            value: 'EMPLOYER_NUMBER',
        },
    ],
};

export const DefaultTemplateValues: Record<LocalTemplateType, string> = {
    [LocalTemplateType.JoinFormEmail]: `
        <p>Hi {MEMBER_FIRSTNAME},</p>
        <p>Thank you for your interest in joining the UFCW!</p>
        <p>
            Being a UFCW union member is about having a strong contract, and knowing that
            we’ll always stand together.
            <a href="{LINK}">Complete Your Registration</a> to join UFCW Local
            T0001's proud union family.
        </p>
        <br />
        {JOIN_BUTTON}
    `,
    [LocalTemplateType.JoinFormSms]: `Hi, {RECIPIENT_FIRSTNAME}! You can complete your registration and learn more about UFCW at {LINK}. Thank you and have a great rest of your day!`,
    [LocalTemplateType.ClientJoinSuccess]: `
        <h3>
            <strong style="color: rgb(0, 84, 166); font-size: 1.5rem;"
                >Be on the lookout for a Welcome Letter from your Local Union:</strong
            >
        </h3>
        <p><br /></p>
        <p>
            <strong style="color: rgb(68, 68, 68); font-size: 1.25rem;"
                >UFCW Local 123</strong
            >
        </p>
        <p><span style="color: rgb(68, 68, 68);">555-555-5555 </span></p>
        <p><u style="color: rgb(0, 102, 204);">ufcwlocal123@gmail.com</u></p>
        <p><span style="color: rgb(68, 68, 68);">President Charles Charleston</span></p
    `,
    [LocalTemplateType.JoinSuccessEmail]: `
        <p><span style="font-size: 1rem;">Hi {MEMBER_FIRSTNAME},</span></p>
        <p>
        <span style="font-size: 1rem;"
            >Thank you for completing an application to join UFCW Local 123!</span
        >
        </p>
        <p>
        <span style="font-size: 1rem;"
            >A representative will be in contact with you soon. If you have any
            questions, please contact us.</span
        >
        </p>
    `,
    [LocalTemplateType.WelcomePage]: `
        <h3>
        <strong style="color: rgb(0, 102, 204); font-size: 1.25rem;"
            >Being a UFCW union member is about having a strong contract, and knowing
            that we’ll always stand together.</strong
        >
        </h3>
        <p><br /></p>
        <p>
        <span style="font-size: 1.125rem;"
            >We work to build a better life for hard-working men and women
            through:</span
        >
        </p>
        <ul>
            <li>
                <span style="font-size: 1.125rem;">Free College Degree Programs </span>
            </li>
            <li><span style="font-size: 1.125rem;">Language Classes</span></li>
            <li>
                <span style="font-size: 1.125rem;"
                >Discounts on your wireless bill, movie tickets, legal programs and
                more...</span
                >
            </li>
        </ul>
    `,
    [LocalTemplateType.JoinNotificationEmailSubject]: `UFCW New Join - {MEMBER_SSN_4} {MEMBER_FIRSTNAME} {MEMBER_LASTNAME}`,
};

export const QuillToolbar = [
    [
        {
            size: ['1rem', '1.125rem', '1.25rem', '1.5rem'],
        },
    ],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
    ],
    [{ color: [] }],
    ['link', 'image'],
    ['clean'],
];

export const quillStyleReset = css({
    ul: {
        listStyleType: 'disc',
        paddingInlineStart: '1.5rem',
    },
});

export const SurveyTokenOptions = [
    {
        label: "Employee's first name",
        value: 'MEMBER_FIRSTNAME',
    },
    {
        label: "Employee's last name",
        value: 'MEMBER_LASTNAME',
    },
    {
        label: 'Employee ID',
        value: 'EMPLOYEE_ID',
    },
    {
        label: 'Employer name',
        value: 'EMPLOYER_NAME',
    },
    {
        label: 'Employer Number',
        value: 'EMPLOYER_NUMBER',
    },
];
