import React from 'react';
import { NavBar } from './Navbar';
import { PhotoFrame } from '../components/PhotoFrame';

export const LocalNotFound: React.FC = () => {
    return (
        <div>
            <NavBar />
            <PhotoFrame
                header="Uh Oh!"
                className="max-w-screen-md m-auto mt-4"
                classes={{
                    header: 'text-ufcw-blue',
                }}
            >
                <h2 className="text-lg font-semibold text-gray-700">
                    The provided link is invalid.
                </h2>
                <p className="text-gray-700 mt-2">
                    <a href="/" className="underline text-ufcw-blue">
                        Go the the homepage,
                    </a>{' '}
                    or contact your local representative for assistance.
                </p>
            </PhotoFrame>
        </div>
    );
};
