import { FormControlLabel, Radio, RadioProps } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import React from 'react';

class FRadioField extends React.Component<
    RadioProps & { ignoreCase?: boolean; label?: string; name: string }
> {
    render() {
        const { label, ignoreCase, ...props } = this.props;
        return (
            <Field name={props.name}>
                {({ field, form }: FieldProps) => {
                    return (
                        <FormControlLabel
                            label={label}
                            control={
                                <Radio
                                    // TODO: error stuff
                                    // error={
                                    //     !!(
                                    //         (touched[props.name] ||
                                    //             form.submitCount > 0) &&
                                    //         errors[props.name]
                                    //     )
                                    // }
                                    // helperText={
                                    //     (touched[props.name] || form.submitCount > 0) &&
                                    //     errors[props.name]
                                    // }
                                    // errorTarget="under"
                                    // errorMessage={
                                    //     (touched[props.name] || form.submitCount > 0) &&
                                    //     errors[props.name]
                                    // }
                                    checked={
                                        ignoreCase
                                            ? field.value
                                                  ?.toString()
                                                  ?.toLowerCase() ===
                                              props.value
                                            : field.value === props.value
                                    }
                                    {...props}
                                    {...field}
                                    onChange={(e, checked) => {
                                        if (checked) {
                                            form.setFieldValue(
                                                props.name,
                                                props.value
                                            );
                                            props.onChange &&
                                                props.onChange(e, checked);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        form.setFieldTouched(props.name, true);
                                        props.onBlur && props.onBlur(e);
                                    }}
                                />
                            }
                        />
                    );
                }}
            </Field>
        );
    }
}

export default FRadioField;
