import { createMuiTheme } from '@material-ui/core';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';

// gotta kinda hack this since mui lab stuff isn't in the theme by default :/
interface Overrides extends CoreOverrides {
    MuiAutocomplete: {};
}

export const palette = {
    ufcwBlue: '#0054a6',
    ufcwYellow: '#ffc315',
    ufcwOrange: '#f90',
    ufcwIndigo: '#5472d2',
    ufcwBlueDark: '#004890',
    ufcwGrayDark: '#333',
    ufcwGrayLight: '#888',
    lighterBlue: '#f1f6f9',
};

export const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
};
export const muiTheme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: breakpoints.sm,
            md: breakpoints.md,
            lg: breakpoints.lg,
            xl: breakpoints.xl,
        },
    },
    palette: {
        primary: {
            main: '#5472d2',
        },
        secondary: {
            main: '#fec315',
        },
    },
    overrides: {
        MuiFormLabel: {
            colorSecondary: {
                '&$focused': {
                    color: '#212121',
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: '0.5rem',
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 0.8rem) scale(1)',
                height: '1.1rem',
                display: 'flex',
                alignItems: 'flex-end',
            },
        },
        MuiOutlinedInput: {
            inputMarginDense: {},
            root: {
                borderRadius: '0.5rem',
            },
            input: {
                paddingTop: '0.8rem',
                paddingBottom: '0.8rem',
            },
        },
        MuiAutocomplete: {
            inputRoot: {
                '&[class*="MuiOutlinedInput-root"]': {
                    padding: '0.2rem',
                },
            },
        },
    } as Overrides,
    props: {
        MuiButton: {
            disableElevation: true,
        },
        MuiCheckbox: {
            color: 'primary',
        },
        MuiTextField: {
            variant: 'outlined',
            color: 'secondary',
        },
        MuiRadio: {
            color: 'primary',
        },
        MuiSelect: {
            variant: 'outlined',
            color: 'secondary',
        },
        MuiFormControl: {
            variant: 'outlined',
            color: 'secondary',
        },
    },
});

export const mq = (
    bp: keyof typeof breakpoints,
    omitMedia: boolean = false
) => {
    return `${!omitMedia ? '@media' : ''} (min-width: ${breakpoints[bp]}px)`;
};
