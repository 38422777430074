import * as React from "react"

interface DynamicLogoProps extends React.SVGProps<SVGSVGElement> {
  local?: string;
}

function DynamicLogo(props: DynamicLogoProps) {
  const localValue = props.local?.length
      
return (
  
  <svg viewBox="0 0 196.41 67.75" className="h-full" {...props}>
  <defs>
    <style>
      {
        ".prefix__cls-2,.prefix__cls-3{isolation:isolate;font-size:13px;font-family:Lato-Bold,Lato;font-weight:700;fill:#fff}.prefix__cls-2{letter-spacing:.04em}.prefix__cls-3{letter-spacing:.1em}.prefix__cls-6{fill:#ffc315}"
      }
    </style>
  </defs>
  <g id="prefix__Layer_2" data-name="Layer 2">
    <g id="prefix__Layer_1-2" data-name="Layer 1">
      <g id="prefix__Group_248" data-name="Group 248">
        <g
          style={{
            isolation: "isolate",
          }}
          id="prefix__LOCAL_T0001"
          data-name="LOCAL T0001"
        >
          <text
              transform="translate(11 26)"
              fill="#fff"
              fontSize={13}
              fontFamily="Lato-Black,Lato-Bold,Lato,sans-serif"
              fontWeight={700}
              letterSpacing=".07em"
              id="prefix__LOCAL_T0001"
              data-name={props.local}
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
          >
            {props.local}
          </text>
        </g>
        <g id="prefix__Group_238" data-name="Group 238">
          <path
            id="prefix__Path_96-2"
            data-name="Path 96-2"
            className="prefix__cls-6"
            d="M41.38 29L49 27.69c.18 0 .31.13.44.36a2.5 2.5 0 01.27.92c0 .4-1.18.71-1.18.93-.88.22-8.2 1.8-9.17 2.11a72.06 72.06 0 01-4.07 9 69.26 69.26 0 01-8.23 12.11 41.4 41.4 0 01-9.74 8.5 18.72 18.72 0 01-10 3.19C2.48 64.81 0 62.55 0 58a12 12 0 013-7.44 38.32 38.32 0 017.75-7.13 81.51 81.51 0 0110.31-6.28c3.72-1.9 7.35-3.59 10.93-5 1.6-.62 3.15-1.19 4.56-1.68.49-1.29 1-2.61 1.38-3.94a48 48 0 002.16-14.17c0-1.29-.09-2.66-.22-4.12a8.91 8.91 0 00-1.06-3.63 2.89 2.89 0 00-2.72-1.5c-1.81 0-4.16.84-7 2.57a49.28 49.28 0 00-7.66 5.75c-2.3 2.13-3.67 3.85-4.11 5.22-2 0-3-.4-3-1.19 0-1 1-2.61 2.92-4.69a41.16 41.16 0 015.71-5C28.18 1.92 32.65 0 36.41 0a5.53 5.53 0 014.42 1.64 8.76 8.76 0 012 4.47 36.73 36.73 0 01.44 6.2 52 52 0 01-2.18 14.51c-.27.84-.49 1.64-.75 2.39zm-8 5.27c-3.31 1.37-6.72 3-10.22 4.82A94.14 94.14 0 0013.45 45a39.67 39.67 0 00-7.3 6.33c-1.9 2.12-2.83 4.16-2.83 6.11q0 4.31 4 4.38c2.43 0 5.13-1 8.1-2.88a43.13 43.13 0 008.72-7.74 67.59 67.59 0 008-11.27 54.64 54.64 0 003.19-6.41c-.58.26-1.25.53-1.87.75z"
          />
          <path
            id="prefix__Path_97-2"
            data-name="Path 97-2"
            className="prefix__cls-6"
            d="M41.34 44a6 6 0 01-1.68-4.47 10 10 0 012-5.09A27.46 27.46 0 0146 29.27Q48.5 27 49.89 27a1.36 1.36 0 011.2.71 2.66 2.66 0 01.49 1.33c-.58 0-1.6.66-3 1.95a28.12 28.12 0 00-3.94 4.56A8.33 8.33 0 0042.83 40a3.17 3.17 0 00.57 2 1.79 1.79 0 001.6.8 4.31 4.31 0 002.56-1.37 17.67 17.67 0 003-3.23 13.24 13.24 0 001.86-3.72c-1.55-.88-2.35-1.81-2.35-2.74a1.3 1.3 0 01.76-1.15l.8-.45.48-.66a1.63 1.63 0 011.15-.71A1.85 1.85 0 0154.85 30a4.77 4.77 0 01.58 2.22V33h.8A16.85 16.85 0 0060 32a23.7 23.7 0 003-1.26l1.1-.58a.76.76 0 01.76.78v.28s-.4.35-1.11.88a15.28 15.28 0 01-2.88 1.73 11.18 11.18 0 01-5.18 1.37H55a20.47 20.47 0 01-2.22 4.8 17.68 17.68 0 01-3.37 4 5.75 5.75 0 01-3.71 1.64A5.85 5.85 0 0141.34 44z"
          />
          <path
            id="prefix__Path_98-2"
            data-name="Path 98-2"
            className="prefix__cls-6"
            d="M61.22 44.55A4 4 0 0159.58 43a5.91 5.91 0 01-.66-3.06 11.71 11.71 0 01.84-4.33 23.81 23.81 0 011.94-4c.76-1.2 1.38-2.21 2-3l.84-1.11a3.69 3.69 0 012 .49 1.62 1.62 0 01.67 1.28l-.8 1.24c-3 4.74-4.47 8.23-4.47 10.45 0 1 .26 1.5.8 1.5.84 0 1.94-.62 3.32-1.81A51.09 51.09 0 0070.53 36c1.63-1.82 3.23-3.76 4.82-5.8h.18a1.09 1.09 0 01.71.26.8.8 0 01.31.62v.18q-2.72 3.53-5.24 6.74a51.37 51.37 0 01-4.65 5.13c-1.46 1.33-2.65 2-3.58 2a4.21 4.21 0 01-1.86-.58zM67.55 25c-.36-.09-.58-.22-.62-.4a1 1 0 01-.09-.53v-.31l2.7-5.27a6.89 6.89 0 012.34.35 1.14 1.14 0 01.67 1.16v.08l-3.23 5.12a11.66 11.66 0 01-1.77-.2z"
          />
          <path
            id="prefix__Path_99-2"
            data-name="Path 99-2"
            className="prefix__cls-6"
            d="M69.76 44.68c-.75-.31-1.1-.76-1.1-1.29q0-2.91 6.77-15.93a5.11 5.11 0 012 .44c.76.31 1.11.66 1.11 1.06a204.39 204.39 0 00-4.47 9.34l6.42-6c4.33-3.94 6.81-5.93 7.43-5.93a1.76 1.76 0 01.8.26 6.76 6.76 0 01.89.71 1.25 1.25 0 01.35.75 30.64 30.64 0 01-1.77 3.59c-.93 1.73-1.86 3.58-2.79 5.49a11.63 11.63 0 00-1.4 4.6c2 0 4.83-1.77 8.63-5.27 1.68-1.55 3.77-3.67 6.24-6.33a1.18 1.18 0 01.53.22 1 1 0 01.49.84.33.33 0 01-.09.27 77 77 0 01-5.26 6A52.89 52.89 0 0189 42.67c-1.82 1.46-3.19 2.17-4.17 2.17a4.32 4.32 0 01-3-1 2.63 2.63 0 01-1.2-2 9.47 9.47 0 01.66-3.19c.46-1.2 1-2.38 1.55-3.54a25.3 25.3 0 011.55-2.78l.61-1.11a31.85 31.85 0 00-4.07 3.72c-2 2-3.85 4-5.66 6.06a37.12 37.12 0 00-3.41 4 5.77 5.77 0 01-2.1-.32z"
          />
          <g id="prefix__Group_223" data-name="Group 223">
            <g id="prefix__Group_218" data-name="Group 218">
              <g id="prefix__Group_168" data-name="Group 168">
                <g id="prefix__Group_89" data-name="Group 89">
                  <g id="prefix__Group_88" data-name="Group 88">
                    <path
                      id="prefix__Path_53-2"
                      data-name="Path 53-2"
                      d="M118 33.07c0 3.15-.36 7.17-3.55 9.56-3.62 2.65-11.81 2.86-16.19.4-4.56-2.53-4.35-8.73-4.35-9.89v-11l-3-3.91h12.31l-3 3.91V33c0 .94-.18 4.42 2.29 6.41a5.51 5.51 0 007.24-.07c2-1.6 2-5.11 2-6.27V20.36a2.09 2.09 0 011.77-2 11.5 11.5 0 012.75 0 2.18 2.18 0 011.78 2.1v12.61zM158.9 41c.94 0 .66 2.2-.18 2.57-2.61 1.12-8.73 1.45-11.48.61-5.18-1.54-9.92-5-10.77-11.19a5.7 5.7 0 01-1.17.18c-.84 0-3.08.11-4.42.18-1.56.08-4.13.8-4.13 3.34v5.43a2.11 2.11 0 01-2 2.18h-7.66l3-3.8V21.12a2.68 2.68 0 012.47-2.86h12.93c1.16 0 2 .29 2.24.94a1.63 1.63 0 010 1.3c-.19.43-1.13.69-2 .76-1.09.07-2.39.18-3.91.22-2.47.07-5 .54-5 4 0 3.8 3.76 4.31 4.63 4.35s2.68.11 3.91.18a5.53 5.53 0 011.07.16c.29-6.31 4.21-9.8 8.56-11.61 2.65-1.08 10.36-1.37 13.22.52a2.63 2.63 0 01.49.39 1.37 1.37 0 011.39-1.28 11.2 11.2 0 013 .18c1 .22 1.78 1.84 1.92 3.22a103.26 103.26 0 002 10.61c.94 3.48 2.35 3 3.58-1.7.69-2.64 1.3-6.48 1.85-9.34.4-2.17 1.23-3.19 2.82-3.19h4.42c1.52 0 2.32.65 2.76 3.12s1.2 6.84 2 9.85c1.23 4.89 2.61 4.42 3.44 1.08.69-2.71 1.81-8.07 2.21-10.57.22-1.38.73-2.86 1.78-3.08a11.62 11.62 0 013-.18c2.21 0 1.56 2.72 1 5.14s-3.15 12.75-3.87 16.23c-.66 3-.94 4.6-3.77 4.67h-3.84c-2.5-.07-2.64-2.61-3.26-5.47-.4-1.88-1.2-6.16-1.74-8.36-.8-3.23-2.64-3.19-3.51.07-.55 2-1.56 6-1.92 8.3-.47 3-1 5.4-3.37 5.47h-3.7c-2.61-.08-3.15-2-3.94-5-.66-2.53-3-13.32-3.77-15.93-.17-.61-.31-1.22-.42-1.85a1 1 0 01-.34 0c-7.86-1.23-11 0-14 4.24-2.14 3-1.23 8.33-.25 10 3.58 6 9 5.51 14.85 5.29z"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
            {localValue && <path
          className="prefix__cls-6"
          d="M55.17 60H30.76a1 1 0 110-2h24.41a1 1 0 010 2zM186.17 60h-24.41a1 1 0 010-2h24.41a1 1 0 010 2z"
            /> }
      </g>
    </g>
  </g>
  </svg>
)
}

export default DynamicLogo