import React, { useState } from 'react';
// import ModalVideo from 'react-modal-video';
const getVideoId = require('get-video-id');
// const ModalVideo = require('react-modal-video').default;

interface types {
    video: string;
    classes?: string;
    mobile?: boolean;
}

const JoinVideo = ({ video, classes, mobile }: types) => {
    var videoId: string = '';
    function convertEmbedUrl(url: string): string | undefined {
        const videoInfo:
            | {
                  id?: string;
                  service: 'youtube' | 'vimeo' | 'vine' | 'videopress';
              }
            | {} = getVideoId(url);

        if (!('id' in videoInfo && videoInfo.id && 'service' in videoInfo)) {
            return;
        }

        videoId = videoInfo.id;

        switch (videoInfo.service) {
            case 'youtube': {
                return `https://www.youtube.com/embed/${videoInfo.id}`;
            }
            case 'vimeo': {
                return `https://player.vimeo.com/video/${videoInfo.id}`;
            }
        }

        return url;
    }

    if (mobile) {
        return (
            <div className={classes}>
                <iframe
                    src={convertEmbedUrl(video)}
                    frameBorder={0}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    width={window.innerWidth}
                    height={window.innerWidth}
                />
            </div>
        );
    } else {
        return (
            <div className={classes}>
                <iframe
                    src={convertEmbedUrl(video)}
                    frameBorder={0}
                    width="100%"
                    height="100%"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        );
    }
};

export default JoinVideo;
