import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Form } from './Form';
import { Sign } from './Sign';
import { css, cx } from 'emotion';
import { useTypedSelector } from '../app/store';
import { Dues } from './Dues';

export const FormStack: React.FC = () => {
    const local = useTypedSelector(state => state.appReducer.local);

    return (
        <>
            <StepIndicator />
            <Switch>
                <Route path="/join" component={Form} />
                <Route path={['/sign', '/:local/sign']} component={Sign} />
                {local?.isUsingPayments ? (
                    <Route path="/dues" component={Dues} />
                ) : null}
                <Redirect to="/join" />
            </Switch>
        </>
    );
};

const StepIndicator: React.FC = () => {
    const stepOne = useRouteMatch('/join');
    const stepTwo = useRouteMatch(['/sign', '/:local/sign']);
    const stepThree = useRouteMatch('/dues');

    const local = useTypedSelector(state => state.appReducer.local);

    return (
        <div className="bg-ufcw-blue-dark justify-evenly py-2 text-center">
            <div className="md:max-w-lg w-full flex m-auto justify-evenly">
                <StepLabel active={!!stepOne}>Step 1: Form</StepLabel>
                <StepLabel active={!!stepTwo}>Step 2: Sign</StepLabel>
                {local?.isUsingPayments ? (
                    <StepLabel active={!!stepThree}>Step 3: Dues</StepLabel>
                ) : null}
            </div>
        </div>
    );
};

const StepLabel: React.FC<{ active: boolean }> = ({ active, children }) => {
    return (
        <div
            className={cx(
                'text-xs uppercase relative h-full font-medium md:text-base md:py-1',
                active && 'text-white font-semibold',
                active &&
                    css({
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            width: '1rem',
                            height: '1rem',
                            background: '#f7f7f7',
                            bottom: '-0.5rem',
                            left: '50%',
                            transform: 'translate(-50%)',
                            clipPath: 'polygon(50% 50%, 100% 100%, 0 100%)',
                        },
                    }),
                !active && 'text-blue-200'
            )}
        >
            {children}
        </div>
    );
};
