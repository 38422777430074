import {
    parseISO,
    parse,
    subMinutes,
    toDate,
    isValid,
    isBefore,
    isAfter,
} from 'date-fns';
import { endsWith, entries } from 'lodash-es';
import ky from 'ky';
import { LocalStorageKey } from './constants';
import store from './store';
import { logout } from '../reducers/appReducer';
import { GridApi } from 'ag-grid-community';
import { ReactElement } from 'react';
import React from 'react';
import { muiTheme } from './theme';
import { ThemeProvider } from '@material-ui/core';
import ReactDOMServer from 'react-dom/server';
import { Employer } from '../types';

export function fromUTC(date: string | number | Date) {
    let dt: Date;
    // If a date is a string, we gotta do some gymnastics...
    if (typeof date === 'string') {
        const dateStr = date.toUpperCase();

        // If there is no time, assume it's a date
        if (/[0-9-]{10}T0{2}:0{2}:0{2}/i.test(dateStr)) {
            // If it's a UTC time, make it local
            if (endsWith(dateStr, 'Z')) {
                const localDate = dateStr.substring(0, dateStr.length - 1);
                dt = parseISO(localDate);
            } else {
                dt = parseISO(dateStr);
            }
            // otherwise, let it be parsed as local

            // If it's utc, we don't need to do anything, since it should parse fine
        } else if (endsWith(dateStr, 'Z')) {
            dt = parseISO(dateStr);
        } else if (/\d{2}\/\d{2}\/\d{4}/.test(dateStr)) {
            const newDate = parse(dateStr, 'MM/dd/yyyy', new Date());
            dt = newDate;
        }
        // otherwise, if we get a time that doesn't have a Z, assume it's local
        else {
            const newDate = parseISO(date);
            dt = subMinutes(newDate, new Date().getTimezoneOffset());
        }
    }
    // If it's a number or a Date, we'll assume that the client has already done the right thing
    else {
        dt = toDate(date);
    }

    return dt;
}

const jwt = localStorage.getItem(LocalStorageKey.JWT);

export let api = ky.extend({
    prefixUrl: '/api',
    timeout: 5 * 60 * 1000, // 5 minutes
    headers: {
        Authorization: jwt ? `Bearer ${jwt}` : '',
    },
    hooks: {
        afterResponse: [
            (_request, _options, response) => {
                if (response.status === 401) {
                    logout(store.dispatch);
                }
            },
        ],
    },
});

export function updateApiToken(token: string) {
    api = api.extend({
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

export function readFile(
    file: File,
    options: { readAs: 'arraybuffer' | 'text' | 'dataurl' }
): Promise<ProgressEvent<FileReader>> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = resolve;
        reader.onerror = (e) => {
            reject(e);
            reader.abort();
        };
        reader.onabort = reject;
        switch (options?.readAs) {
            case 'arraybuffer':
                reader.readAsArrayBuffer(file);
                break;
            case 'text':
                reader.readAsText(file);
                break;
            case 'dataurl':
                reader.readAsDataURL(file);
                break;
        }
    });
}

export function safeJsonParse<T>(jsonString: string | null): T | null {
    try {
        const object = JSON.parse(jsonString ?? '');
        return object as T;
    } catch (e) {
        return null;
    }
}

const numberFormatter = new Intl.NumberFormat();

export function formatNumber(num: number) {
    return numberFormatter.format(num);
}

export function formatPhoneNumber(num: string): string {
    return num
        .replace(/[^0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export function getGridData<T>(gridApi: GridApi | null): T[] {
    const data: T[] = [];
    gridApi?.forEachNode((node) => data.push(node.data));
    return data;
}

export function getGridSelectedRows<T>(gridApi: GridApi | null): T[] {
    const data: T[] = [];
    gridApi?.getSelectedRows().forEach((row) => {
        data.push(row);
    });
    return data;
}

export function getGridDataAfterFilter<T>(gridApi: GridApi | null): T[] {
    const data: T[] = [];
    gridApi?.forEachNodeAfterFilterAndSort((node) => data.push(node.data));
    return data;
}

export function objectToFormData(obj: object): FormData {
    const fd = new FormData();
    entries(obj).forEach((ent) => {
        fd.append(ent[0], ent[1]);
    });

    return fd;
}

export function dateValidator(val: string) {
    if (val) {
        const date = fromUTC(val);
        if (
            !isValid(date) ||
            isBefore(date, new Date(1754, 0, 0)) ||
            isAfter(date, new Date(9999, 0, 0))
        ) {
            return 'Invalid date';
        }
    }
    return '';
}

export function renderIconToString(icon: ReactElement) {
    return ReactDOMServer.renderToStaticMarkup(
        <ThemeProvider theme={muiTheme}>{icon}</ThemeProvider>
    );
}

export function cleanPhoneNumber(number: string) {
    let cleaned = ('' + number).replace(/\D/g, '');

    if (cleaned.length === 11) {
        cleaned = cleaned.substring(1);
    }

    if (cleaned.length === 10) {
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/) ?? [];
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return '';
}

export function truncateAndEllipsize(str: string, maxLength: number) {
    if (!str || str.length < maxLength) {
        return str;
    }
    return str.substring(0, maxLength) + '...';
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const formatCurrency = (amount: number): string => {
    return currencyFormatter.format(amount);
};

export const isEmployer = (emp: any): emp is Employer => {
    return emp?.hasOwnProperty('employerName');
};
