import 'react-app-polyfill/stable';
import 'typeface-lato';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './tailwind.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './app/store';
import { Provider } from 'react-redux';
import { LicenseManager } from 'ag-grid-enterprise';
import { ThemeProvider, StylesProvider } from '@material-ui/core';
import { muiTheme } from './app/theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';

LicenseManager.setLicenseKey(
    'ThirtyTech_MultiApp_1Devs23_September_2020__MTYwMDgxNTYwMDAwMA==952595342e2c123a4d1f36dd8dea7203'
);

ReactDOM.render(
    // Can't enable strict mode since MUI doesn't support it. This'll change in v5
    // <React.StrictMode>
    <Provider store={store}>
        <StylesProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
                <SnackbarProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Router>
                            <App />
                        </Router>
                    </MuiPickersUtilsProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StylesProvider>
    </Provider>,
    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
