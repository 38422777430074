// import { SelectField, SelectFieldProps } from '@extjs/ext-react';
import {
    FormControl,
    FormControlProps,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
} from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import React from 'react';

class FSelectField extends React.Component<
    SelectProps & {
        fullWidth?: boolean;
        controlClasses?: FormControlProps['classes'];
        options: {
            text: string;
            value: any;
        }[];
        hideErrorText?: boolean;
        name: string;
    }
> {
    render() {
        const {
            hideErrorText,
            options,
            fullWidth,
            controlClasses,
            ...props
        } = this.props;
        return (
            <Field name={props.name}>
                {({ field, form }: FieldProps) => {
                    const { errors, touched } = form;
                    return (
                        <FormControl
                            fullWidth={fullWidth}
                            classes={controlClasses}
                            error={
                                !!(
                                    (touched[props.name] ||
                                        form.submitCount > 0) &&
                                    errors[props.name]
                                )
                            }
                            required={props.required}
                        >
                            {props.label && (
                                <InputLabel>{props.label}</InputLabel>
                            )}
                            <Select
                                {...props}
                                {...field}
                                MenuProps={{ disableScrollLock: true }} //Fix: body padding https://github.com/mui-org/material-ui/issues/17353
                                fullWidth={fullWidth}
                                value={field.value == null ? '' : field.value}
                                onChange={(e, child) => {
                                    const newVal = e.target.value;
                                    form.setFieldValue(props.name, newVal);
                                    props.onChange && props.onChange(e, child);
                                }}
                                onBlur={(e) => {
                                    form.setFieldTouched(props.name, true);
                                    props.onBlur && props.onBlur(e);
                                }}
                            >
                                {options?.map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        {opt.text}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                {!hideErrorText &&
                                    (touched[props.name] ||
                                        form.submitCount > 0) &&
                                    errors[props.name]}
                            </FormHelperText>
                        </FormControl>
                    );
                }}
            </Field>
        );
    }
}

export default FSelectField;
