import React, { useState } from 'react';
import { Footer } from './Footer';
import { PhotoFrame } from '../components/PhotoFrame';
import happyEmployees from '../assets/happy-employees.jpg';
import { cx } from 'emotion';
import { useTypedSelector } from '../app/store';
import { quillStyleReset } from '../app/constants';
import { AnimatedThumb } from '../components/AnimatedThumb';

export const Completed: React.FC = () => {
    const [hash, setHash] = useState<number>(0);
    const successTemplate = useTypedSelector(
        (state) => state.appReducer.local?.clientJoinSuccessTemplate
    );

    return (
        <div className="flex-1 flex flex-col">
            <div
                className={cx(
                    'flex flex-col items-center flex-shrink-0 py-32 bg-ufcw-blue-dark -mt-24 pt-32 md:pt-24',
                    'md:py-48 md:pt-48'
                )}
            >
                <div className="flex items-center justify-center">
                    <AnimatedThumb
                        key={hash}
                        className="h-16 w-16 md:h-32 md:w-32 cursor-pointer"
                        onClick={() => setHash(Date.now())}
                    />
                </div>

                <h1 className="text-white text-4xl font-extrabold mt-2 md:text-6xl mt-8 text-shadow">
                    All Set!
                </h1>
                <h2 className="text-ufcw-yellow text-xl  md:text-2xl text-shadow">
                    We'll be in touch soon.
                </h2>
            </div>
            <div className="md:px-8 md:mb-8">
                <PhotoFrame
                    header="What's Next?"
                    img={happyEmployees}
                    className={cx(
                        'mb-8 m-auto -mt-16',
                        'md:max-w-screen-xl md:-mt-16 md:px-4 md:pb-0'
                    )}
                    classes={{
                        image: 'pt-4 pb-0 md:py-8',
                        header: 'text-white md:mb-12 md:text-2xl',
                    }}
                >
                    <div className="px-3 pb-4">
                        {successTemplate ? (
                            <div
                                className={quillStyleReset}
                                dangerouslySetInnerHTML={{
                                    __html: successTemplate,
                                }}
                            />
                        ) : (
                            <>
                                <h3 className="inline-block margin-auto text-xl font-bold mb-3 text-ufcw-blue">
                                    Be on the lookout for a Welcome Letter from
                                    your Local Union:
                                </h3>
                                <p className="text-xl">
                                    <strong>UFCW Local 123</strong>
                                </p>
                                <p>555-555-5555</p>
                                <p>
                                    <span className="text-ufcw-blue underline">
                                        ufcwlocal123@gmail.com
                                    </span>
                                </p>
                                <p>President Charles Charleston</p>
                                <hr className="my-6" />
                            </>
                        )}
                        <p className="text-base font-serif">
                            Learn more about your benefits as a Union Member at{' '}
                            <a
                                className="text-base font-serif underline text-ufcw-blue"
                                href="http://myufcw.org"
                            >
                                myufcw.org
                            </a>
                        </p>
                    </div>
                </PhotoFrame>
            </div>
            <Footer className="mt-auto md:mt-16" />
        </div>
    );
};
