import React, { ReactNode } from 'react';

interface ConditionalWrapperProps {
    wrap?: boolean;
    wrapper: (children: ReactNode) => ReactNode;
}

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
    wrap,
    wrapper,
    children,
}) => <>{wrap ? wrapper(children) : children}</>;
