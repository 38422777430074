import {
    createSlice,
    createSelector,
    createAsyncThunk,
    PayloadAction,
} from '@reduxjs/toolkit';
import {
    SurveyTemplate,
    UpsertSurveyTemplateRequest,
    PreviewTemplateRequest,
    Survey,
} from '../types';
import { RootState } from '../app/store';
import { api } from '../app/utils';
import { keyBy } from 'lodash-es';
import { CallActions } from './callSlice';

// TODO: rename to for local
const fetchSurveyTemplates = createAsyncThunk(
    'survey/fetchSurveyTemplates',
    async ({ localId }: { localId: string }, thunkAPI) => {
        const res: SurveyTemplate[] = await api.get(`survey/template`).json();

        return res;
    }
);

const fetchAllSurveyTemplates = createAsyncThunk(
    'survey/fetchAllSurveyTemplates',
    async () => {
        const res: SurveyTemplate[] = await api
            .get(`survey/all-templates`)
            .json();

        return res;
    }
);

const upsertSurveyTemplate = createAsyncThunk(
    'survey/upsertSurvey',
    async (survey: UpsertSurveyTemplateRequest) => {
        const res: SurveyTemplate = await api
            .post('survey/upsert-template', {
                json: survey,
            })
            .json();

        return res;
    }
);

const deleteTemplate = createAsyncThunk(
    'survey/deleteTemplate',
    async (surveyId: string) => {
        const res: SurveyTemplate = await api
            .delete(`survey/${surveyId}`)
            .json();

        return res;
    }
);

const previewTemplate = createAsyncThunk(
    'survey/previewTemplate',
    async (req: PreviewTemplateRequest) => {
        await api.post('survey/preview-template', {
            json: req,
        });
    }
);

const sendSurveys = createAsyncThunk(
    'admin/sendJoinForm',
    async (req: { memberIds: string[]; surveyTemplateId: string }) => {
        await api.post('survey/send', {
            json: req,
        });
    }
);

const fetchByMemberId = createAsyncThunk(
    'survey/fetchByMemberId',
    async (memberId: string) => {
        const surveys: Survey[] = await api
            .get(`survey/member/${memberId}`)
            .json();

        return surveys;
    }
);

export interface SurveyState {
    surveyTemplates: Record<string, SurveyTemplate>;
    selectedTemplate: string | null;
    surveys: Record<string, Survey[]>;
}

const initialState: SurveyState = {
    surveyTemplates: {},
    selectedTemplate: null,
    surveys: {},
};

const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        selectTemplate(state, action: PayloadAction<string>) {
            state.selectedTemplate = action.payload;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(upsertSurveyTemplate.fulfilled, (state, action) => {
                state.surveyTemplates[action.payload.displayID] =
                    action.payload;
                state.selectedTemplate = action.payload.displayID;
            })
            .addCase(fetchSurveyTemplates.fulfilled, (state, action) => {
                state.surveyTemplates = keyBy(action.payload, 'displayID');
            })
            .addCase(deleteTemplate.fulfilled, (state, action) => {
                delete state.surveyTemplates[action.payload.displayID];
            })
            .addCase(fetchByMemberId.fulfilled, (state, action) => {
                const memberId = action.payload[0]?.memberDisplayId;

                if (memberId) {
                    state.surveys[memberId] = action.payload;
                }
            })
            .addCase(CallActions.pollCallStatus.fulfilled, (state, action) => {
                if (action.payload.surveys?.length) {
                    const memberId = action.payload.surveys[0].memberDisplayId;
                    const existing = state.surveys[memberId].filter((survey) =>
                        action.payload.surveys.find(
                            (surv) => surv.displayID === survey.displayID
                        )
                    );

                    state.surveys[memberId] = [
                        ...existing,
                        ...action.payload.surveys,
                    ];
                }
            }),
});

const { actions, reducer } = surveySlice;

export const SurveyActions = {
    ...actions,
    fetchSurveyTemplates,
    fetchAllSurveyTemplates,
    upsertSurveyTemplate,
    deleteTemplate,
    previewTemplate,
    sendSurveys,
    fetchByMemberId,
};

const getSurveys = createSelector(
    (state: RootState) => state.surveyReducer.surveyTemplates,
    (surveys) => {
        return Object.values(surveys);
    }
);

const getSelectedSurvey = createSelector(
    (state: RootState) => state.surveyReducer.surveyTemplates,
    (state: RootState) => state.surveyReducer.selectedTemplate,
    (templates, selectedId) => {
        if (!selectedId) {
            return undefined;
        }
        return templates[selectedId] ?? undefined;
    }
);

export const SurveySelectors = {
    getSurveys,
    getSelectedSurvey,
};

export default reducer;
