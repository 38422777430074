import { createReducer, createSelector } from '@reduxjs/toolkit';
import {
    AdminActions,
    fetchEmployers,
    fetchMemberJoinLinks,
    getLocals,
    selectLocal,
    sendJoinForm,
    updateLocalSettings,
    updateTemplate,
} from '../actions/adminActions';
import {
    AdditionalField,
    Employer,
    GridPreset,
    JoinLink,
    JWT,
    Local,
    LocalTag,
    RoleKey,
    TagCounts,
    TagSummary,
} from '../types';
import { keyBy, pickBy, values, partition } from 'lodash-es';
import { RootState } from '../app/store';
import { CallActions } from './callSlice';
import _ from 'lodash';
import { compareDesc } from 'date-fns';
import { fromUTC } from '../app/utils';
import { LocalStorageKey } from '../app/constants';
import JwtDecode from 'jwt-decode';
import { login } from '../actions/appActions';

const jwt = localStorage.getItem(LocalStorageKey.JWT);
let token: JWT | undefined = undefined;

if (jwt) {
    token = JwtDecode<JWT>(jwt);
}

export interface AdminState {
    localNumbers?: string[];
    locals: Record<string, Local | undefined>;
    selectedLocal: string;
    employersByLocal: Record<string, Employer[]>;
    joinLinksByMember: Record<string, JoinLink[]>;
    customFieldsByLocal: { [localNumber: string]: AdditionalField[] };
    gridPresetsById: Record<string, GridPreset>;
    activeSignupsFilters: { colId: string; colName: string }[];
    localTags: LocalTag[];
    tagCounts: TagCounts;
    tagSummaries: TagSummary[];
    local: Local | undefined;
}

const defaultState: AdminState = {
    localNumbers: undefined,
    locals: {},
    selectedLocal: token?.localNumber ?? '',
    employersByLocal: {},
    joinLinksByMember: {},
    customFieldsByLocal: {},
    gridPresetsById: {},
    activeSignupsFilters: [],
    localTags: [],
    tagCounts: {},
    tagSummaries: [],
    local: undefined,
};

export const adminReducer = createReducer(defaultState, (builder) =>
    builder
        .addCase(sendJoinForm.fulfilled, (state, action) => {})
        .addCase(getLocals.fulfilled, (state, action) => {
            state.localNumbers = action.payload.map(
                (local) => local.localNumber
            );
            state.locals = keyBy(action.payload, 'localNumber');
        })

        .addCase(login.fulfilled, (state, action) => {
            if (action.payload) {
                state.selectedLocal = action.payload.localNumber;
            }
        })
        .addCase(selectLocal, (state, action) => {
            state.selectedLocal = action.payload;
        })
        .addCase(updateTemplate.fulfilled, (state, action) => {
            state.locals[action.payload.localNumber] = action.payload;
        })
        .addCase(updateLocalSettings.fulfilled, (state, action) => {
            state.locals[action.payload.localNumber] = action.payload;
        })
        .addCase(fetchEmployers.fulfilled, (state, action) => {
            state.employersByLocal[action.payload.localNumber] =
                action.payload.employers;
        })
        .addCase(fetchMemberJoinLinks.fulfilled, (state, action) => {
            state.joinLinksByMember[action.payload.memberId] =
                action.payload.links;
        })
        .addCase(AdminActions.fetchLocalTags.fulfilled, (state, action) => {
            state.localTags = action.payload.tags;
        })
        .addCase(AdminActions.fetchTagCounts.fulfilled, (state, action) => {
            state.tagCounts = action.payload;
        })
        .addCase(AdminActions.fetchTagSummaries.fulfilled, (state, action) => {
            state.tagSummaries = action.payload;
        })
        .addCase(AdminActions.createLocalTags.fulfilled, (state, action) => {
            const [newTags, existingTags] = partition(
                action.payload,
                (lt) => !state.tagSummaries.find((ts) => ts.tag == lt.tag)
            );

            existingTags.forEach((tag) => {
                var sum = state.tagSummaries.find((ts) => ts.tag === tag.tag);

                if (sum) {
                    sum.localTagDisplayId = tag.displayID;
                }
            });

            const newSummaries = newTags.map<TagSummary>((t) => ({
                count: 0,
                tag: t.tag,
                localTagDisplayId: t.displayID,
            }));

            state.tagSummaries.push(...newSummaries);
        })
        .addCase(
            AdminActions.getMemberCustomFields.fulfilled,
            (state, action) => {
                state.customFieldsByLocal[action.payload.local] =
                    action.payload.additionalFields;
            }
        )
        .addCase(AdminActions.createEmployers.fulfilled, (state, action) => {
            // Could potentially break if they switch employers while the req is in-flight :3
            const currentEmployers = keyBy(
                state.employersByLocal[state.selectedLocal],
                (e) => e.displayID
            );
            const newEmployers = keyBy(action.payload, (e) => e.displayID);
            state.employersByLocal[state.selectedLocal] = values({
                ...currentEmployers,
                ...newEmployers,
            });
        })
        .addCase(AdminActions.deleteEmployer.fulfilled, (state, action) => {
            const currentEmployers = keyBy(
                state.employersByLocal[state.selectedLocal],
                (e) => e.displayID
            );

            delete currentEmployers[action.payload];

            state.employersByLocal[state.selectedLocal] = values({
                ...currentEmployers,
            });
        })
        .addCase(AdminActions.renameTag.fulfilled, (state, action) => {
            const sum = state.tagSummaries.find(
                (ts) => ts.tag === action.payload.oldTag
            );

            if (sum) {
                sum.tag = action.payload.tag;
            }
        })
        .addCase(AdminActions.deleteTag.fulfilled, (state, action) => {
            if (action.payload.deletedFromMembers) {
                state.tagSummaries = state.tagSummaries.filter(
                    (ts) => ts.tag !== action.payload.tag
                );
            } else {
                const sum = state.tagSummaries.find(
                    (ts) => ts.tag === action.payload.tag
                );
                if (sum) {
                    if (sum.count === 0) {
                        state.tagSummaries = state.tagSummaries.filter(
                            (ts) => ts.tag != action.payload.tag
                        );
                    } else {
                        sum.localTagDisplayId = null;
                    }
                }
            }
        })
        .addCase(AdminActions.deleteAllTags.fulfilled, (state, action) => {
            if (action.payload.deletePresets) {
                state.tagSummaries = [];
            } else {
                state.tagSummaries = state.tagSummaries.filter(
                    (t) => t.localTagDisplayId
                );
                state.tagSummaries.forEach((t) => {
                    t.count = 0;
                });
            }
        })
        .addCase(AdminActions.fetchGridPresets.fulfilled, (state, action) => {
            state.gridPresetsById = keyBy(action.payload, (k) => k.displayID);
        })
        .addCase(AdminActions.deleteGridPreset.fulfilled, (state, action) => {
            state.gridPresetsById = pickBy(
                state.gridPresetsById,
                (a) => a.displayID !== action.payload.displayId
            );
        })
        .addCase(AdminActions.setActiveSignupsFilters, (state, action) => {
            state.activeSignupsFilters = action.payload;
        })
        .addCase(
            AdminActions.deleteAdditionalField.fulfilled,
            (state, action) => {
                const { localNumber, fieldId } = action.payload;
                state.customFieldsByLocal[
                    localNumber
                ] = state.customFieldsByLocal[localNumber].filter(
                    (l) => l.displayID !== fieldId
                );
            }
        )
        .addCase(CallActions.pollCallStatus.fulfilled, (state, action) => {
            if (action.payload.joinLinksSent?.length) {
                const memberId = action.payload.memberDisplayId;
                const existing =
                    state.joinLinksByMember[memberId]?.filter((link) => {
                        action.payload.joinLinksSent.find(
                            (surv) => surv.displayID === link.displayID
                        );
                    }) ?? [];
                state.joinLinksByMember[memberId] = [
                    ...existing,
                    ...action.payload.joinLinksSent,
                ];
            }
        })
        .addCase(
            AdminActions.updateLocalPaymentSettings.fulfilled,
            (state, action) => {
                state.local = action.payload;
                state.locals[action.payload.localNumber] = action.payload;
            }
        )
);

const getSelectedLocal = createSelector(
    (state: RootState) => state.appReducer.token?.localNumber ?? '',
    (state: RootState) => state.adminReducer.locals,
    (selectedLocal, locals) => {
        return locals[selectedLocal ?? ''];
    }
);

const getCustomFields = createSelector(
    (state: RootState) => state.appReducer.token?.localNumber ?? '',
    (state: RootState) => state.adminReducer.customFieldsByLocal,
    (local, customFields): AdditionalField[] | undefined => {
        return customFields[local ?? ''] ?? undefined;
    }
);

const getEmployers = createSelector(
    (state: RootState) => state.appReducer.token?.localNumber ?? '',
    (state: RootState) => state.adminReducer.employersByLocal,
    (local, employers): Employer[] | undefined => {
        const localEmployers = employers[local ?? ''];
        if (localEmployers) {
            return [...localEmployers].sort((a, b) =>
                (a.name ?? a.employerName).localeCompare(b.name)
            );
        }
        return undefined;
    }
);

const getGridPresets = createSelector(
    (state: RootState) => state.adminReducer.gridPresetsById,
    (presets) => {
        const [myPresets, localPresets] = _(presets)
            .values()
            .sort((a, b) =>
                compareDesc(fromUTC(a.dateUpdated), fromUTC(b.dateUpdated))
            )
            .partition((p) => p.presetFor === 'USER')
            .value();

        return { myPresets, localPresets };
    }
);

const getAllLocals = createSelector(
    (state: RootState) => state.adminReducer.locals,
    (locals): Local[] => {
        return values(locals).filter((l) => !!l) as Local[];
    }
);

const getEmployersByCompany = createSelector(
    (state: RootState) => getEmployers(state),
    (employers): Employer[] => {
        return (
            _(employers ?? [])
                .sortedUniqBy((e) => e.employerName ?? '')
                .value() ?? []
        );
    }
);

const getTagSummary = createSelector(
    (state: RootState) => state.adminReducer.tagSummaries,
    (_: RootState, tagName: string) => tagName,
    (tagSums, tagName) => {
        return tagSums.find((ts) => ts.tag === tagName);
    }
);

export const AdminSelectors = {
    getSelectedLocal,
    getCustomFields,
    getGridPresets,
    getEmployers,
    getAllLocals,
    getEmployersByCompany,
    getTagSummary,
};
