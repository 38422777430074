import {
    createSlice,
    PayloadAction,
    createAsyncThunk,
    unwrapResult,
    createAction,
} from '@reduxjs/toolkit';
import {
    MemberWithEmployerLocal,
    CallStatus,
    StartMemberCallRequest,
    MemberCall,
    UpdateMemberCallRequest,
    HangupCallRequest,
    MemberCallStatusResponse,
    MemberCallWithStatus,
    Member,
} from '../types';
import { api, fromUTC } from '../app/utils';
import { dispatch, AppThunk, RootState } from '../app/store';
import { compareDesc } from 'date-fns';

const pollCallStatus = createAsyncThunk(
    'call/pollCallStatus',
    async (req: MemberCall, thunkAPI) => {
        const {
            surveyReducer,
            adminReducer,
        } = thunkAPI.getState() as RootState;

        const surveyTime = [
            ...(surveyReducer.surveys[req.toMemberDisplayId] ?? []),
        ]?.sort((a, b) =>
            compareDesc(fromUTC(a.dateUpdated), fromUTC(b.dateUpdated))
        )[0]?.dateUpdated;

        const joinLinksTime = [
            ...(adminReducer.joinLinksByMember[req.toMemberDisplayId] ?? []),
        ]?.sort((a, b) =>
            compareDesc(fromUTC(a.dateUpdated), fromUTC(b.dateUpdated))
        )[0]?.dateUpdated;

        const params = new URLSearchParams({
            surveysSince: surveyTime ?? '',
            joinLinksSince: joinLinksTime ?? '',
            // joinFormsSince: '',
        });

        const res: MemberCallStatusResponse = await api
            .get(`memberCall/get-call-status/${req.displayID}?${params}`)
            .json();

        return res;
    }
);

const pollStatus = (call: MemberCall): AppThunk => {
    return async (dispatch, getState) => {
        const status = await dispatch(pollCallStatus(call)).then(unwrapResult);
        const callState: CallState = getState().callReducer;
        const isDialogDirty = callState.isDialogDirty;
        const currentCallId = callState.currentCall?.displayID;

        if (isDialogDirty && currentCallId === call.displayID) {
            setTimeout(
                () => {
                    dispatch(pollStatus(call));
                },
                status.dateEnded ? 3_000 : 1_000
            );
        }
    };
};

const callMember = createAsyncThunk(
    'call/callMember',
    async (req: StartMemberCallRequest, thunkAPI) => {
        const res: MemberCall = await api
            .post(`memberCall/start`, {
                json: req,
            })
            .json();

        dispatch(pollStatus(res));

        return res;
    }
);

const endCall = createAsyncThunk('call/endCall', async (_, thunkAPI) => {
    const callState: CallState = (thunkAPI.getState() as any).callReducer;

    if (!callState.currentCall) {
        console.warn('Unable to hang up when not in call.');
        return;
    }

    const req: HangupCallRequest = {
        callId: callState.currentCall?.displayID,
    };

    const res: MemberCall = await api
        .post(`memberCall/hangup`, {
            json: req,
        })
        .json();

    return res;
});

const updateCall = createAsyncThunk(
    'call/updateCall',
    async (req: UpdateMemberCallRequest) => {
        const res: MemberCall = await api
            .post('memberCall/update', {
                json: req,
            })
            .json();

        return res as MemberCall;
    }
);

const completeCall = createAsyncThunk(
    'call/completeCall',
    async (req: UpdateMemberCallRequest) => {
        const res: MemberCall = await api
            .post('memberCall/update', {
                json: req,
            })
            .json();

        return res as MemberCall;
    }
);

const getCallHistory = createAsyncThunk(
    'call/fetchHistory',
    async (memberId: string) => {
        const res: MemberCall[] = await api
            .get(`memberCall/get-history/${memberId}`)
            .json();

        return {
            memberId,
            callHistory: res,
        };
    }
);

const getTags = createAsyncThunk('call/getTags', async (memberId: string) => {
    const res: string[] = await api
        .get(`memberCall/get-tags/${memberId}`)
        .json();

    return res;
});

export const updateCallStatus = createAction<CallStatus>('app/selectLocal');

export interface CallState {
    selectedEmployee?: MemberWithEmployerLocal;
    currentCall?: MemberCallWithStatus;
    callStatus: CallStatus;
    callHistory: Record<string, MemberCall[]>;
    tags: string[];
    isDialogDirty: boolean;
}

const initialState: CallState = {
    selectedEmployee: undefined,
    currentCall: undefined,
    callStatus: CallStatus.NotStarted,
    callHistory: {},
    tags: [],
    isDialogDirty: false,
};

const callSlice = createSlice({
    name: 'call',
    initialState,
    reducers: {
        selectMember(state, action: PayloadAction<MemberWithEmployerLocal>) {
            state.selectedEmployee = action.payload;
        },
        updateMember(state, action: PayloadAction<Member>) {
            if (state.selectedEmployee) {
                state.isDialogDirty = true;
                state.selectedEmployee = {
                    ...state.selectedEmployee,
                    ...action.payload,
                };
            }
        },
        deselectMember(state) {
            state.selectedEmployee = undefined;
            state.callHistory = {};
            state.callStatus = CallStatus.NotStarted;
        },
        setCallStatus(state, action: PayloadAction<CallStatus>) {
            state.callStatus = action.payload;
        },
        updateDirty(state, action: PayloadAction<boolean>) {
            state.isDialogDirty = action.payload;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(callMember.fulfilled, (state, action) => {
                state.currentCall = action.payload;
                state.isDialogDirty = true;
            })
            .addCase(endCall.fulfilled, (state, action) => {
                if (action.payload) {
                    state.callStatus = CallStatus.Ended;
                    state.currentCall = {
                        ...state.currentCall,
                        ...action.payload,
                    };
                }
            })
            .addCase(completeCall.fulfilled, (state, action) => {
                state.selectedEmployee = undefined;
                state.callStatus = CallStatus.NotStarted;
            })
            .addCase(getTags.fulfilled, (state, action) => {
                state.tags = action.payload;
            })
            .addCase(getCallHistory.fulfilled, (state, action) => {
                state.callHistory[action.payload.memberId] =
                    action.payload.callHistory;
            })
            .addCase(updateCallStatus, (state, action) => {
                state.callStatus = action.payload;
                if (action.payload === CallStatus.NotStarted) {
                    state.currentCall = undefined;
                }
            })
            .addCase(pollCallStatus.fulfilled, (state, { payload }) => {
                if (state.currentCall && state.isDialogDirty) {
                    state.currentCall = {
                        ...state.currentCall,
                        ...payload,
                    };

                    state.callStatus = payload.callStatus;
                }
            }),
});

const { actions, reducer } = callSlice;

export const CallActions = {
    ...actions,
    callMember,
    endCall,
    completeCall,
    getCallHistory,
    getTags,
    updateCall,
    updateCallStatus,
    pollCallStatus,
};

export default reducer;
