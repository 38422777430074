import React, { useState, useCallback } from 'react';
import { css, cx } from 'emotion';
import { palette, mq } from '../app/theme';
import JoinVideo from '../components/JoinVideo';
import { Height } from '@material-ui/icons';

export interface PhotoFrameProps {
    header: string;
    img?: string | null;
    video?: string | null;
    className?: string;
    classes?: {
        root?: string;
        header?: string;
        body?: string;
        content?: string;
        image?: string;
        border?: string;
    };
}

const borderOffset = '1rem';

export const PhotoFrame: React.FC<PhotoFrameProps> = ({
    children,
    header,
    img,
    video,
    className,
    classes = {},
}) => {
    const [headerWidth, setHeaderWidth] = useState<number>(0);

    const measureHeader = useCallback(
        (node: HTMLElement | null) => {
            if (node && header) {
                setHeaderWidth(node.getBoundingClientRect().width);
            } else {
                setHeaderWidth(0);
            }
        },
        [header]
    );

    return (
        <div
            className={cx(
                'relative flex flex-col items-center',
                className,
                classes.root
            )}
        >
            <div
                className={cx(
                    css({
                        borderRadius: '0.5em',
                        borderColor: palette.ufcwYellow,
                        borderWidth: 1,

                        position: 'absolute',
                        top: borderOffset,
                        right: borderOffset,
                        bottom: borderOffset,
                        left: borderOffset,
                        clipPath: `polygon(
                                    0 0,
                                    calc(50% - 1.5rem - ${headerWidth / 2}px) 0,
                                    calc(50% - 1.5rem - ${
                                        headerWidth / 2
                                    }px) 0.5rem,
                                    0.5rem 0.5rem,
                                    0.5rem calc(100% - 0.5rem),
                                    calc(100% - 0.5rem) calc(100% - 0.5rem),
                                    calc(100% - 0.5rem) 0.5rem,
                                    calc(50% + 1.5rem + ${
                                        headerWidth / 2
                                    }px) 0.5rem,
                                    calc(50% + 1.5rem + ${headerWidth / 2}px) 0,
                                    100% 0,
                                    100% 100%,
                                    0 100%
                    )`,

                        [mq('md')]: {
                            right: 0,
                            bottom: 0,
                            left: 0,
                        },
                    }),
                    classes.border
                )}
            ></div>
            <h3
                className={cx(
                    'inline-block margin-auto text-xl text-center font-bold mb-3',
                    classes.header,
                    'md:mb-12'
                )}
                ref={measureHeader}
            >
                {header}
            </h3>
            <div className={cx(classes.body)}>
                {img && !video && (
                    <div className="flex-1">
                        <img
                            src={img}
                            alt=""
                            className={cx(
                                'w-full object-contain',
                                classes.image
                            )}
                        />
                    </div>
                )}
                {video && (
                    <JoinVideo
                        video={video}
                        classes={css({ width: '100%', height:'100%', minHeight: 350, flex: 1})}
                    />
                )}
                <div
                    className={cx(
                        'text-gray-800 py-8 px-8 flex-1 md:p-0',
                        classes.content
                    )}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
