import {
    JoinForm,
    SignatureType,
    SubmitJoinResult,
    SubmitJoinRequest,
    Employer,
    PaymentSessionInfo,
    PaymentSubmissionResponse,
} from '../types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunk } from '../app/store';
import { JoinState } from '../reducers/joinFormReducer';
import ky from 'ky';
import { AppState } from '../reducers/appReducer';
import { omit } from 'lodash-es';
import { api } from '../app/utils';

export const markJoinFormCompleted = createAction('joinForm/markCompleted');

export const fetchEmployers = createAsyncThunk(
    'joinForm/fetchEmployers',
    async (localNumber: string) => {
        const employers: Employer[] = await api
            .get(`employer/by-local/${localNumber}`)
            .json();
        return { localNumber, employers };
    }
);

export const submitJoinForm = (payload: Partial<JoinForm>): AppThunk => (
    dispatch
) => {
    dispatch(updateJoinForm(payload));
    dispatch(markJoinFormCompleted());
};

export const updateJoinForm = createAction<Partial<JoinForm>>(
    'joinForm/update'
);

export const updateSignature = createAction<{
    type: SignatureType;
    value: string;
}>('joinForm/updateSignature');

export const submitApplication = createAsyncThunk<
    // return type
    SubmitJoinResult,
    // arg to action creator
    void
>('joinForm/submitApplication', async (_, thunkAPI) => {
    // Gotta type state as any instead of RootState to avoid circular reference :/
    const state: any = thunkAPI.getState();
    const { signatures, joinForm }: JoinState = state.joinFormReducer;
    const appState: AppState = state.appReducer;

    const { employerId, localId } = appState.employee ?? {};
    const pickedForm = omit(joinForm, [
        'formEmployerAddress',
        'formEmployer',
        'formLocal',
    ]);

    const submission: SubmitJoinRequest = {
        member: {
            ...pickedForm,
            employerId,
            localId,
        },
        additionalInfo: {
            employer: joinForm.formEmployer,
            local: joinForm.formLocal,
        },
        signatures,
        linkShortId: appState.formId,
    };

    const res = await ky
        .post('/api/join/submit', {
            json: submission,
        })
        .json();

    return res as SubmitJoinResult;
});

export const fetchLocals = createAsyncThunk(
    'joinForm/fetchLocals',
    async ({ includeAssociate }: { includeAssociate: boolean }, thunkAPI) => {
        var query = new URLSearchParams({
            includeAssociate: includeAssociate.toString(),
        });

        const locals = await api.get(`join/get-locals?${query}`).json();

        return locals as string[];
    }
);

export const fetchPaymentSession = createAsyncThunk(
    'joinForm/fetchPaymentSession',
    async (
        { localNumber, joinGuid }: { localNumber: string; joinGuid: string },
        thunkAPI
    ) => {
        const paymentSessionInfo = await api
            .post(`payment/${localNumber}`, {
                retry: {
                    limit: 5,
                    methods: ['post'],
                },
                json: {
                    joinGuid,
                },
            })
            .json<PaymentSessionInfo>();

        return paymentSessionInfo;
    }
);

export const updatePaymentInfo = createAsyncThunk(
    'joinForm/updatePaymentInfo',
    async (
        {
            amount,
            confirmationCode,
            type,
            joinGuid,
        }: Omit<PaymentSubmissionResponse, 'paymentId'> & { joinGuid: string },
        thunkApi
    ) => {
        const res = await api
            .post('join/update-payment-info', {
                json: {
                    joinGuid,
                    paymentType: type,
                    amount,
                    confirmationCode,
                },
            })
            .json();

        return res;
    }
);

export const selectEmployer = createAction<Employer>('app/selectEmployer');

export const JoinFormActions = {
    fetchEmployers,
    fetchPaymentSession,
};
