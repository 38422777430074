import * as React from 'react';
import { FC } from 'react';
import {
    ButtonProps,
    Button,
    CircularProgress,
    CircularProgressProps,
} from '@material-ui/core';
import { css, cx } from 'emotion';

export interface LoaderButtonProps extends ButtonProps {
    CircularProgressProps?: Partial<CircularProgressProps>;
    loading?: boolean;
    ContainerProps?: Partial<React.HTMLProps<HTMLDivElement>>;
}

export const LoaderButton: FC<LoaderButtonProps> = (props) => {
    const {
        loading,
        CircularProgressProps,
        ContainerProps,
        disabled,
        ...buttonProps
    } = props;

    return (
        <div
            {...ContainerProps}
            className={`relative ${ContainerProps?.className ?? ''}`}
        >
            <Button
                disabled={loading || disabled}
                {...buttonProps}
                className={cx(
                    buttonProps.className ?? '',
                    css({ color: loading ? 'transparent' : undefined })
                )}
            ></Button>
            {loading && (
                <CircularProgress
                    size={20}
                    color="secondary"
                    thickness={5}
                    {...CircularProgressProps}
                    className={css(
                        {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginLeft: -10,
                            marginTop: -10,
                        },
                        CircularProgressProps?.className
                    )}
                />
            )}
        </div>
    );
};
