import { GridState } from './utils/useStatefulGrid';
import { ColDef } from 'ag-grid-community';

export interface TypedColDef<T> extends Omit<ColDef, 'field'> {
    field?: keyof T | string;
}

export interface EntityBase {
    dateCreated: string;
    dateUpdated: string;
    dateDeleted?: string;
    userCreated?: string;
    userUpdated?: string;
    displayID: string;
}

export interface Member extends EntityBase {
    ssn: string;
    ssN_4: string;
    encryptedSSN: string;

    firstName: string;
    initial: string;
    lastName: string;
    suffix: string;

    sex: string;
    birthDate: string;
    maritalStatus: string;
    race: string;

    email: string;
    phoneNum?: string;
    textMessage: string;
    cellPhone: string;
    preferredPhone?: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;

    localId: number;
    employerId: string;
    companyId: string;
    storeId: string;
    origHireDate?: string;
    recentHireDate?: string;
    terminationDate?: string;
    jbno: string;
    fp: string;
    department: string;
    rateOfPay?: number;
    hours?: number;
    steward?: string;

    abcAmount?: number;
    pendingFlag?: string;
    memberClass: string;
    duesType?: string;

    voter: string;
    language: string;
    joinSent: string;
    lastApplicationURL: string;
    local: Local;
    joinLinkId: string | null;
    joinForms: JoinRecord[] | null;
    lastSendFormErrors: { sms?: string; email?: string } | null;
    lastSMSError: string | null;
    numberNotDialable?: boolean;
    employeeId: string | null;

    callRecords?: MemberCall[];
    surveys?: Survey[];
    tags?: MemberTag[];
    hasJoined: boolean;
    hasBeenMember?: boolean;
    isRetired?: boolean;
    industry?: {
        options: string[];
        other: string;
    };
}

export interface MemberWithEmployer extends Member {
    employer: Employer;
}

export interface MemberWithEmployerLocal extends MemberWithEmployer {
    local: Local;
}

export interface GetLinkResponse extends EntityBase {
    shortId: string;
    member: MemberWithEmployer;
    localId: number;
    local: Local;
}

export type JoinForm = Pick<
    Member,
    | 'ssn'
    | 'firstName'
    | 'lastName'
    | 'initial'
    | 'sex'
    | 'birthDate'
    | 'email'
    | 'phoneNum'
    | 'textMessage'
    | 'cellPhone'
    | 'addressLine1'
    | 'addressLine2'
    | 'city'
    | 'state'
    | 'zipCode'
    | 'origHireDate'
    | 'jbno'
    | 'fp'
    | 'department'
    | 'abcAmount'
    | 'voter'
    | 'language'
    | 'hours'
    | 'race'
    | 'hasBeenMember'
    | 'isRetired'
    | 'industry'
> & {
    formLocal?: string;
    formEmployerAddress?: string;
    formEmployer?: Partial<Employer>;
};

export interface Employer extends EntityBase {
    employerNumber: string;
    employerName: string;
    employerLoc: string;
    empLastPosition: string;
    name: string;
    alternateName: string;

    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;

    homePage: string;
    phone: string;

    businessAgent?: number;
    agentLastName: string;
    agentFirstName: string;
    agentType: string;

    baseFullTime: number;
    basePartTime: number;
    previousNumber: string;
    checkOffName: string;
    lastChangeDate: Date;
    employerCombo: string;
}

export interface AddressDetails {
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
}

export enum SignatureType {
    Membership = 'SignOff',
    DuesCheckoff = 'Dues',
    ActiveBallot = 'Political',
    Custom = 'Custom',
}

export interface Signature {
    id: number;
    required: boolean;
    title: string;
    content: string;
    type: SignatureType;
}

export interface SignatureConfig extends Signature, EntityBase {
    LocalId: number;
    required: boolean;
    enabled: boolean;
}

export enum SignatureResponseStatus {
    Ok = 'OK',
    LocalNotFound = 'LOCAL_NOT_FOUND',
}

export interface SignatureResponse {
    status: SignatureResponseStatus;
    signatures: SignatureConfig[];
}

export interface SubmitJoinRequest {
    member: Partial<Member>;
    additionalInfo: {
        employer?: Partial<Employer>;
        local?: string;
    };
    signatures: Partial<Record<SignatureType, string>>;
    linkShortId?: string;
}

export interface SubmitJoinResult {
    succeeded: boolean;
    joinGuid: string;
}

export interface LoginRequest {
    local: string;
    email: string;
    password: string;
}

export const RoleKey =
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export type Role = 'User' | 'SuperAdmin' | 'Agent';

export interface JWT extends ExtraClaims {
    [RoleKey]: Role;
    sub: string;
    iat: number;
    exp: number;
    username: string;
    localId: string;
    localNumber: string;
}

export interface ExtraClaims {
    canImport?: 'true';
}

export interface Token {
    role: Role;
    userId: string;
    username: string;
    canImport: boolean;
    localNumber: string;
    localId: string;
}

export interface JoinRecord extends EntityBase {
    memberId?: number;
    encryptedSsn: string;
    memberBlob: Partial<Member>;
    employerBlob: Partial<Employer>;
    signatures: Partial<Record<SignatureType, string>>;
    applicationURL: string;
    guid: string;
    dateLastDownloaded?: string;
    userLastDownloaded?: string;
}

export interface PagedResult<T> {
    results: T[];
    count: number;
}

export interface ImportForm {
    // type: 'EMPLOYER' | 'EMPLOYEE';
    sheet: File;
    includeHeaders: boolean;
    importEmployers: boolean;
    importAgentEmployerMapping: boolean;
    doTerminationCheck: boolean;
    importAdditionalFields: boolean;
}

export interface ImportRequest {
    imports: {
        member: Partial<Member>;
        employer: Partial<Employer>;
        extraImportColumns: Partial<ExtraImportColumns>;
    }[];
    importEmployers: boolean;
    importAgentEmployerMapping: boolean;
    doTerminationCheck: boolean;
    importAdditionalFields: boolean;
}

export interface ExtraImportColumns {
    agentNumber: string | null;
}

export interface ApplicationUser {
    id: string;
    userName: string;
    email: string;
    phoneNumber: string;
    userLocals: UserLocal[];
    firstName: string;
    lastName: string;
    allowEmailNotifications: boolean;
    canImport: boolean;
    role?: Role;
    local?: string;
    userEmployers?: UserEmployer[];
    agentNumber: string | null;
}

export interface UserEmployer extends EntityBase {
    userId: string;
    employerId: number;
    employerDisplayId: string;
}

export interface UserLocal {
    localId: string;
    local?: Local;
}

export interface Local extends EntityBase {
    localNumber: string;
    homePageUrl?: string;
    contactPageUrl?: string;
    contactPhone?: string;
    defaultAbcAmount?: number;
    minABCAmount?: number;
    joinFormEmailTemplate?: string;
    joinFormSmsTemplate?: string;
    clientJoinSuccessTemplate?: string;
    successEmailTemplate?: string;
    welcomePageTemplate?: string;
    joinNotificationEmailTemplate?: string;
    appImage?: number;
    ssnFieldLabel?: string;
    joinApplicationUrl?: string;
    twilioPhoneNumber?: string;
    useLocalTagsOnly: boolean;
    videoUrl: string;
    isUsingPayments: boolean;
    duesRate: number;
    isAssociateLocal: boolean;
    areEmailConfirmationsEnabled: boolean;
    requirePayment: boolean;
    useGenericPdf: boolean;
}

export interface CreateUserForm {
    email: string;
    local?: string;
    firstName: string;
    lastName: string;
    allowEmailNotifications: boolean;
    phoneNumber: string;
    canImport: boolean;
    role?: Role;
    agentNumber: string | null;
}

export interface UpdateUserForm {
    id: string;
    firstName: string;
    lastName: string;
    allowEmailNotifications: boolean;
    canImport: boolean;
    role?: Role;
    assignedEmployerIds: string[];
}

export interface FailedMemberImportResult {
    failedMember: Partial<Member>;
    failureReason: string;
}

export interface MemberImportResult {
    failedMembers: FailedMemberImportResult[];
    createdCount: number;
    updatedCount: number;
    possibleTermCount: number;
    createdEmployersCount: number;
    updatedEmployersCount: number;
    markedAsRejoinable: number;
    errors: string[];
}

export interface PasswordResetForm {
    email: string;
    token: string;
    password: string;
    confirmPassword: string;
}

export enum QueryParam {
    Filter = 'filter',
}

export enum SignupQueryFilter {
    Unsent = 'unsent',
}

export enum LocalTemplateType {
    JoinFormEmail = 'JoinFormEmail',
    JoinFormSms = 'JoinFormSms',
    ClientJoinSuccess = 'ClientJoinSuccess',
    JoinSuccessEmail = 'JoinSuccessEmail',
    WelcomePage = 'WelcomePage',
    JoinNotificationEmailSubject = 'JoinNotificationEmailSubject',
}

export interface TemplateToken {
    label: string;
    value: string;
}

export interface UpdateLocalTemplatesRequest {
    localId: string;
    localTemplateType: LocalTemplateType;
    value: string | null;
}

export interface UpdateLocalSettingsRequest {
    localId: string;
    homePageUrl: string;
    contactPageUrl: string;
    contactPhone: string;
    defaultAbcAmount: number | null;
    minABCAmount: number | null;
    joinApplicationUrl: string | null;
    appImage: number;
    ssnFieldLabel: string;
    useLocalTagsOnly: boolean;
    videoUrl: string;
    isAssociateLocal: boolean;
    areEmailConfirmationsEnabled: boolean;
    useGenericPdf: boolean;
}

export interface UpdateLocalPaymentSettingsRequest {
    isUsingPayments: boolean;
    duesRate: number;
    requirePayment: boolean;
}

export interface SendEmailTemplatePreviewRequest {
    localTemplateType: LocalTemplateType;
}

export interface LinkInfoResponse {
    local: Local;
    employer?: Employer;
}

export interface MemberCallTag {
    memberCallId: number;
    tag: string;
}

export interface MemberCall extends EntityBase {
    localId: number;
    fromUserId: number;
    toMemberDisplayId: string;
    toMemberPhone: string;
    notes: string;
    tags: MemberCallTag[];
    dateStarted?: string;
    dateEnded?: string;
    userAnsweredBy: 'machine_start' | 'unknown' | null;
    user?: ApplicationUser;
}

export interface MemberCallWithStatus
    extends MemberCall,
        Partial<MemberCallStatusResponse> {}

export interface StartMemberCallRequest {
    memberId: string;
}

export enum CallStatus {
    NotStarted = 'NotStarted',
    PendingCall = 'PendingCall',
    RingingUser = 'RingingUser',
    RingingMember = 'RingingMember',
    InProgress = 'InProgress',
    Ended = 'Ended',
    NoUserAnswer = 'NoUserAnswer',
}

export interface UpdateMemberCallRequest {
    callId: string;
    notes?: string;
    tags?: string[];
}

export interface HangupCallRequest {
    callId: string;
}

export interface MemberCallStatusResponse {
    memberDisplayId: string;
    timestamp: string;
    dateStarted?: string;
    dateEnded?: string;
    callStatus: CallStatus;
    dateClickedJoinLink?: string;
    dateSubmittedApplication?: string;
    applicationUrl: string | null;
    surveys: Survey[];
    joinLinksSent: JoinLink[];
    joinFormsCompleted: { dateCompleted: string }[];
    failedToDial: boolean;
}

export interface JoinLink extends EntityBase {
    shortId: string;
    dateViewed: string;
    dateUsed: string;
}

export interface SurveyTemplate extends EntityBase {
    localId: string;
    name: string;
    messageText: string;
}

export interface Survey extends EntityBase {
    localId: string;
    memberDisplayId: string;
    dateViewed: string;
    linkUrl: string;
    surveyTemplateDisplayId: string | null;
    surveyTemplateName: string | null;
}

export interface UpsertSurveyTemplateRequest {
    templateId?: string;
    localId: string;
    name: string;
    messageText: string;
}

export interface PreviewTemplateRequest {
    messageText: string;
}

export interface Activity {
    type: 'CALL' | 'SURVEY' | 'JOIN_LINK';
}

export interface MemberTag extends EntityBase {
    tag: string;
}

export type ExportCallRow = Pick<
    MemberCall,
    'dateStarted' | 'dateEnded' | 'notes'
> & {
    ssn: string;
    tagsCSV: string;
    memberFirstName: string;
    memberLastName: string;
    memberCellPhone: string;
    userFirstName: string;
    userLastName: string;
    agentNumber: string | null;
};

export interface AdditionalField extends EntityBase {
    localId: number;
    fieldName: string;
}

export interface MemberAdditionalField extends EntityBase {
    additionalFieldId: number;
    memberId: number;
    fieldValue: string;
}

export interface UploadLocalJoinPDFRequest {
    file: File;
}

export interface UploadLocalJoinPDFResponse {
    blobUrl: string;
}

export interface GridPreset extends EntityBase {
    localId: number;
    presetFor: 'LOCAL' | 'USER';
    userId: string;
    presetKey: 'SIGNUPS';
    presetValue: GridPresetData;
}

export type AgGridSorter = { colId: string; sort: string };

export interface GridPresetData {
    label: string;
    gridState: GridState;
}

export interface ExportSignupsRequest {
    searchParams: any;
    memberIds: string[];
    theseColumnsOnly: string[];
}

export interface DeleteMembersRequest {
    memberIds: string[];
}

export interface ResetJoinStatusRequest {
    memberIds: string[];
}

export interface DashboardCounts {
    joinLinksClicked: number;
    smsMessagesSent: number;
    emailsSent: number;
    callsConnected: number;
    callsConnectedDistinct: number;
    callsAttempted: number;
    callsAttemptedDistinct: number;
    joinedWithLocalLink: number;
    joinedWithDirectLink: number;
}

export interface OverviewGraphPoint {
    date: string;
    opportunities: number;
    joins: number;
}

export interface OverviewStats {
    opportunities: number;
    joins: number;
    contacted: number;
    graphPoints: OverviewGraphPoint[];
}

export interface DashboardStats {
    counts: DashboardCounts;
    overview: OverviewStats;
}

export interface DashboardJoin {
    memberDisplayId: string;
    firstName: string;
    lastName: string;
    dateLastJoined: string | null;
    lastApplicationUrl: string | null;
    dateFormLastDownloaded: string | null;
}

export interface DashboardMemberCall {
    memberDisplayId: string;
    callDisplayId: string;
    firstName: string;
    lastName: string;
    dateLastCalled: string;
    tags: MemberCallTag[];
}

export interface DashboardUserStat {
    userId: string;
    userFullName: string;
    callsConnected: number;
    smsSent: number;
    totalJoins: number;
    totalCalls: number;
    averageCallLengthSeconds: number;
}

export interface LocalTag extends EntityBase {
    tag: string;
}

export interface CreateEmployerForm {
    employerName: string;
    employerLoc: string;
    name: string;
    checkOffName: string;
}

export interface DeleteLocalTagResponse {
    tagId: string;
}

export type TagCounts = {
    [tagName: string]: number;
};

export enum TagType {
    Local = 'LOCAL',
    Member = 'MEMBER',
}

export interface TagSummary {
    tag: string;
    count: number;
    localTagDisplayId: string | null;
}

export interface UpdateLocalTagsRequest {
    tags: string[];
}

export interface RenameTagRequest {
    oldTag: string;
    tag: string;
}

export interface RenameTagResponse {
    oldTag: string;
    tag: string;
}

export interface DeleteTagRequest {
    tag: string;
    deleteForMembers: boolean;
}

export interface DeleteTagResponse {
    tag: string;
    deletedFromMembers: boolean;
}

export interface DeleteAllTagsDTO {
    deletePresets: boolean;
}

export interface PaymentSessionInfo {
    guid: string;
    url: string;
}

export interface PaymentSubmissionResponse {
    amount: number;
    paymentId: string;
    confirmationCode: string;
    type: 'CreditCard' | 'CashOrCheck';
}

export type PaymentType = 'CreditCard' | 'CheckOrCash';

export interface CheckboxOther {
    options: string[];
    other: string;
}
