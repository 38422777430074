import { Field, FieldProps, FieldAttributes } from 'formik';
import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

export type FTextFieldProps = TextFieldProps & {
    mask?: MaskedInputProps['mask'];
    hideErrorText?: boolean;
    name: string;
    step?: number;
    validate?: FieldAttributes<any>['validate'];
};

class FTextField extends React.Component<FTextFieldProps> {
    CustomMaskedInput = ({ inputRef, ...other }: any) => (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            placeholderChar={'\u2000'}
        />
    );

    render() {
        const { hideErrorText, validate, ...props } = this.props;
        return (
            <Field name={props.name} validate={validate}>
                {({ field, form }: FieldProps) => {
                    const { errors, touched } = form;
                    return (
                        <TextField
                            error={
                                !!(
                                    (touched[props.name] ||
                                        form.submitCount > 0) &&
                                    errors[props.name]
                                )
                            }
                            helperText={
                                !hideErrorText &&
                                (touched[props.name] || form.submitCount > 0) &&
                                errors[props.name]
                            }
                            {...props}
                            {...field}
                            value={field.value || ''}
                            InputProps={{
                                inputComponent: props.mask
                                    ? this.CustomMaskedInput
                                    : undefined,
                                ...props.InputProps,
                                // looks like chrome will add the 'suggested password' thing if you use 'new-password
                                // (not good on the login page)
                                autoComplete:
                                    props.type === 'password'
                                        ? 'off'
                                        : 'new-password',
                            }}
                            inputProps={{
                                mask: props.mask,
                                step: props.step,
                                ...props.inputProps,
                            }}
                            onChange={(e) => {
                                const newVal = e.target.value;
                                form.setFieldValue(props.name, newVal);
                                props.onChange && props.onChange(e);
                            }}
                            onBlur={(e) => {
                                form.setFieldTouched(props.name, true);
                                props.onBlur && props.onBlur(e);
                            }}
                        />
                    );
                }}
            </Field>
        );
    }
}

export default FTextField;
