import React from 'react';
import { cx, css } from 'emotion';
import { LoaderButtonProps, LoaderButton } from './LoaderButton';

interface GradientButtonProps extends LoaderButtonProps {}

export const GradientButton: React.FC<GradientButtonProps> = ({
    className,
    ...props
}) => {
    return (
        <LoaderButton
            color="primary"
            variant="contained"
            className={cx(
                css({
                    backgroundColor: 'rgb(84,114,210)',
                    background:
                        'linear-gradient(180deg, rgba(84,114,210,1) 0%, rgba(73,99,184,1) 100%)',
                    border: '1px solid #445CAB',
                    boxShadow: '0 1px 0 rgba(0,0,0,.16) !important',
                    letterSpacing: '.075em',
                    fontWeight: 'bold',
                    fontSize: '14px !important',
                    padding: '10px 22px !important',
                }),
                className
            )}
            {...props}
        />
    );
};
