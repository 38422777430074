import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
    GetLinkResponse,
    JoinForm,
    LoginRequest,
    Local,
    LinkInfoResponse,
    JWT,
    SignatureResponse,
} from '../types';
import ky from 'ky';
import { updateJoinForm } from './joinFormActions';
import { RootState } from '../app/store';
import { DefaultABCAmount, LocalStorageKey } from '../app/constants';
import jwtDecode from 'jwt-decode';
import { updateApiToken, api } from '../app/utils';
import { appTokenFromJWT } from '../reducers/appReducer';

export const fetchFormById = createAsyncThunk<
    GetLinkResponse | undefined,
    string
>('app/fetchFormById', async (formId: string, thunkAPI) => {
    const response: GetLinkResponse | undefined = await ky
        .get(`/api/join/get-link/${formId}`)
        .json();

    if (response?.member) {
        const joinForm = (thunkAPI.getState() as RootState).joinFormReducer
            .joinForm;

        const newJoinValues: Partial<JoinForm> = {};

        let prop: keyof JoinForm;

        for (prop in joinForm) {
            newJoinValues[prop] =
                (response?.member as any)[prop] ?? (joinForm[prop] as any);
        }

        newJoinValues.textMessage =
            newJoinValues.textMessage !== 'N' ? 'Y' : 'N';
        newJoinValues.language = newJoinValues.language || 'E';

        if (
            !response.member.abcAmount ||
            response.member.abcAmount < DefaultABCAmount
        ) {
            newJoinValues.abcAmount = DefaultABCAmount;
        }

        thunkAPI.dispatch(updateJoinForm(newJoinValues));
    }

    return response;
});

export const fetchLocalEmployerLink = createAsyncThunk(
    'app/selectLocal',
    async (req: { local: string; employer?: string }) => {
        let url = `join/link-info/${req.local}`;
        if (req.employer) {
            url += `/${req.employer}`;
        }

        const res: LinkInfoResponse = await api.get(url).json();

        return res;
    }
);

export const selectLocal = createAction<Local>('app/selectLocal');

export const login = createAsyncThunk(
    'auth/login',
    async (req: LoginRequest) => {
        const response = await ky
            .post('/api/auth/login', {
                json: req,
            })
            .text();

        localStorage.setItem(LocalStorageKey.JWT, response);
        updateApiToken(response);

        const jwt = jwtDecode<JWT>(response);
        const appToken = appTokenFromJWT(jwt);
        return appToken;
    }
);

const fetchSignatures = createAsyncThunk(
    'app/fetchSignatures',
    async ({
        localId,
        includeDisabled,
    }: {
        localId?: string;
        includeDisabled?: boolean;
    }) => {
        const searchParams = new URLSearchParams();

        if (includeDisabled) {
            searchParams.append('includeDisabled', includeDisabled.toString());
        }

        const response: SignatureResponse = await api
            .get(`local/${localId}/signatures?${searchParams}`)
            .json();

        return response;
    }
);

export const logout = createAction('auth/logout');

export const AppActions = {
    fetchSignatures,
};
