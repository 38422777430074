import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import FTextField from '../components/inputs/FTextField';
import { useAppDispatch } from '../app/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { LoaderButton } from '../components/LoaderButton';
import { Button } from '@material-ui/core';
import { requestPasswordReset } from '../actions/adminActions';

const defaultValues = {
    email: '',
};

export const RequestPasswordReset: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (
        values: { email: string },
        formik: FormikHelpers<{ email: string }>
    ) => {
        setLoading(true);
        dispatch(requestPasswordReset(values))
            .then(unwrapResult)
            .then(() => {
                enqueueSnackbar(
                    'Check your email for a link to reset your password',
                    {
                        variant: 'success',
                    }
                );
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                enqueueSnackbar('Sorry, an error occurred', {
                    variant: 'error',
                });
            });
        formik.resetForm();
    };

    return (
        <div className="flex h-full items-center justify-center">
            <div className="p-8 lg:w-1/2 md:w-2/3 w-full max-w-xl">
                <h3 className="mb-4 text-xl">Forgot password</h3>
                <Formik initialValues={defaultValues} onSubmit={handleSubmit}>
                    {({ handleSubmit }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col space-y-2"
                        >
                            <FTextField
                                label="Email"
                                name="email"
                                type="email"
                                required
                            />

                            <LoaderButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="flex-1"
                                loading={loading}
                                ContainerProps={{
                                    className: 'flex',
                                }}
                            >
                                Request Reset Password
                            </LoaderButton>
                        </form>
                    )}
                </Formik>
                <Button
                    href={'/login'}
                    variant="text"
                    className="flex flex-1 mt-2"
                >
                    Back to Login
                </Button>
            </div>
        </div>
    );
};
