import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { api } from '../app/utils';

export const Payment = () =>  {
    const [paymentSession, setPaymentSession] = useState<{
        guid: string;
        url: string;
    } | undefined>();

    const [paymentResult, setPaymentResult] = useState<{
        paymentId: string;
        amount: number;
    }>();
    

    useEffect(() => {
        const fetchPaymentSession = async () => {
            const res = await api.post('payment').json();

            setPaymentSession(res as typeof paymentSession);
        }

        fetchPaymentSession();
    }, []);

    useEffect(() => {
        if (!paymentSession?.url) {
            return;
        }

        const paymentsOrigin = new URL(paymentSession.url).origin;


        const fn = (e: MessageEvent) => {
            if (e.origin !== paymentsOrigin) {
                return;
            }

            if (e.data?.type === 'PaymentSuccess') {
                setPaymentResult(e.data.value);
            }
        };

        window.addEventListener('message', fn);

        return () => {
            window.removeEventListener('message', fn);
        };
    }, [paymentSession?.url]);

    return (
        <div className="flex flex-col h-full">
            <h1>Payment</h1>
            {paymentResult ? (
                <div>
                    Payment Details Submitted!

                    <div>Amount: {paymentResult.amount}</div>
                    <div>Transaction ID: {paymentResult.paymentId}</div>

                </div>
            ) : 
                paymentSession ? (
                    <iframe src={paymentSession.url} className="flex-1" />
                ) : (
                    <div className="p-4 text-center">
                        <CircularProgress />
                    </div>
                )
            }

        </div>
    );
};