import * as React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import {
    Dialog as MDialog,
    DialogProps as MDialogProps,
    DialogContent,
    DialogContentText,
    StylesProvider,
    DialogTitle,
    ThemeProvider,
} from '@material-ui/core';
import { ReactNode } from 'react';
import { muiTheme } from '../app/theme';

interface DialogProps extends Partial<MDialogProps> {
    title: string;
    message?: ReactNode;
    onClose: () => void;
}

const Dialog: React.FC<DialogProps> = ({
    onClose,
    title,
    message,
    children,
    ...rest
}) => {
    return (
        <div>
            <MDialog open={true} {...rest}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {message &&
                        (typeof message === 'string' ? (
                            <DialogContentText>{message}</DialogContentText>
                        ) : (
                            <DialogContent>{message}</DialogContent>
                        ))}
                </DialogContent>
                {children}
            </MDialog>
        </div>
    );
};

class Msg {
    private el: HTMLDivElement = document.createElement('div');
    show = (
        title: string,
        message: ReactNode,
        children: ({ onClose }: { onClose: () => void }) => ReactNode,
        dialogProps?: Partial<MDialogProps>
    ) => {
        document.body.appendChild(this.el);
        render(
            <StylesProvider injectFirst>
                <ThemeProvider theme={muiTheme}>
                    <Dialog
                        {...dialogProps}
                        onClose={this.hide}
                        title={title}
                        message={message}
                    >
                        {children({
                            onClose: this.hide,
                        })}
                    </Dialog>
                </ThemeProvider>
            </StylesProvider>,
            this.el
        );
    };

    hide = () => {
        unmountComponentAtNode(this.el);
        document.body.removeChild(this.el);
    };
}

export default new Msg();
