import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Field, FieldProps, Formik, FormikHelpers } from 'formik';
import { AddressDetails, CheckboxOther, JoinForm } from '../types';
import FTextField from '../components/inputs/FTextField';
import FSelectField from '../components/inputs/FSelectField';
import {
    FPOptions,
    GenderSelectOptions,
    phoneMask,
    PreferredLanguage,
    RaceOptions,
    StateSelectOptions,
    YesNoOptions,
} from '../app/constants';
import { FAddressAutocomplete } from '../components/inputs/FAddressAutocomplete';
import { FieldSet } from '../components/FieldSet';
import FDatePickerField from '../components/inputs/FDatePickerField';
import FComboBoxField from '../components/inputs/FComboBoxField';
import {
    ButtonBase,
    Checkbox,
    FormControlLabel,
    TextField,
    useMediaQuery,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../app/store';
import { JoinFormActions, submitJoinForm } from '../actions/joinFormActions';
import { ChevronRight } from '@material-ui/icons';
import { Footer } from './Footer';

import rays from '../assets/rays.svg';
import { css, cx } from 'emotion';
import { ConditionalWrapper } from '../components/ConditionalWrapper';
import { PhotoFrame } from '../components/PhotoFrame';
import { mq } from '../app/theme';
import { GradientButton } from '../components/GradientButton';
import { trimEnd, uniqBy } from 'lodash-es';
import { fetchLocalEmployerLink, selectLocal } from '../actions/appActions';
import { unwrapResult } from '@reduxjs/toolkit';
import { dateValidator } from '../app/utils';
import * as Yup from 'yup';
import { parseISO } from 'date-fns/esm';
import { isValid } from 'date-fns';
import FRadioField from '../components/inputs/FRadioField';

export const Form: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const joinForm = useTypedSelector(
        (state) => state.joinFormReducer.joinForm
    );

    const [isFetchingEmployers, setFetchingEmployers] = useState(false);
    const employers = useTypedSelector(
        (state) => state.joinFormReducer.employerOptions
    );

    const [isEmployerDetailsShown, setIsEmployerDetailsShown] = useState(true);

    const employer = useTypedSelector((state) => state.appReducer.employer);
    const existingMember = useTypedSelector(
        (state) => state.appReducer.employee
    );
    const locals = useTypedSelector(
        (state) => state.joinFormReducer.localOptions
    );

    const localOptions = ['', ...locals];

    const selectedLocal = useTypedSelector((state) => state.appReducer.local);

    const [allowLocalSelection] = useState(!selectedLocal);

    const isAssociateLocal = selectedLocal?.isAssociateLocal;

    const isMd = useMediaQuery(mq('md', true));

    const formId = useTypedSelector((state) => state.appReducer.formId);

    const joinSchema = useMemo(() => {
        let schema = Yup.object().shape<Partial<JoinForm>>({
            cellPhone: Yup.string()
                .label('Cell phone')
                .transform(function (val: string) {
                    return val.replace(/\D/g, '');
                })
                .length(10)
                .matches(/^\d{10}$/)
                .required(),
            birthDate: Yup.string()
                .label('Birth Date')
                .test('is-date', 'A valid date is required', function (val) {
                    const { createError } = this;

                    if (!val || !isValid(parseISO(val))) {
                        return createError({
                            message: `Birth Date is invalid`,
                        });
                    }

                    return true;
                })
                .required(),
            phoneNum: Yup.string()
                .label('Phone number')
                .transform(function (val: string) {
                    return val.replace(/\D/g, '');
                })
                .length(10)
                .matches(/^\d{10}$/),
        });

        if (isAssociateLocal) {
            schema = schema.shape<Partial<JoinForm>>({
                race: Yup.string().required().label('Race / Ethnicity'),
                hasBeenMember: Yup.boolean().required('Selection is required'),
                isRetired: Yup.boolean().required('Selection is required'),
                industry: Yup.object().test<CheckboxOther>(
                    'has-industry',
                    '${path} is required',
                    (value): value is CheckboxOther => {
                        return !!(value?.options.length > 0 || value?.other);
                    }
                ),
            });
        }

        return schema;
    }, [isAssociateLocal]);

    const employerOptions = useMemo(() => {
        return uniqBy(employers, 'employerName')?.map((emp) => ({
            text: `${emp.checkOffName}`,
            value: emp.employerName,
        }));
    }, [employers]);

    const handleSubmit = (values: JoinForm) => {
        dispatch(submitJoinForm(values));
        history.push('/sign');
    };

    useEffect(() => {
        if (selectedLocal) {
            setFetchingEmployers(true);
            dispatch(JoinFormActions.fetchEmployers(selectedLocal.localNumber))
                .then(unwrapResult)
                .finally(() => setFetchingEmployers(false));
        }
    }, [selectedLocal]);

    const handleLocalChange = (localNumber: string) => {
        setFetchingEmployers(true);

        Promise.all([
            dispatch(JoinFormActions.fetchEmployers(localNumber)).then(
                unwrapResult
            ),
            dispatch(
                fetchLocalEmployerLink({
                    local: localNumber,
                })
            ).then(unwrapResult),
        ])
            .then(([, localEmployerRes]) => {
                dispatch(selectLocal(localEmployerRes.local));
            })
            .finally(() => setFetchingEmployers(false));
    };

    const handlePlaceChange = useCallback(
        (
            { streetAddress, state, city, zip }: AddressDetails,
            setFieldValue: FormikHelpers<JoinForm>['setFieldValue']
        ) => {
            const stateVal =
                StateSelectOptions.find(
                    (stateOpt) => stateOpt.text === state
                ) ?? StateSelectOptions.find((opt) => opt.value === '');

            setFieldValue('addressLine1', streetAddress);
            setFieldValue('city', city);
            setFieldValue('state', stateVal?.value);
            setFieldValue('zipCode', zip);
        },
        []
    );
    const employerAndAddress = trimEnd(
        `${employer?.name ?? ''}, ${employer?.address1 ?? ''}`.trim(),
        ','
    );

    const isLocOptional = (formEmployer: string | undefined) => {
        if (!employers) {
            return false;
        }

        return employers.some(
            (emp) => emp.employerName === formEmployer && !emp.employerLoc
        );
    };

    const doesEmpHaveLocs = (formEmployer: string | undefined) => {
        if (!employers || !formEmployer) {
            return true;
        }

        // return employers.some(
        //     (emp) => emp.employerName === formEmployer && emp.employerLoc
        // );

        // Just doing this temporarily until we change this part so it doesnt hide the location box
        return true;
    };

    const showLocalSelect =
        !joinForm.formLocal ||
        localOptions.every((l) => l !== joinForm.formLocal) ||
        allowLocalSelection;

    const showEmployerPicker =
        !joinForm.formEmployer?.displayID && !isAssociateLocal;

    const getShowLocPicker = (values: JoinForm) =>
        !isAssociateLocal &&
        doesEmpHaveLocs(values.formEmployer?.employerName) &&
        !isAssociateLocal;

    return (
        <div className="min-h-0">
            <div
                className={cx(
                    'bg-gray-100 p-4 md:h-40',
                    css({
                        backgroundImage: `url(${rays})`,
                        backgroundPosition: 'top right',
                        backgroundRepeat: 'no-repeat',
                    })
                )}
            >
                {employer ? (
                    <div className="bg-gray-200 text-sm p-3 md:rounded-md md:max-w-screen-lg md:text-center md:m-auto md:p-3">
                        <h5 className="font-semibold mr-2 inline text-gray-900">
                            Employer
                        </h5>
                        <span className="text-gray-800">
                            {employerAndAddress}
                        </span>
                    </div>
                ) : joinForm.formEmployer?.name ? (
                    <div className="bg-gray-200 text-sm p-3 md:rounded-md md:max-w-screen-lg md:text-center md:m-auto md:p-3">
                        <h5 className="font-semibold mr-2 inline text-gray-900">
                            Employer
                        </h5>
                        <span className="text-gray-800">
                            {joinForm.formEmployer?.name ?? ''}
                            &nbsp;
                            {joinForm.formEmployer?.address1 ?? ''}
                            &nbsp;
                            {joinForm.formEmployer?.city ?? ''}
                            &nbsp;
                            {joinForm.formEmployer?.state ?? ''}
                        </span>
                    </div>
                ) : joinForm.formLocal ? (
                    <div className="bg-gray-200 text-sm p-3 md:rounded-md md:max-w-screen-lg md:text-center md:m-auto md:p-3">
                        <span className="text-gray-800">
                            Thanks for your interest in joining Local{' '}
                            {joinForm.formLocal}
                        </span>
                    </div>
                ) : (
                    <div className="bg-gray-200 text-sm p-3 md:rounded-md md:max-w-screen-lg md:text-center md:m-auto md:p-3">
                        <span className="text-gray-800">
                            If you do not know your Local Number, please{' '}
                            <a
                                className="text-ufcw-blue"
                                href="http://www.ufcw.org/about/connect-with-the-ufcw/"
                            >
                                contact us
                            </a>
                            .
                        </span>
                    </div>
                )}

                <h3
                    className={cx(
                        'text-ufcw-blue-dark text-md my-2 mb-0 font-semibold',
                        formId && 'my-5',
                        'md:hidden'
                    )}
                >
                    Please complete the fields below
                </h3>
            </div>
            <Formik
                initialValues={{
                    ...joinForm,
                    // formLocal: localMatch?.params.localNumber,
                }}
                onSubmit={handleSubmit}
                validationSchema={joinSchema}
            >
                {({ handleSubmit, setFieldValue, values, errors }) => (
                    <form
                        className="py-4 px-5 md:-mt-20 mb-12"
                        onSubmit={handleSubmit}
                    >
                        <ConditionalWrapper
                            wrap={isMd}
                            wrapper={(children) => (
                                <PhotoFrame
                                    header="Please Complete the Fields Below"
                                    className="md:max-w-screen-lg md:m-auto md:mb-16 md:px-4"
                                    classes={{
                                        header: 'text-ufcw-blue',
                                        content: '',
                                    }}
                                >
                                    {children}
                                </PhotoFrame>
                            )}
                        >
                            {!employer?.displayID &&
                                (showLocalSelect ||
                                    showEmployerPicker ||
                                    getShowLocPicker(values)) && (
                                    <div className="mb-8">
                                        <h2 className="text-ufcw-blue-dark text-lg font-semibold mt-2 mb-5 md:mt-8">
                                            Union Information
                                        </h2>
                                        <FieldSet>
                                            {showLocalSelect && (
                                                <div className="-mb-2">
                                                    <FComboBoxField
                                                        options={localOptions}
                                                        getOptionLabel={(opt) =>
                                                            opt
                                                        }
                                                        renderOption={(opt) =>
                                                            opt
                                                        }
                                                        getOptionDisabled={(
                                                            opt
                                                        ) => !opt}
                                                        name="formLocal"
                                                        label="Local Number"
                                                        fullWidth
                                                        required
                                                        disableClearable
                                                        onChange={(
                                                            _: React.ChangeEvent<{}>,
                                                            value: string
                                                        ) => {
                                                            handleLocalChange(
                                                                value
                                                            );
                                                            setFieldValue(
                                                                'formLocal',
                                                                value ?? ''
                                                            );
                                                        }}
                                                    />
                                                    <a
                                                        href="https://www.ufcw.org/members/find-your-local/"
                                                        className="text-xs text-ufcw-blue hover:underline -mb-2"
                                                    >
                                                        Don't see your local?
                                                    </a>
                                                </div>
                                            )}

                                            {showEmployerPicker && (
                                                <>
                                                    {!isEmployerDetailsShown ? (
                                                        <>
                                                            <div>
                                                                <FComboBoxField
                                                                    options={
                                                                        employerOptions ??
                                                                        []
                                                                    }
                                                                    name="formEmployer.employerName"
                                                                    label="Employer Name"
                                                                    fullWidth
                                                                    disabled={
                                                                        !values.formLocal &&
                                                                        !selectedLocal
                                                                    }
                                                                    loading={
                                                                        isFetchingEmployers
                                                                    }
                                                                    loadingText="Loading"
                                                                />

                                                                <ButtonBase
                                                                    className="text-xs text-ufcw-blue -mb-2"
                                                                    onClick={() => {
                                                                        setIsEmployerDetailsShown(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    Don't see
                                                                    your
                                                                    employer?
                                                                </ButtonBase>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <FTextField
                                                                    name="formEmployer.employerName"
                                                                    label="Employer Name"
                                                                    fullWidth
                                                                />
                                                                <ButtonBase
                                                                    className="text-xs text-ufcw-blue -mb-2"
                                                                    onClick={() => {
                                                                        setIsEmployerDetailsShown(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    Or, pick
                                                                    your
                                                                    employer
                                                                    from a list
                                                                </ButtonBase>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {getShowLocPicker(values) && (
                                                <FTextField
                                                    name="formEmployer.employerLoc"
                                                    label="Location Number"
                                                    fullWidth
                                                    required={
                                                        !isLocOptional(
                                                            values.formEmployer
                                                                ?.employerName
                                                        )
                                                    }
                                                />
                                            )}
                                        </FieldSet>
                                    </div>
                                )}

                            <div>
                                <h2
                                    className={cx(
                                        'text-ufcw-blue-dark text-lg font-semibold mt-2 mb-5 md:mt-8',
                                        cx(formId && 'mt-8')
                                    )}
                                >
                                    Personal Information
                                </h2>
                                <FieldSet>
                                    {(existingMember
                                        ? !existingMember.encryptedSSN
                                        : true) &&
                                        !isAssociateLocal && (
                                            <FTextField
                                                name="ssn"
                                                label={
                                                    selectedLocal?.ssnFieldLabel ??
                                                    'Last 4 of SSN'
                                                }
                                                required
                                                fullWidth
                                            />
                                        )}
                                    <FTextField
                                        name="firstName"
                                        label="First Name"
                                        fullWidth
                                        required
                                    />
                                    <FTextField
                                        name="initial"
                                        label="Middle Initial"
                                        inputProps={{ maxLength: 1 }}
                                        fullWidth
                                    />
                                    <FTextField
                                        name="lastName"
                                        label="Last Name"
                                        fullWidth
                                        required
                                    />
                                    <FDatePickerField
                                        name="birthDate"
                                        label="Birth Date"
                                        fullWidth
                                        required
                                        validate={dateValidator}
                                    />
                                    {!isAssociateLocal ? (
                                        <FSelectField
                                            name="language"
                                            label="Preferred Language"
                                            options={PreferredLanguage}
                                            fullWidth
                                            required
                                        />
                                    ) : null}
                                </FieldSet>
                            </div>

                            <div>
                                <h2 className="text-ufcw-blue-dark text-lg font-semibold mt-8 mb-5">
                                    Address
                                </h2>
                                <FieldSet>
                                    <FAddressAutocomplete
                                        name="addressLine1"
                                        label="Address"
                                        fullWidth
                                        onPlaceChange={(place) => {
                                            if (place) {
                                                handlePlaceChange(
                                                    place,
                                                    setFieldValue
                                                );
                                            }
                                        }}
                                    />
                                    <FTextField
                                        name="addressLine2"
                                        label="Address 2 / Apt #"
                                        fullWidth
                                    />
                                    <FTextField
                                        name="city"
                                        label="City"
                                        fullWidth
                                        required
                                    />
                                    <FComboBoxField
                                        name="state"
                                        label="State"
                                        options={StateSelectOptions}
                                        fullWidth
                                        required
                                    />
                                    <FTextField
                                        name="zipCode"
                                        label="ZIP"
                                        fullWidth
                                        required
                                        onChange={(e) => {
                                            const newVal = e.target.value.replace(
                                                /[^0-9]/g,
                                                ''
                                            );

                                            setFieldValue('zipCode', newVal);
                                        }}
                                    />
                                </FieldSet>
                            </div>
                            <div>
                                <h2 className="text-ufcw-blue-dark text-lg font-semibold mt-8 mb-5">
                                    Contact Information
                                </h2>
                                <FieldSet>
                                    <FTextField
                                        name="email"
                                        label="Email"
                                        type="email"
                                        fullWidth
                                        required
                                    />
                                    <FTextField
                                        name="phoneNum"
                                        label="Phone"
                                        type="tel"
                                        mask={phoneMask}
                                        fullWidth
                                    />
                                    <FTextField
                                        name="cellPhone"
                                        label="Cell Phone"
                                        type="tel"
                                        mask={phoneMask}
                                        fullWidth
                                        required
                                    />
                                    <FSelectField
                                        name="textMessage"
                                        label="Send Text?"
                                        options={YesNoOptions}
                                        fullWidth
                                        required
                                    />
                                </FieldSet>
                            </div>
                            <div>
                                <h2 className="text-ufcw-blue-dark text-lg font-semibold mt-8 mb-5">
                                    Other
                                </h2>
                                <FieldSet>
                                    {isAssociateLocal ? (
                                        <>
                                            <FSelectField
                                                name="race"
                                                label="Race / Ethnicity"
                                                options={RaceOptions}
                                                fullWidth
                                                required
                                            />
                                            {values['race'] &&
                                            (values['race'] === 'O' ||
                                                !RaceOptions.some(
                                                    (opt) =>
                                                        opt.value ===
                                                        values['race']
                                                )) ? (
                                                <TextField
                                                    name="race"
                                                    fullWidth
                                                    required
                                                    placeholder="Race / Ethnicity"
                                                />
                                            ) : null}
                                            <FSelectField
                                                name="sex"
                                                label="Gender"
                                                options={GenderSelectOptions}
                                                fullWidth
                                            />
                                            {values['sex'] &&
                                            (values['sex'] === 'O' ||
                                                !GenderSelectOptions.some(
                                                    (opt) =>
                                                        opt.value ===
                                                        values['sex']
                                                )) ? (
                                                <TextField
                                                    name="sex"
                                                    fullWidth
                                                    placeholder="Gender"
                                                />
                                            ) : null}

                                            <div>
                                                Have you been a member of the
                                                UFCW before?
                                            </div>
                                            <FRadioField
                                                name="hasBeenMember"
                                                label="Yes"
                                                value={true}
                                                required
                                            />
                                            <FRadioField
                                                name="hasBeenMember"
                                                label="No"
                                                value={false}
                                                required
                                            />
                                            {errors['hasBeenMember'] ? (
                                                <div
                                                    className={cx(
                                                        'text-red-500 text-xs',
                                                        css({
                                                            marginTop:
                                                                '0 !important',
                                                        })
                                                    )}
                                                >
                                                    {errors['hasBeenMember']}
                                                </div>
                                            ) : null}
                                            <div>
                                                Are you currently retired or
                                                consider yourself semi-retired?
                                            </div>
                                            <FRadioField
                                                name="isRetired"
                                                label="Yes"
                                                value={true}
                                                required
                                            />
                                            <FRadioField
                                                name="isRetired"
                                                label="No"
                                                value={false}
                                                required
                                            />
                                            {errors['isRetired'] ? (
                                                <div
                                                    className={cx(
                                                        'text-red-500 text-xs',
                                                        css({
                                                            marginTop:
                                                                '0 !important',
                                                        })
                                                    )}
                                                >
                                                    {errors['isRetired']}
                                                </div>
                                            ) : null}
                                            <div>
                                                What industry(s) do you
                                                currently work in?
                                            </div>
                                            <div className="flex flex-col">
                                                <MultiRadioField
                                                    name="industry.options"
                                                    label="Cannabis"
                                                    value="cannabis"
                                                />
                                                <MultiRadioField
                                                    name="industry.options"
                                                    label="Retail"
                                                    value="retail"
                                                />
                                                <MultiRadioField
                                                    name="industry.options"
                                                    label="Grocery Store"
                                                    value="grocery"
                                                />
                                                <MultiRadioField
                                                    name="industry.options"
                                                    label="Health Care"
                                                    value="healthcare"
                                                />
                                                <MultiRadioField
                                                    name="industry.options"
                                                    label="Retired"
                                                    value="retired"
                                                />
                                                <FTextField
                                                    name="industry.other"
                                                    label="Other"
                                                />
                                            </div>
                                            {errors['industry'] ? (
                                                <div
                                                    className={cx(
                                                        'text-red-500 text-xs',
                                                        css({
                                                            marginTop:
                                                                '0 !important',
                                                        })
                                                    )}
                                                >
                                                    {errors['industry']}
                                                </div>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            <FDatePickerField
                                                name="origHireDate"
                                                label="Hire Date"
                                                fullWidth
                                                validate={dateValidator}
                                            />
                                            <FTextField
                                                name="jbno"
                                                label="Job Classification"
                                                fullWidth
                                            />
                                            <FSelectField
                                                name="fp"
                                                label="Full-Time/Part-Time"
                                                options={FPOptions}
                                                fullWidth
                                            />
                                            {/* <FSelectField
                                        name="voter"
                                        label="Registered Voter"
                                        options={VoterOptions}
                                        fullWidth
                                    /> */}
                                            <FTextField
                                                name="department"
                                                label="Department"
                                                fullWidth
                                            />
                                            <FTextField
                                                type="number"
                                                name="rateOfPay"
                                                step={0.01}
                                                label="Wage"
                                                fullWidth
                                            />
                                            <FTextField
                                                type="number"
                                                step={0.5}
                                                name="hours"
                                                label="Hours"
                                                fullWidth
                                            />
                                            <FTextField
                                                name="steward"
                                                label="Steward Name"
                                                fullWidth
                                            />
                                        </>
                                    )}
                                </FieldSet>
                            </div>
                            <div className="text-center">
                                <GradientButton
                                    className={cx(
                                        'mt-8 md:col-span-2 md:max-w-md md:margin-auto md:mt-6',
                                        css({
                                            [mq('md')]: {
                                                justifySelf: 'center',
                                                transform:
                                                    'translate(0, 2.75rem)',
                                                // simulate the border cut-out
                                                outline: '1.5rem solid #fff',
                                                '&:focus': {
                                                    outline:
                                                        '1.5rem solid #fff',
                                                },
                                            },
                                        })
                                    )}
                                    disableElevation={isMd}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    endIcon={
                                        <ChevronRight className="text-ufcw-yellow" />
                                    }
                                >
                                    Continue & Sign
                                </GradientButton>
                            </div>
                        </ConditionalWrapper>
                    </form>
                )}
            </Formik>
            <Footer />
        </div>
    );
};

interface MultiRadioFieldProps {
    label: ReactNode;
    name: string;
    value: string;
}

const MultiRadioField = ({ label, name, value }: MultiRadioFieldProps) => {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => (
                <FormControlLabel
                    label={label}
                    control={<Checkbox checked={field.value.includes(value)} />}
                    checked={field.value.includes(value)}
                    onChange={(e, checked) => {
                        if (checked) {
                            const newOptions = [
                                ...new Set([...field.value, value]),
                            ];

                            form.setFieldValue(name, newOptions);
                        }

                        if (!checked) {
                            const newOptions = field.value.filter(
                                (v: string) => v !== value
                            );

                            form.setFieldValue(name, newOptions);
                        }
                    }}
                />
            )}
        </Field>
    );
};
