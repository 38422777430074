import React, { useState } from 'react';
import { Formik, FormikHelpers, Field } from 'formik';
import FTextField from '../components/inputs/FTextField';
import { useAppDispatch } from '../app/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { LoaderButton } from '../components/LoaderButton';
import { resetPassword } from '../actions/adminActions';
import { useQuery } from '../utils/useQuery';
import { PasswordResetForm } from '../types';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    password: Yup.string().min(6).required('Password is required'),
    confirmPassword: Yup.string()
        .required('Password is required')
        .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
});

export const PasswordReset: React.FC = () => {
    const query = useQuery();
    const email = atob(query.get('e') as string);
    const token = query.get('t') as string;
    const newUser = query.get('newUser') as string;

    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (
        values: PasswordResetForm,
        formik: FormikHelpers<PasswordResetForm>
    ) => {
        setLoading(true);
        dispatch(resetPassword(values))
            .then(unwrapResult)
            .then(() => {
                enqueueSnackbar('Thanks! Login with your new password', {
                    variant: 'success',
                });
                setLoading(false);
                history.push('/login');
            })
            .catch((e) => {
                setLoading(false);
                enqueueSnackbar('Sorry, an error occurred', {
                    variant: 'error',
                });
            });
        formik.resetForm();
    };

    const headerText = newUser ? 'Create Password' : 'Reset Password';

    return (
        <div className="flex h-full items-center justify-center">
            <div className="p-8 lg:w-1/2 md:w-2/3 w-full max-w-xl">
                <h3 className="mb-4 text-xl">{headerText}</h3>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        email,
                        token,
                        password: '',
                        confirmPassword: '',
                    }}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col space-y-2"
                        >
                            <Field type="hidden" name="token" />
                            <Field type="hidden" name="email" />
                            <FTextField
                                label="Password"
                                name="password"
                                type="password"
                                required
                                validate={(value: string) => {
                                    const hasLetters = /[a-z]/.test(value);
                                    const hasUppercase = /[A-Z]/.test(value);
                                    const hasNumbers = /\d/.test(value);
                                    const hasSymbol = /[^a-zA-Z0-9]/.test(
                                        value
                                    );
                                    const hasLength = value.length >= 6;

                                    if (!hasLetters) {
                                        return 'password must include lowercase letters';
                                    }

                                    if (!hasUppercase) {
                                        return 'password must include uppercase letters';
                                    }

                                    if (!hasNumbers) {
                                        return 'password must include number';
                                    }

                                    if (!hasSymbol) {
                                        return 'password must include symbol';
                                    }

                                    if (!hasLength) {
                                        return 'password must be at least 6 characters';
                                    }
                                }}
                            />

                            <FTextField
                                label="Confirm Password"
                                name="confirmPassword"
                                type="password"
                                required
                            />

                            <div className="text-sm text-gray-700">
                                Password must:
                                <ul className="list-disc list-inside">
                                    <li>Be at least 6 characters</li>
                                    <li>
                                        Include Uppercase & Lowercase letters
                                    </li>
                                    <li>Include Number</li>
                                    <li>Include Symbol</li>
                                </ul>
                            </div>

                            <LoaderButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="flex-1"
                                loading={loading}
                                ContainerProps={{
                                    className: 'flex',
                                }}
                            >
                                Submit
                            </LoaderButton>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
