import React from 'react';
import { CircularProgress } from '@material-ui/core';

export const FullPageLoader: React.FC = () => {
    return (
        <div className="flex items-center justify-center h-full bg-ufcw-blue">
            <CircularProgress color="secondary" />
        </div>
    );
};
